<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row
          flex
          align="center"
          justify="space-between"
          class="px-3 mb-4"
        >
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Meer cijfers?</span>
            <div>
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsPerformances' }"
              >
                voorstellingen
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsPerformances10weeks' }"
              >
                voorstellingen laatste 10 weken
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase ma-1"
                :to="{ name: 'SalesMutationsPerformances7days' }"
              >
                voorstellingen laatste 7 dagen
                <v-icon
                  small
                  right
                  >mdi-chart-bar-stacked</v-icon
                >
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1131"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-lg-flex">
              <filter-date-from-to
                :defaultFrom="this.filter.filterDateOne"
                :defaultTo="this.filter.filterDateTwo"
                @changePeriod="filter.filterPeriod = $event"
              ></filter-date-from-to>
            </div>
            <!-- Filter dialog -->
            <div class="d-lg-none">
              <v-dialog
                v-model="tableProductionsFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-bind="activatorProps"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="text-primary">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <filter-date-from-to
                      :defaultFrom="this.filter.filterDateOne"
                      :defaultTo="this.filter.filterDateTwo"
                      @changePeriod="filter.filterPeriod = $event"
                    ></filter-date-from-to>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      variant="outlined"
                      dark
                      @click="clearFilterDateFromTo()"
                      >sluit filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <div v-if="!tableProductionsLoading">
          <!-- Table -->
          <v-data-table
            :headers="tableProductionsHeaders"
            :items="tableProductions"
            :mobile-breakpoint="0"
            :loading="tableProductionsLoading"
            :page.sync="tableProductionsPage"
            :items-per-page="tableProductionsItemsPerPage"
            :item-class="changeRowBackground"
            hide-default-footer
            @update:current-items="tableProductionsPageCount = Math.ceil(tableProductions.length / tableProductionsItemsPerPage)"
            @update:items-per-page="tableProductionsPageCount = Math.ceil(tableProductions.length / tableProductionsItemsPerPage)"
            class="table-action table-expand mt-2"
            item-key="productionNumber"
            single-expand
            show-expand
            fixed-header
            :height="tableProductions.length > 10 ? '45vh' : ''"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="pa-0"
              >
                <v-data-table
                  :headers="tableProductionsDetailsHeaders"
                  :items="item.details"
                  :mobile-breakpoint="0"
                  hide-default-footer
                  :items-per-page="-1"
                >
                  <template v-slot:[`item.type`]="{ item }">
                    <strong>{{ item.type }}</strong>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>

          <v-data-table
            :headers="tableProductionsTotalHeaders"
            :items="tableProductionsTotal"
            :mobile-breakpoint="0"
            hide-default-footer
            item-key=""
            class="table-action table-total-head"
          >
          </v-data-table>

          <!-- Table settings -->
          <v-row
            class="text-center pt-2"
            align="center"
          >
            <!-- Table totaal -->
            <v-col cols="12">
              <p class="font-weight-medium text-left caption text-primary ma-0 pa-0">
                totaal: {{ this.tableProductions.length }}
              </p>
            </v-col>

            <!-- Table buttons items per page -->
            <v-col
              cols="12"
              sm="4"
              class="d-flex align-center justify-center justify-sm-start"
            >
              <v-btn
                v-if="tableProductions.length > 25"
                @click="tableProductionsItemsPerPage = 25"
                :color="tableProductionsItemsPerPage == 25 ? 'primary' : '#fff'"
                height="45"
                width="45"
                elevation="0"
                x-small
                class="pa-0 mr-1 font-weight-bold body-2"
                >25</v-btn
              >
              <v-btn
                v-if="tableProductions.length > 25"
                @click="tableProductionsItemsPerPage = 50"
                :color="tableProductionsItemsPerPage == 50 ? 'primary' : '#fff'"
                height="45"
                width="45"
                elevation="0"
                x-small
                class="pa-0 mr-1 font-weight-bold body-2"
                >50</v-btn
              >
              <v-btn
                v-if="tableProductions.length > 50"
                @click="tableProductionsItemsPerPage = 100"
                :color="tableProductionsItemsPerPage == 100 ? 'primary' : '#fff'"
                height="45"
                width="45"
                elevation="0"
                x-small
                class="pa-0 mr-1 font-weight-bold body-2"
                >100</v-btn
              >
            </v-col>

            <!-- Table pagination -->
            <v-col
              cols="12"
              sm="4"
            >
              <v-pagination
                v-if="tableProductionsPageCount > 1"
                v-model="tableProductionsPage"
                :length="tableProductionsPageCount"
                :total-visible="7"
                :elevation="0"
              ></v-pagination>
            </v-col>

            <!-- Table download -->
            <v-col
              cols="12"
              sm="4"
              class="d-flex justify-center justify-sm-end"
            >
              <v-btn
                color="primary"
                rounded
                depressed
                class="font-weight-bold text-lowercase mr-2"
              >
                <vue-excel-xlsx
                  class="v-btn font-weight-bold text-lowercase"
                  :data="exportProductions"
                  :columns="exportProductionsColumns"
                  :filename="'dip-verkoopmutaties-producties'"
                  :sheetname="'Verkoopmutaties Producties'"
                  >exporteer <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
                >
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <div class="text-center progress-circular">
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <h4 class="text-primary mt-5">Een moment geduld aub, je gegevens worden berekend. Dit kan even duren.</h4>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../../components/Help.vue';
import functions from '../../services/functions.service';
import FilterDateFromTo from '../../components/FilterDateFromTo';

export default {
  name: 'SalesMutationsProductions',
  components: {
    Help,
    FilterDateFromTo,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'verkoopmutaties',
          disabled: false,
          href: '/verkoopmutaties',
          bold: false,
        },
        {
          text: 'producties',
          disabled: true,
          href: '',
          bold: true,
        },
      ],
      filter: {
        filterDateOne: '',
        filterDateTwo: '',
        filterPeriod: {
          from: '',
          to: '',
        },
      },
      tableProductions: [],
      productions: [],
      tableProductionsTotal: [],
      tableProductionsLoading: true,
      tableProductionsHeaders: [
        { title: 'productienummer', key: 'productionNumber' },
        { title: 'impresariaat', key: 'agencyName' },
        { title: 'productie', key: 'productionTitle' },
        { title: 'producent', key: 'producerName' },
        { title: 'genre', key: 'genreName' },
        { title: '', key: 'data-table-expand', align: 'end' },
      ],
      tableProductionsPage: 1,
      tableProductionsPageCount: 0,
      tableProductionsItemsPerPage: 25,
      tableProductionsDetailsHeaders: [
        { title: '', key: 'type' },
        { title: 'datum 1st vrst', key: 'dateFirstPerformance' },
        { title: 'datum laatste vrst', key: 'dateLastPerformance' },
        { title: '# vrst', key: 'performanceCount' },
        { title: '# capaciteit', key: 'capacity' },
        { title: '# theaters', key: 'theaterCount' },
        { title: 'wk1', key: 'week1' },
        { title: 'wk2', key: 'week2' },
        { title: '# mutatie', key: 'mutationNumber' },
        { title: '% mutatie', key: 'mutationRate' },
        { title: '% bezet', key: 'occupancyRate' },
        { title: 'gemiddelde kaartprijs', key: 'ticketPrice' },
        { title: 'recette', key: 'revenueInVat' },
        { title: '%', key: 'occupancyRateDifference' },
        { title: '#', key: 'salesBoothDifference' },
        { title: '', key: 'type' },
      ],
      tableProductionsTotalHeaders: [
        { title: 'Totaal', key: 'type', sortable: false, class: 'title' },
        { title: '# vrst', key: 'performanceCount' },
        { title: '# capaciteit', key: 'capacity' },
        { title: '# theaters', key: 'theaterCount' },
        { title: 'wk1', key: 'week1' },
        { title: 'wk1', key: 'week2' },
        { title: '# mutatie', key: 'mutationNumber' },
        { title: '% mutatie', key: 'mutationRate' },
        { title: '% bezet', key: 'occupancyRate' },
        { title: 'gemiddelde kaartprijs', key: 'ticketPrice' },
        { title: 'recette', key: 'revenueInVat' },
        { title: '', key: 'type' },
      ],
      tableProductionsFilterDialog: false,
      exportProductions: [],
      exportProductionsColumns: [],
    };
  },
  beforeMount() {
    this.filterDefaultDate();
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMutations');
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    calculateArrayAvg: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop] / items.length;
      }, 0);
    },
    filterDefaultDate: function () {
      this.checkFilter({ viewName: 'SalesMutationsOveral', filterObject: this.filter });
      this.filter = {};
      this.filter = this.getFilter('SalesMutationsOveral');

      if (this.filter.filterDateOne == '') {
        var now = new Date();
        var previousWeek = new Date();
        previousWeek = new Date(previousWeek.setDate(previousWeek.getDate() - 7));
        this.filter.filterDateOne = functions.getDateView(functions.jsDateToStringDate(previousWeek));
        this.filter.filterDateTwo = functions.getDateView(functions.jsDateToStringDate(now));
      } else {
        this.filter.filterDateOne = functions.getDateView(
          functions.jsDateToStringDate(new Date(this.filter.filterPeriod.from))
        );
        this.filter.filterDateTwo = functions.getDateView(
          functions.jsDateToStringDate(new Date(this.filter.filterPeriod.to))
        );
      }
    },
    clearFilterDateFromTo: function () {
      this.tableProductionsFilterDialog = false;
    },
    getProductions: function () {
      this.tableProductions = [];
      this.tableProductionsLoading = true;

      ApiService.get('/SalesMutations/GetProductionsForDateRange', {
        firstDate: this.filter.filterPeriod.from,
        lastDate: this.filter.filterPeriod.to,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.tableProductions = response.data.result.productions;
          this.tableProductionsTotals = response.data.result;

          for (var i = 0; i < this.tableProductions.length; i++) {
            var productionDetails = [
              {
                type: 'theater',
                dateFirstPerformance: functions.getDateView(this.tableProductions[i].theater.firstPerformanceDate),
                dateLastPerformance: functions.getDateView(this.tableProductions[i].theater.lastPerformanceDate),
                performanceCount: this.formatThousandsWithDot(this.tableProductions[i].theater.performanceCount),
                capacity: this.formatThousandsWithDot(this.tableProductions[i].theater.capacity),
                theaterCount: this.tableProductions[i].theater.theaterCount,
                mutationNumber: this.tableProductions[i].theater.mutationNumber,
                mutationRate:
                  this.tableProductions[i].theater.mutationRate != null
                    ? functions.getPercentageView(this.tableProductions[i].theater.mutationRate)
                    : '',
                occupancyRate:
                  this.tableProductions[i].theater.occupancyRate != null
                    ? functions.getPercentageView(this.tableProductions[i].theater.occupancyRate)
                    : '',
                ticketPrice:
                  this.tableProductions[i].theater.ticketPrice != null
                    ? functions.getEuroSignView(this.tableProductions[i].theater.ticketPrice)
                    : '',
                revenueInVat:
                  this.tableProductions[i].theater.revenueInVat != null
                    ? functions.getEuroSignView(this.tableProductions[i].theater.revenueInVat)
                    : '',
              },
              {
                type: 'landelijk',
                dateFirstPerformance: functions.getDateView(this.tableProductions[i].national.firstPerformanceDate),
                dateLastPerformance: functions.getDateView(this.tableProductions[i].national.lastPerformanceDate),
                performanceCount: this.formatThousandsWithDot(this.tableProductions[i].national.performanceCount),
                capacity: this.formatThousandsWithDot(this.tableProductions[i].national.capacity),
                theaterCount: this.tableProductions[i].national.theaterCount,
                mutationNumber: this.tableProductions[i].national.mutationNumber,
                mutationRate:
                  this.tableProductions[i].national.mutationRate != null
                    ? functions.getPercentageView(this.tableProductions[i].national.mutationRate)
                    : '',
                occupancyRate:
                  this.tableProductions[i].national.occupancyRate != null
                    ? functions.getPercentageView(this.tableProductions[i].national.occupancyRate)
                    : '',
                ticketPrice:
                  this.tableProductions[i].national.ticketPrice != null
                    ? functions.getEuroSignView(this.tableProductions[i].national.ticketPrice)
                    : '',
                revenueInVat:
                  this.tableProductions[i].national.revenueInVat != null
                    ? functions.getEuroSignView(this.tableProductions[i].national.revenueInVat)
                    : '',
              },
              {
                type: 'verschil',
                occupancyRateDifference: functions.getPercentageView(this.tableProductions[i].occupancyRateDifference),
                salesBoothDifference:
                  this.tableProductions[i].salesBoothDifference != null &&
                  this.tableProductions[i].salesBoothDifference != ''
                    ? parseFloat(this.tableProductions[i].salesBoothDifference.toFixed(2))
                    : '',
              },
            ];
            for (var j = 0; j < this.tableProductions[i].theater.days.length; j++) {
              productionDetails[0]['week' + (j + 1)] = this.formatThousandsWithDot(
                this.tableProductions[i].theater.days[j].ticketCount
              );
              this.tableProductionsDetailsHeaders[j + 6].text = functions.getDateView(
                this.tableProductions[i].theater.days[j].date
              );
              this.tableProductionsTotalHeaders[j + 4].text = functions.getDateView(
                this.tableProductions[i].theater.days[j].date
              );
            }
            for (var k = 0; k < this.tableProductions[i].national.days.length; k++) {
              productionDetails[1]['week' + (k + 1)] = this.formatThousandsWithDot(
                this.tableProductions[i].national.days[k].ticketCount
              );
              this.tableProductionsDetailsHeaders[k + 6].text = functions.getDateView(
                this.tableProductions[i].national.days[k].date
              );
            }
            this.tableProductions[i].details = productionDetails;

            this.tableProductions[i].totalTheaterPerformanceCount = this.tableProductions[i].theater.performanceCount;
            this.tableProductions[i].totalTheatercapacity = this.tableProductions[i].theater.capacity;
            this.tableProductions[i].totalTheaterWeekOne = this.tableProductions[i].theater.days[0].ticketCount;
            this.tableProductions[i].totalTheaterWeekTwo = this.tableProductions[i].theater.days[1].ticketCount;
            this.tableProductions[i].totalTheaterMutationNumber = this.tableProductions[i].theater.mutationNumber;
            this.tableProductions[i].totalNationalMutationRate = this.tableProductions[i].theater.mutationRate;
            this.tableProductions[i].totalTheateroccupancyRate = this.tableProductions[i].theater.occupancyRate;
            this.tableProductions[i].totalTheaterticketPrice = this.tableProductions[i].theater.ticketPrice;
            this.tableProductions[i].totalTheaterRevenueInVat = this.tableProductions[i].theater.revenueInVat;

            this.tableProductions[i].totalNationalPerformanceCount = this.tableProductions[i].national.performanceCount;
            this.tableProductions[i].totalNationalcapacity = this.tableProductions[i].national.capacity;
            this.tableProductions[i].totalNationalWeekOne = this.tableProductions[i].national.days[0].ticketCount;
            this.tableProductions[i].totalNationalWeekTwo = this.tableProductions[i].national.days[1].ticketCount;
            this.tableProductions[i].totalNationalMutationNumber = this.tableProductions[i].national.mutationNumber;
            this.tableProductions[i].totalNationalMutationRate = this.tableProductions[i].national.mutationRate;
            this.tableProductions[i].totalNationaloccupancyRate = this.tableProductions[i].national.occupancyRate;
            this.tableProductions[i].totalNationalticketPrice = this.tableProductions[i].national.ticketPrice;
            this.tableProductions[i].totalNationalRevenueInVat = this.tableProductions[i].national.revenueInVat;
          }

          if (this.tableProductionsTotals.theater != null) {
            this.tableProductionsTotal = [
              {
                type: 'Theater',
                performanceCount: this.formatThousandsWithDot(this.tableProductionsTotals.theater.performanceCount),
                capacity: this.formatThousandsWithDot(this.tableProductionsTotals.theater.capacity),
                theaterCount: this.formatThousandsWithDot(this.tableProductionsTotals.theater.theaterCount),
                week1: this.formatThousandsWithDot(this.tableProductionsTotals.theater.days[0].ticketCount),
                week2: this.formatThousandsWithDot(this.tableProductionsTotals.theater.days[1].ticketCount),
                mutationNumber: this.formatThousandsWithDot(this.tableProductionsTotals.theater.mutationNumber),
                mutationRate:
                  this.tableProductionsTotals.theater.mutationRate != null
                    ? functions.getPercentageView(this.tableProductionsTotals.theater.mutationRate)
                    : '',
                occupancyRate:
                  this.tableProductionsTotals.theater.occupancyRate != null
                    ? functions.getPercentageView(this.tableProductionsTotals.theater.occupancyRate)
                    : '',
                ticketPrice:
                  this.tableProductionsTotals.theater.ticketPrice != null
                    ? functions.getEuroSignView(this.tableProductionsTotals.theater.ticketPrice)
                    : '',
                revenueInVat: functions.getEuroSignView(this.tableProductionsTotals.theater.revenueInVat),
              },
              {
                type: 'Landelijk',
                performanceCount: this.formatThousandsWithDot(this.tableProductionsTotals.national.performanceCount),
                capacity: this.formatThousandsWithDot(this.tableProductionsTotals.national.capacity),
                theaterCount: this.formatThousandsWithDot(this.tableProductionsTotals.national.theaterCount),
                week1: this.formatThousandsWithDot(this.tableProductionsTotals.national.days[0].ticketCount),
                week2: this.formatThousandsWithDot(this.tableProductionsTotals.national.days[1].ticketCount),
                mutationNumber: this.formatThousandsWithDot(this.tableProductionsTotals.national.mutationNumber),
                mutationRate:
                  this.tableProductionsTotals.national.mutationRate != null
                    ? functions.getPercentageView(this.tableProductionsTotals.national.mutationRate)
                    : '',
                occupancyRate:
                  this.tableProductionsTotals.national.occupancyRate != null
                    ? functions.getPercentageView(this.tableProductionsTotals.national.occupancyRate)
                    : '',
                ticketPrice:
                  this.tableProductionsTotals.national.ticketPrice != null
                    ? functions.getEuroSignView(this.tableProductionsTotals.national.ticketPrice)
                    : '',
                revenueInVat: functions.getEuroSignView(this.tableProductionsTotals.national.revenueInVat),
              },
            ];
          }
          this.tableProductionsLoading = false;
          this.createExportData();
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.tableProductionsLoading = false;
          this.errored = true;
        });
    },
    createExportData: function () {
      if (this.tableProductions.length > 0) {
        this.exportProductionsColumns = [];
        this.exportProductions = [];

        // create export columns
        for (var i = 1; i < 32; i++) {
          this.exportProductionsColumns.push({ label: '', field: 'column' + i });
        }

        // create export data
        let date = new Date();
        this.exportProductions.push({
          column1: this.user.organisationName,
          column3:
            'Gegenereerd op: ' +
            date.getDate() +
            '-' +
            (date.getMonth() + 1) +
            '-' +
            date.getFullYear() +
            ' ' +
            date.getHours() +
            ':' +
            (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
        });
        this.exportProductions.push({
          column6: 'theater',
          column7: 'theater',
          column8: 'theater',
          column9: 'theater',
          column10: 'theater',
          column11: 'theater',
          column12: 'theater',
          column13: 'theater',
          column14: 'theater',
          column15: 'theater',
          column16: 'theater',
          column17: 'theater',
          column18: 'landelijk',
          column19: 'landelijk',
          column20: 'landelijk',
          column21: 'landelijk',
          column22: 'landelijk',
          column23: 'landelijk',
          column24: 'landelijk',
          column25: 'landelijk',
          column26: 'landelijk',
          column27: 'landelijk',
          column28: 'landelijk',
          column29: 'landelijk',
          column30: 'verschil',
          column31: 'verschil',
        });

        this.exportProductions.push({
          column1: 'productienummer',
          column2: 'impresariaat',
          column3: 'productie',
          column4: 'producent',
          column5: 'genre',
          column6: 'datum 1st vrst',
          column7: 'datum laatste vrst',
          column8: '# vrst',
          column9: '# capaciteit',
          column10: '# theaters',
          column11: functions.getDateView(this.tableProductions[0].theater.days[0].date),
          column12: functions.getDateView(this.tableProductions[0].theater.days[1].date),
          column13: '# mutatie',
          column14: '% mutatie',
          column15: '% bezet',
          column16: 'gemiddelde kaartprijs',
          column17: 'recette',
          column18: 'datum 1st vrst',
          column19: 'datum laatste vrst',
          column20: '# vrst',
          column21: '# capaciteit',
          column22: '# theaters',
          column23: functions.getDateView(this.tableProductions[0].theater.days[0].date),
          column24: functions.getDateView(this.tableProductions[0].theater.days[1].date),
          column25: '# mutatie',
          column26: '% mutatie',
          column27: '% bezet',
          column28: 'gemiddelde kaartprijs',
          column29: 'recette',
          column30: '%',
          column31: '#',
        });

        this.tableProductions.forEach((item) => {
          this.exportProductions.push({
            column1: item.productionNumber,
            column2: item.agencyName,
            column3: item.productionTitle,
            column4: item.producerName,
            column5: item.genreName,
            // Theater
            column6: functions.getDateTimeView(item.theater.firstPerformanceDate),
            column7: functions.getDateTimeView(item.theater.lastPerformanceDate),
            column8: item.theater.performanceCount,
            column9: item.theater.capacity,
            column10: item.theater.theaterCount,
            column11: item.theater.days[0].ticketCount,
            column12: item.theater.days[1].ticketCount,
            column13: item.theater.mutationNumber,
            column14:
              item.theater.mutationRate != null && item.mutationRate != ''
                ? parseFloat(item.theater.mutationRate.toFixed(2))
                : '',
            column15:
              item.theater.occupancyRate != null && item.occupancyRate != ''
                ? parseFloat(item.theater.occupancyRate.toFixed(2))
                : '',
            column16:
              item.theater.ticketPrice != null && item.ticketPrice != ''
                ? parseFloat(item.theater.ticketPrice.toFixed(2))
                : '',
            column17:
              item.theater.revenueInVat != null && item.revenueInVat != ''
                ? parseFloat(item.theater.revenueInVat.toFixed(2))
                : '',
            // Landelijk
            column18: functions.getDateTimeView(item.national.firstPerformanceDate),
            column19: functions.getDateTimeView(item.national.lastPerformanceDate),
            column20: item.national.performanceCount,
            column21: item.national.capacity,
            column22: item.national.theaterCount,
            column23: item.national.days[0].ticketCount,
            column24: item.national.days[1].ticketCount,
            column25: item.national.mutationNumber,
            column26:
              item.national.mutationRate != null && item.mutationRate != ''
                ? parseFloat(item.national.mutationRate.toFixed(2))
                : '',
            column27:
              item.national.occupancyRate != null && item.occupancyRate != ''
                ? parseFloat(item.national.occupancyRate.toFixed(2))
                : '',
            column28:
              item.national.ticketPrice != null && item.ticketPrice != ''
                ? parseFloat(item.national.ticketPrice.toFixed(2))
                : '',
            column29:
              item.national.revenueInVat != null && item.revenueInVat != ''
                ? parseFloat(item.national.revenueInVat.toFixed(2))
                : '',
            // Verschil
            column30:
              item.occupancyRateDifference != null && item.occupancyRateDifference != ''
                ? parseFloat(item.occupancyRateDifference.toFixed(2))
                : '',
            column31: item.salesBoothDifference,
          });
        });
      }
    },
    changeRowBackground: function (item) {
      return item.inPast ? 'in-past' : '';
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),

    ...mapGetters('storage', ['getFilter']),
  },
  watch: {
    filter: {
      handler: 'getProductions',
      deep: true,
    },
  },
};
</script>
