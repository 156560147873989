<template>
  <v-card
    class="pa-5 mb-1"
    flat
    width="100%"
    height="100%"
  >
    <v-container>
      <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="text-primary font-weight-medium mb-0"></p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>
      <v-row
        flex
        class="d-flex flex-grow-1"
      >
        <template>
          <!-- Capaciteit-->
          <v-col
            cols="6"
            sm="12"
            md="6"
            class="py-0 mb-4"
          >
            <p class="text-primary font-weight-medium mb-0">capaciteit</p>
            <table class="text-primary">
              <tr>
                <td>{{ formatThousandsWithDot(this.occupancy.capacity) }}</td>
                <td v-if="this.occupancy.comparison != null">
                  {{ formatThousandsWithDot(this.occupancy.comparison.capacity) }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-medium">eigen theater</td>
                <td class="font-weight-medium" v-if="this.occupancy.comparison != null">theaters selectie</td>
              </tr>
            </table>
          </v-col>
          <!-- Aantal verkochte kaarten -->
          <v-col
            cols="6"
            sm="12"
            md="6"
            class="py-0 mb-4"
          >
            <p class="text-primary font-weight-medium mb-0">aantal verkochte kaarten</p>
            <table class="text-primary">
              <tr>
                <td>{{ formatThousandsWithDot(this.occupancy.tickets) }}</td>
                <td v-if="this.occupancy.comparison != null">
                  {{ formatThousandsWithDot(this.occupancy.comparison.tickets) }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-medium">eigen theater</td>
                <td class="font-weight-medium" v-if="this.occupancy.comparison != null">theaters selectie</td>
              </tr>
            </table>
          </v-col>
          <!-- Aantal kaartkopers -->
          <v-col
            cols="6"
            sm="12"
            md="6"
            class="py-0 mb-4"
            v-if="this.occupancy != null"
          >
            <p class="text-primary font-weight-medium mb-0">aantal kaartkopers</p>
            <table class="text-primary">
              <tr>
                <td>{{ formatThousandsWithDot(this.occupancy.purchases) }}</td>
                <td v-if="this.occupancy.comparison != null">
                  {{ formatThousandsWithDot(this.occupancy.comparison.purchases) }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-medium">eigen theater</td>
                <td class="font-weight-medium" v-if="this.occupancy.comparison != null">theaters selectie</td>
              </tr>
            </table>
          </v-col>

          <v-col
            cols="6"
            sm="12"
            md="6"
            class="py-0"
            v-if="this.occupancy != null"
          >
            <p class="text-primary font-weight-medium mb-0">aantal nieuwe kaartkopers</p>
            <table class="text-primary">
              <tr>
                <td>{{ formatThousandsWithDot(this.occupancy.newPurchases) }} ({{ this.newPurchasesPercentage }}%)</td>
                <td v-if="this.occupancy.comparison != null">
                  {{ formatThousandsWithDot(this.occupancy.comparison.newPurchases) }} ({{ this.newPurchasesComparisonPercentage }}%)
                </td>
              </tr>
              <tr>
                <td class="font-weight-medium">eigen theater</td>
                <td class="font-weight-medium" v-if="this.occupancy.comparison != null">theaters selectie</td>
              </tr>
            </table>
          </v-col>
          <!-- Bezettingspercentage -->
          <v-col
            cols="12"
            class="pt-1 pb-4"
            v-if="this.occupancy.rate > 0"
          >
            <v-progress-linear
              class="custom_progress"
              bg-color="#e1e7f2"
              height="20"
              width="200"
              rounded="true"
              :model-value="this.occupancy.rate"
              color="#0abfb8"
            >
              <div
                class="target"
                :style="{
                  left: this.occupancy.comparison.rate + '%',
                }"
                v-if="this.occupancy.comparison != null"
              ></div>
            </v-progress-linear>
            <i v-if="this.occupancy.rate > 0 && this.occupancy.comparison == null" class="text-primary">
              bezettingspercentage
              {{ getPercentageView(this.occupancy.rate) }}
            </i>
            <i v-else-if="this.occupancy.rate > 0 && this.occupancy.comparison != null" class="text-primary">
              {{
                `bezettingspercentage ${getPercentageView(
                  this.occupancy.rate
                )} ${'(alle theaters:'} ${getPercentageView(this.occupancy.comparison.rate)})`
              }}
            </i>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import functions from '../../../services/functions.service';
import Help from '../../../components/Help.vue';

export default {
  props: {
    occupancy: {
      type: Object,
      required: true,
    },
    helpId: {
      type: Number,
      required: false,
    },
  },
  components: {
    Help,
  },
  data() {
    return {
      benchmarkOccupancyRate: {},
    };
  },
  computed: {
    newPurchasesPercentage() {
      if (this.occupancy.purchases === 0) {
        return '0.00';
      }
      const value = (this.occupancy.newPurchases / this.occupancy.purchases) * 100;
      return value.toFixed(2);
    },
    newPurchasesComparisonPercentage() {
      if (this.occupancy.comparison.purchases === 0) {
        return '0.00';
      }
      const value = (this.occupancy.comparison.newPurchases / this.occupancy.comparison.purchases) * 100;
      return value.toFixed(2);
    },
  },
  methods: {
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    getDateTimeView(value) {
      return functions.getDateTimeView(value);
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  font-weight: 700;
  font-size: 16px;
}
</style>