<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row v-if="rights.edit && (user.organisationTypeId == 1 || user.organisationTypeId == 3)" align="center"
          class="px-3 mb-4">
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Een nieuwe productie toevoegen?</span>
            <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase"
              :to="{ name: 'LibraryProductionsAdd' }">
              Aanmaken nieuwe productie
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="46"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field v-model="tableProductionsFilter.title" label="op naam zoeken" hide-details dense density="compact" outlined
                bg-color="#fff" class="ma-1 input-sm"></v-text-field>
              <v-autocomplete v-model="tableProductionsFilter.seasonId" :items="seasons" item-text="text"
                @change="getProductions()" label="seizoen selecteren" hide-details dense  density="compact" outlined
                bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog v-model="tableProductionsFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field v-model="tableProductionsFilter.title" label="op naam zoeken" hide-details dense density="compact"
                      outlined bg-color="#fff" class="ma-3"></v-text-field>
                    <v-autocomplete v-model="tableProductionsFilter.seasonId" :items="seasons" item-text="text"
                      @change="getProductions()" label="seizoen selecteren" hide-details dense  density="compact" outlined
                      bg-color="#fff" class="ma-3"></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableProductionsFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" outlined dark
                      @click="clearFilterProductions()">reset
                      filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tableProductionsHeaders" :items="tableProductionsFiltered" :mobile-breakpoint="0"
          :loading="tableProductionsLoading" :page.sync="tableProductionsPaging.page"
          :items-per-page="tableProductionsPaging.itemsPerPage" hide-default-footer
          @update:current-items="tableProductionsPaging.pageCount = Math.ceil(tableProductionsFiltered.length / tableProductionsPaging.itemsPerPage)"
          @update:items-per-page="tableProductionsPaging.pageCount = Math.ceil(tableProductionsFiltered.length / tableProductionsPaging.itemsPerPage)"
          @click:row="goToDetail" class="table-action row-is-clickable" fixed-header
          :height="tableProductionsFiltered.length > 10 ? '55vh' : ''">
          <template v-slot:[`item.action`]="{ item }">
            <v-btn v-if="item.hasFiles" rounded="circle" elevation="0" size="small" icon="icon-paperclip" color="primary">
              <v-icon color="white" class="icon-paperclip">mdi-attachment</v-icon>
            </v-btn>
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-eye" color="warning" class="ml-1" :to="{
              name: 'LibraryProductionsDetail',
              params: { productionNumber: item.number },
            }">
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
            <v-btn v-if="rights.edit && (user.organisationTypeId == 1 || user.organisationTypeId == 3) && item.isOwn"
              rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline" color="info" class="ml-1" :to="{
                name: 'LibraryProductionsEdit',
                params: { productionNumber: item.number },
              }">
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>


        </v-data-table>

        <!-- Tabel settings -->
        <v-row class="text-center pt-2" align="center" no-gutters>
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption text-primary ma-0 pa-0">
              totaal: {{ this.tableProductionsFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col cols="12" sm="4" class="d-flex align-center justify-center justify-sm-start">
            <v-btn v-if="tableProductionsFiltered.length > 25" @click="tableProductionsPaging.itemsPerPage = 25"
              :color="tableProductionsPaging.itemsPerPage == 25 ? 'primary' : '#fff'" height="45" width="45"
              elevation="0" x-small class="pa-0 mr-1 font-weight-bold body-2">25</v-btn>
            <v-btn v-if="tableProductionsFiltered.length > 25" @click="tableProductionsPaging.itemsPerPage = 50"
              :color="tableProductionsPaging.itemsPerPage == 50 ? 'primary' : '#fff'" height="45" width="45"
              elevation="0" x-small class="pa-0 mr-1 font-weight-bold body-2">50</v-btn>
            <v-btn v-if="tableProductionsFiltered.length > 50" @click="tableProductionsPaging.itemsPerPage = 100"
              :color="tableProductionsPaging.itemsPerPage == 100 ? 'primary' : '#fff'" height="45" width="45"
              elevation="0" x-small class="pa-0 mr-1 font-weight-bold body-2">100</v-btn>
          </v-col>

          <!-- Table pagination -->
          <v-col cols="12" sm="4">
            <v-pagination v-if="tableProductionsPaging.pageCount > 1" v-model="tableProductionsPaging.page"
              :length="tableProductionsPaging.pageCount" :total-visible="7" :elevation="0"></v-pagination>
          </v-col>

          <!-- Table downlaod -->
          <v-col cols="12" sm="4" class="d-flex justify-center justify-sm-end">
            <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase">
              <vue-excel-xlsx class="v-btn font-weight-bold text-lowercase" :data="tableProductionsFiltered"
                :columns="downloadExportColumns" :filename="'dip-producties-export'" :sheetname="'Producties'">exporteer
                selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '../../../components/BreadcrumbsWithHelp.vue';

export default {
  name: 'LibraryProductions',
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/beheer/producties',
          bold: true,
        },
      ],
      seasonId: '',
      seasons: [],
      productions: [],
      tableProductionsFiltered: [],
      tableProductionsLoading: true,
      tableProductionsHeaders: [],
      tableProductionsFilter: {
        title: '',
        seasonId: '',
      },
      tableProductionsPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableProductionsFilterDialog: false,
      downloadExportColumns: [
        { label: 'productienr', field: 'number' },
        { label: 'naam', field: 'title' },
        { label: 'producent', field: 'producerName' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryProductions');

    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableProductionsFilter,
    });
    this.tableProductionsFilter = this.getFilter(this.$options.name);

    this.getSeasons();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'LibraryProductionsDetail',
        params: { productionNumber: row.number },
      });
    },
    getSeasons: function () {
      const vm = this;
      ApiService.get('/Seasons/GetAll')
        .then((response) => {
          response.data.result.forEach((element) => {
            this.seasons.push({ value: element.id, title: element.name });
          });

          var now = new Date();
          response.data.result.forEach((element) => {
            var startDate = new Date(element.startDate);
            var endDate = new Date(element.endDate);

            if (now > startDate && now < endDate) {
              vm.seasonId = element.id;
              vm.tableProductionsFilter.seasonId = vm.seasonId;
              this.getProductions();
            }
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getProductions: function () {
      this.tableProductionsLoading = true;
      this.tableProductionsFiltered = [];
      this.productions = [];
      ApiService.get('/Productions/GetAll', {
        SeasonId: this.tableProductionsFilter.seasonId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.productions = response.data.result;

          for (var i = 0; i < this.productions.length; i++) {
            this.productions[i].producerName = this.productions[i].producer.name;
          }

          if (this.user.organisationTypeId == 3) {
            this.tableProductionsHeaders = [
              { title: 'productienr', value: 'number' },
              { title: 'naam', value: 'title' },
              { title: 'impresariaat koppeling', value: 'agency.name' },
              { title: '', value: 'action', sortable: false, align: 'end' },
            ];
          } else {
            this.tableProductionsHeaders = [
              { title: 'productienr', value: 'number' },
              { title: 'naam', value: 'title' },
              { title: 'producent', value: 'producer.name' },
              { title: '', value: 'action', sortable: false, align: 'end' },
            ];
          }

          this.tableProductionsFiltered = this.productions;
          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tableProductionsPaging,
          });
          this.tableProductionsPaging = this.getFilter(this.$options.name + '-paging');

          this.filterProductions();

          this.tableProductionsLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterProductions: function () {
      const vm = this;
      this.tableProductionsFiltered = this.productions.filter(function (production) {
        return cleanWords(production.title).indexOf(cleanWords(vm.tableProductionsFilter.title)) !== -1;
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterProductions: function () {
      this.tableProductionsFilter.title = '';
      this.tableProductionsFilter.seasonId = this.seasonId;
      this.tableProductionsFilterDialog = false;
      this.getProductions();
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),

    ...mapGetters('storage', ['getFilter']),
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableProductionsFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableProductionsPaging,
    });
  },
  watch: {
    tableProductionsFilter: {
      handler: 'filterProductions',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.icon-paperclip {
  transform: rotate(-90deg);
}
</style>