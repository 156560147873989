<template>
  <v-col class="d-flex align-center py-2">
    <v-breadcrumbs :items="breadcrumbs" class="pa-0 pl-4">
      <template v-slot:divider>
        <v-icon class="text-primary">mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="{ path: item.href }"
          :exact="true"
          :disabled="item.disabled"
        >
          <span
            class="text-primary"
            :class="{ 'font-weight-bold': item.bold }"
          >
            {{ item.text }}
          </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <help
      bg-color="#fff"
      :help-id="helpId"
    ></help>
  </v-col>
</template>

<script>
import Help from '@/components/Help.vue';

export default {
  name: 'BreadcrumbsWithHelp',
  components: {
    Help,
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
    helpId: {
      type: String,
      required: true,
    },
  },
};
</script>
