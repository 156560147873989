<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="13"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- Form -->
              <v-form
                @submit.prevent
                v-model="formValid"
                class="d-flex flex-column col col-6"
                ref="form"
              >
                <h1 class="text-primary">{{ this.theater.name }}</h1>
                <h3 class="text-primary mb-5">{{ this.user.name }}</h3>
                <h4 class="text-primary mb-2 font-weight-regular">gebruikersrol wijzigen</h4>

                <v-card
                  class="pa-6 mb-1"
                  flat
                >
                  <v-row class="mt-0">
                    <!-- Userrol -->
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-select
                        label="gebruikersrol"
                        v-model="editUserLinkForm.roleId"
                        :items="userRoleOptions"
                        :rules="formValidationRules.required"
                        required
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card>

                <p class="grey--text text--lighten-1 mt-0">
                  <small
                    ><i>{{ this.lastEditedBy }}</i></small
                  >
                </p>

                <v-alert
                  v-if="formError != ''"
                  text
                  color="error"
                  >{{ formError }}</v-alert
                >

                <v-btn
                  rounded
                  large
                  elevation="0"
                  @click="submit"
                  :loading="formLoading"
                  color="secondary"
                  class="text-lowercase mb-5 align-self-start"
                  >Opslaan
                  <v-icon
                    small
                    right
                    >mdi-content-save</v-icon
                  ></v-btn
                >
              </v-form>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'AdminTheatersLinkEdit',
  props: ['theaterId', 'linkId'],
  components: {
    Help,
  },
  data() {
    return {
      theater: {
        name: '',
      },
      user: {
        name: '',
      },
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      editUserLinkForm: {
        roleId: '',
        userId: '',
        organisationTypeId: '',
        organisationId: '',
      },
      lastEditedBy: '',
      userRoleOptions: [],
      formValidationRules: {},
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'theaters',
          disabled: false,
          href: '/admin/theaters',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getTheater();
    this.getUserRoles();
    this.getLink();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getTheater: function () {
      ApiService.get('/Theaters/Get', {
        theaterId: this.theaterId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const theater = response.data.result;
            this.breadcrumbs[2].text = theater.name;
            this.breadcrumbs[2].href = '/admin/theaters/' + this.theaterId + '/';
            this.theater.name = theater.name;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getUserRoles: function () {
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: 2,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.userRoleOptions.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getLink: function () {
      const vm = this;

      ApiService.get('/UserRoles/Get', {
        linkId: this.linkId,
        organisationTypeId: 2,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const link = response.data.result;
            this.editUserLinkForm.organisationTypeId = link.organisationTypeId;
            this.editUserLinkForm.roleId = link.userRoleId;
            this.editUserLinkForm.organisationId = link.organisationId;
            this.editUserLinkForm.userId = link.userId;
            this.lastEditedBy = link.lastEditedBy;
            this.getUser();

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getUser: function () {
      ApiService.get('/Users/Get', {
        userId: this.editUserLinkForm.userId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const link = response.data.result;

            this.user.firstName = link.firstName != null ? link.firstName : '';
            this.user.lastName = link.lastName != null ? link.lastName : '';

            this.user.name = this.user.firstName + ' ' + this.user.lastName;
            this.breadcrumbs[3].text = this.user.name;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editUserLinkForm;

        ApiService.put('/UserRoles/Update', {
          linkId: this.linkId,
          organisationTypeId: form.organisationTypeId,
          organisationId: form.organisationId,
          roleId: form.roleId,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    editUserLinkForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
