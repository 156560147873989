<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="34"></breadcrumbs-with-help>

          <v-col cols="12">
            <v-card title="gegevens castlid">
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <p class="text-primary mb-0">
                        <small>naam:</small> <br />
                        <strong>{{ creativeCast.name }}</strong>
                      </p>
                    </v-col>
                  </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <div>
                <p class="grey--text text--lighten-1 mb-2">
                    <small
                      ><i>{{ this.creativeCast.lastEditedBy }}</i></small
                    >
                  </p>

                  <!-- Bewerken -->
                   <v-btn-edit class="mr-5" :to="{ name: 'AdminCreativeCastEdit' }">Gegevens Bewerken</v-btn-edit>

                  <!-- Verwijderen -->
                  <v-btn-delete @click="deleteItemDialog = true">Verwijderen</v-btn-delete>
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.creativeCast.name }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.creativeCast.name }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemCreativeCast()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

export default {
  name: 'AdminCreativeCastDetail',
  props: ['creativeCastId'],
  components: {
    BreadcrumbsWithHelp,
  },
  data() {
    return {
      creativeCast: {
        name: '',
        lastEditedBy: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'creatieve cast',
          disabled: false,
          href: '/admin/creatievecast',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemDialog: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
    };
  },
  mounted() {
    this.getCreativeCast();
  },
  methods: {
    getCreativeCast: function () {
      ApiService.get('/CreativeCasts/Get', {
        creativeCastId: this.creativeCastId,
      })
        .then((response) => {
          if (response.data.success) {
            const creativeCast = response.data.result;
            this.breadcrumbs[2].text = creativeCast.name;
            this.creativeCast.name = creativeCast.name;
            this.creativeCast.lastEditedBy = creativeCast.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    deleteItemCreativeCast: function () {
      ApiService.delete('/CreativeCasts/Delete', {
        creativeCastId: this.creativeCastId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.creativeCast.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/admin/creatievecast' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {},
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
