<template>
  <v-col class="py-0 d-flex align-center justify-start">
    <div class="d-none d-sm-flex flex-sm-wrap">
      <v-col
        sm="4"
        md="2"
        lg="auto" 
        xl="auto"
        class="pa-0"
      >
        <v-autocomplete
          v-model="filterWidgets.buyerAge"
          :items="filterOptions.buyerAges"
          label="leeftijdsopbouw"
          clearable
          hide-details
          density="compact"
          outlined
          bg-color="#fff"
          class="ma-1 input-sm"
        ></v-autocomplete>
      </v-col>
      <v-col
        sm="4"
        md="2"
        lg="auto" 
        xl="auto"
        class="pa-0"
      >
        <v-autocomplete
          v-model="filterWidgets.purchasePeriod"
          :items="filterOptions.purchasePeriods"
          label="moment van aankoop"
          clearable
          hide-details
          density="compact"
          outlined
          bg-color="#fff"
          class="ma-1 input-sm"
        ></v-autocomplete>
      </v-col>
      <v-col
        sm="4"
        md="2"
        lg="auto" 
        xl="auto"
        class="pa-0"
      >
        <v-autocomplete
          v-model="filterWidgets.salesType"
          :items="filterOptions.salesTypes"
          label="zoek op prijssoort"
          clearable
          hide-details
          density="compact"
          outlined
          bg-color="#fff"
          class="ma-1 input-sm"
        ></v-autocomplete>
      </v-col>
      <v-col
        sm="4"
        md="2"
        lg="auto" 
        xl="auto"
        class="pa-0"
      >
        <v-autocomplete
          v-model="filterWidgets.ticketCount"
          :items="filterOptions.ticketCounts"
          label="aantal gekochte kaarten"
          clearable
          hide-details
          density="compact"
          outlined
          bg-color="#fff"
          class="ma-1 input-sm"
        ></v-autocomplete>
      </v-col>
      <v-col
        sm="4"
        md="2"
        lg="auto" 
        xl="auto"
        class="pa-0"
      >
        <v-autocomplete
          v-model="filterWidgets.buyerGender"
          :items="filterOptions.buyerGenders"
          label="geslacht kaartkopers"
          clearable
          hide-details
          density="compact"
          outlined
          bg-color="#fff"
          class="ma-1 input-sm"
        ></v-autocomplete>
      </v-col>
      <v-col
        sm="4"
        md="2"
        lg="auto" 
        xl="auto"
        class="pa-0"
      >
        <v-autocomplete
          v-model="filterWidgets.dayOfPurchase"
          :items="filterOptions.dayOfPurchases"
          label="dag van aankoop"
          clearable
          hide-details
          density="compact"
          outlined
          bg-color="#fff"
          class="ma-1 input-sm"
        ></v-autocomplete>
      </v-col>
      <v-col
        sm="4"
        md="2"
        lg="auto" 
        xl="auto"
        class="pa-0"
      >
        <v-autocomplete
          v-model="filterWidgets.buyerDistance"
          :items="filterOptions.buyerDistances"
          label="geografische spreiding"
          clearable
          hide-details
          density="compact"
          outlined
          bg-color="#fff"
          class="ma-1 input-sm"
        ></v-autocomplete>
      </v-col>
    </div>

    <!-- Filter dialog -->
    <div class="d-sm-none">
      <v-dialog
        v-model="widgetFilterDialog"
        width="500"
        fullscreen
      >
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn
            class="font-weight-bold text-lowercase"
            color="success"
            rounded
            dark
            v-bind="activatorProps"
            >filter overzicht
            <v-icon
              small
              right
              >mdi-filter</v-icon
            ></v-btn
          >
        </template>

        <v-card>
          <v-card-title class="headline dark"><h5 class="text-primary">filter overzicht</h5></v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="filterWidgets.buyerAge"
              :items="filterOptions.buyerAges"
              label="leeftijdsopbouw"
              clearable
              hide-details
              density="compact"
              outlined
              bg-color="#fff"
              class="ma-1 input-sm"
            ></v-autocomplete>
            <v-autocomplete
              v-model="filterWidgets.purchasePeriod"
              :items="filterOptions.purchasePeriods"
              label="moment van aankoop"
              clearable
              hide-details
              density="compact"
              outlined
              bg-color="#fff"
              class="ma-1 input-sm"
            ></v-autocomplete>
            <v-autocomplete
              v-model="filterWidgets.salesType"
              :items="filterOptions.salesTypes"
              label="zoek op prijssoort"
              clearable
              hide-details
              density="compact"
              outlined
              bg-color="#fff"
              class="ma-1 input-sm"
            ></v-autocomplete>
            <v-autocomplete
              v-model="filterWidgets.ticketCount"
              :items="filterOptions.ticketCounts"
              label="aantal gekochte kaarten"
              clearable
              hide-details
              density="compact"
              outlined
              bg-color="#fff"
              class="ma-1 input-sm"
            ></v-autocomplete>
            <v-autocomplete
              v-model="filterWidgets.buyerGender"
              :items="filterOptions.buyerGenders"
              label="geslacht kaartkopers"
              clearable
              hide-details
              density="compact"
              outlined
              bg-color="#fff"
              class="ma-1 input-sm"
            ></v-autocomplete>
            <v-autocomplete
              v-model="filterWidgets.dayOfPurchase"
              :items="filterOptions.dayOfPurchases"
              label="dag van aankoop"
              clearable
              hide-details
              density="compact"
              outlined
              bg-color="#fff"
              class="ma-1 input-sm"
            ></v-autocomplete>
            <v-autocomplete
              v-model="filterWidgets.buyerDistance"
              :items="filterOptions.buyerDistances"
              label="geografische spreiding"
              clearable
              hide-details
              density="compact"
              outlined
              bg-color="#fff"
              class="ma-1 input-sm"
            ></v-autocomplete>
          </v-card-text>

          <v-card-actions class="justify-center flex-column">
            <v-btn
              class="font-weight-bold text-lowercase mb-5"
              color="success"
              depressed
              @click="widgetFilterDialog = false"
              >filter overzicht
              <v-icon
                small
                right
                >mdi-filter</v-icon
              ></v-btn
            >
            <v-btn
              class="font-weight-bold text-lowercase"
              color="error"
              outlined
              dark
              @click="clearFilterWidgets()"
              >reset filter
              <v-icon
                small
                right
                >mdi-close</v-icon
              ></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-col>
</template>

<script>
const FILTER_TYPES = [
  'salesType',
  'purchasePeriod',
  'buyerAge',
  'buyerLoyalty',
  'buyerBehavior',
  'buyerGender',
  'buyerDistance',
  'ticketCount',
  'dayOfPurchase',
];
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      widgetFilterDialog: false,
      filterWidgets: {
        salesType: '',
        purchasePeriod: '',
        buyerAge: '',
        buyerLoyalty: '',
        buyerBehavior: '',
        buyerGender: '',
        buyerDistance: '',
        ticketCount: '',
        dayOfPurchase: '',
      },
      salesTypes: [],
      buyerAges: [],
      buyerLoyalties: [],
      buyerBehaviors: [],
      purchasePeriods: [],
      buyerGenders: [],
      buyerDistances: [],
      dayOfPurchases: [],
      ticketCounts: [],
    };
  },
  methods: {
    clearFilterWidgets() {
      FILTER_TYPES.forEach((filterType) => {
        this.filterWidgets[filterType] = '';
      });
    },
  },
  watch: {
    filterWidgets: {
      handler() {
        this.$emit('input', this.filterWidgets);
      },
      deep: true,
    },
  },
  mounted() {
    this.clearFilterWidgets();
  },
};
</script>
