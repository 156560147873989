<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="54"
            ></help>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <h1 class="text-primary mb-5">{{ this.area.name }}</h1>
                  <h4 class="text-primary mb-2 font-weight-regular">gegevens zaal</h4>

                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>naam: </small><br /><strong> {{ area.name }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>

                  <h4 class="text-primary mb-2 font-weight-regular">adres</h4>
                  <v-card
                    class="pa-6 mb-1"
                    flat
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>straat en huisnummer:</small> <br />
                          <strong>{{ area.street + ' ' + area.number }}</strong>
                        </p>
                        <hr class="accent-hr my-2" />
                        <p class="text-primary mb-0">
                          <small>plaats:</small> <br />
                          <strong>{{ area.city }}</strong>
                        </p>
                        <hr class="accent-hr my-2" />
                        <p class="text-primary mb-0">
                          <small>postcode:</small> <br />
                          <strong>{{ area.zipCode }}</strong>
                        </p>
                        <hr class="accent-hr my-2" />
                        <p class="text-primary mb-0">
                          <small>provincie:</small> <br />
                          <strong>{{ area.province.name }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>

                  <div>
                    <p class="grey--text text--lighten-1 mt-0">
                      <small
                        ><i>{{ this.area.lastEditedBy }}</i></small
                      >
                    </p>
                  </div>
                </v-col>
              </v-row>
              <!-- Active User overview -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                    <h4 class="text-primary font-weight-regular">toeslagen</h4>
                    <v-btn
                      v-if="rights.edit"
                      rounded
                      small
                      elevation="0"
                      color="primary"
                      class="text-lowercase"
                      :to="{ name: 'LibraryAreasSurchargeAdd' }"
                      >toeslag toevoegen</v-btn
                    >
                  </v-row>

                  <!-- Table -->
                  <v-data-table
                    :headers="tableTheaterAreasSurchargesHeaders"
                    :items="tableTheaterAreasSurchargesFiltered"
                    :mobile-breakpoint="0"
                    :loading="tableTheaterAreasSurchargesLoading"
                    :items-per-page="-1"
                    hide-default-footer
                    class="table-action"
                  >
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        v-if="rights.edit"
                        fab
                        x-small
                        depressed
                        color="info"
                        class="ml-1"
                        :to="{ name: 'LibraryAreasSurchargeEdit', params: { surchargeId: item.id } }"
                      >
                        <v-icon color="primary">mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="rights.edit"
                        fab
                        x-small
                        depressed
                        color="error"
                        class="ml-1"
                        @click="(deleteItemLink = item), (deleteItemLinkDialog = true);"
                      >
                        <v-icon color="#fff">mdi-delete</v-icon>
                      </v-btn>
                    </template>


                  </v-data-table>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.area.name }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.area.name }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemArea()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog link starts here -->
    <v-dialog
      v-model="deleteItemLinkDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Toeslag verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je deze toeslag wil verwijderen? Het is daarna niet meer terug te vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemLinkDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteAreaSurcharge()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryAreasDetail',
  props: ['theaterAreaId'],
  components: {
    Help,
  },
  data() {
    return {
      area: {
        name: '',
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: '',
        lastEditedBy: '',
      },
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'zalen',
          disabled: false,
          href: '/beheer/zalen',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemDialog: false,
      deleteItemLinkDialog: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      tableTheaterAreasSurchargesHeaders: [
        { title: 'startdatum', key: 'startDate' },
        { title: 'theatertoeslag', key: 'theaterFee', dataType: 'Currency' },
        { title: 'consumptietoeslag', key: 'consumptionFee' },
        { title: 'servicetoeslag', key: 'serviceFee' },
        { title: 'garderobetoeslag', key: 'wardrobeFee' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableTheaterAreasSurchargesFiltered: [],
      tableTheaterAreasSurchargesLoading: true,
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryAreasDetail');
    this.getArea();
    this.getTheaterAreaSurcharges();
  },
  methods: {
    getArea: function () {
      ApiService.get('/TheaterAreas/Get', {
        theaterAreaid: this.theaterAreaId,
      })
        .then((response) => {
          if (response.data.success) {
            const area = response.data.result;
            this.breadcrumbs[2].text = area.name;
            this.area.name = area.name;
            this.area.street = area.street;
            this.area.number = area.number;
            this.area.city = area.city;
            this.area.zipCode = area.zipCode;
            this.area.province = area.province;
            this.area.lastEditedBy = area.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getTheaterAreaSurcharges: function () {
      const vmLegacy = { $set: (o, k, v) => o[k] = v };

      ApiService.get('/Surcharges/GetAllPerTheaterArea', {
        theaterAreaId: this.theaterAreaId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableTheaterAreasSurchargesFiltered = response.data.result;
            this.tableTheaterAreasSurchargesLoading = false;

            for (var j = 0; j < this.tableTheaterAreasSurchargesFiltered.length; j++) {
              vmLegacy.$set(
                this.tableTheaterAreasSurchargesFiltered[j],
                'theaterFee',
                functions.getEuroSignView(this.tableTheaterAreasSurchargesFiltered[j].theaterFee)
              );
              vmLegacy.$set(
                this.tableTheaterAreasSurchargesFiltered[j],
                'consumptionFee',
                functions.getEuroSignView(this.tableTheaterAreasSurchargesFiltered[j].consumptionFee)
              );
              vmLegacy.$set(
                this.tableTheaterAreasSurchargesFiltered[j],
                'serviceFee',
                functions.getEuroSignView(this.tableTheaterAreasSurchargesFiltered[j].serviceFee)
              );
              vmLegacy.$set(
                this.tableTheaterAreasSurchargesFiltered[j],
                'wardrobeFee',
                functions.getEuroSignView(this.tableTheaterAreasSurchargesFiltered[j].wardrobeFee)
              );
              vmLegacy.$set(
                this.tableTheaterAreasSurchargesFiltered[j],
                'startDate',
                functions.getDateView(this.tableTheaterAreasSurchargesFiltered[j].startDate)
              );
            }
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    deleteItemArea: function () {
      ApiService.delete('/TheaterAreas/Delete', {
        theaterAreaId: this.theaterAreaId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.area.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/beheer/zalen' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    deleteAreaSurcharge: function () {
      ApiService.delete('/Surcharges/Delete', {
        surchargeId: this.deleteItemLink.id,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableTheaterAreasSurchargesFiltered.map((item) => item.id).indexOf(this.deleteItemLink.id);
            this.tableTheaterAreasSurchargesFiltered.splice(i, 1);

            this.snackbarText = 'Toeslag is verwijderd';
            this.deleteItemLinkDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
