<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="56"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- Form -->
              <v-form
                @submit.prevent
                v-model="formValid"
                class="d-flex flex-column col col-6"
                ref="form"
              >
                <h1 class="text-primary mb-5">{{ this.area.name }}</h1>
                <h4 class="text-primary mb-2 font-weight-regular">toeslag toevoegen</h4>

                <v-card
                  class="pa-6 mb-10"
                  flat
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <date-picker
                        v-model="addAreaSurchargeForm.startDate"
                        :passedDate="null"
                        rules="dateRequired"
                      ></date-picker>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        label="theatertoeslag"
                        prefix="€"
                        v-model="addAreaSurchargeForm.theaterFee"
                        :rules="formValidationRules.valutaRequired"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        label="consumptietoeslag"
                        prefix="€"
                        v-model="addAreaSurchargeForm.consumptionFee"
                        :rules="formValidationRules.valutaRequired"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        label="servicetoeslag"
                        prefix="€"
                        v-model="addAreaSurchargeForm.serviceFee"
                        :rules="formValidationRules.valutaRequired"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        label="kosten garderobe"
                        prefix="€"
                        v-model="addAreaSurchargeForm.wardrobeFee"
                        :rules="formValidationRules.valutaRequired"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <v-btn
                  rounded
                  large
                  elevation="0"
                  @click="submit"
                  :loading="formLoading"
                  color="secondary"
                  class="text-lowercase mb-5 align-self-start"
                  >Opslaan
                  <v-icon
                    small
                    right
                    >mdi-content-save</v-icon
                  ></v-btn
                >
              </v-form>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import DatePicker from '../../../components/DatePicker';

import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryAreasSurchargeAdd',
  props: ['theaterAreaId'],
  components: {
    Help,
    DatePicker,
  },
  data() {
    return {
      area: {
        name: '',
      },
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      addAreaSurchargeForm: {
        startDate: '',
        theaterFee: '0,00',
        consumptionFee: '0,00',
        serviceFee: '0,00',
        wardrobeFee: '0,00',
      },
      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'zalen',
          disabled: false,
          href: '/beheer/zalen',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'toeslag toevoegen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryAreasSurchargeAdd');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getArea();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getArea: function () {
      ApiService.get('/TheaterAreas/Get', {
        theaterAreaId: this.theaterAreaId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          if (response.data.success) {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            const area = response.data.result;
            this.breadcrumbs[2].text = area.name;
            this.breadcrumbs[2].href = '/beheer/zalen/' + this.theaterAreaId + '/';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    submit() {
      this.formLoading = true;
      if (this.formValid) {
        const form = this.addAreaSurchargeForm;

        ApiService.post('/Surcharges/Add', {
          areaId: this.theaterAreaId,
          startDate: functions.getDateDatabase(form.startDate),
          theaterFee: functions.getEuroCurrencyDatabase(form.theaterFee),
          consumptionFee: functions.getEuroCurrencyDatabase(form.consumptionFee),
          serviceFee: functions.getEuroCurrencyDatabase(form.serviceFee),
          wardrobeFee: functions.getEuroCurrencyDatabase(form.wardrobeFee),
        })
          .then((response) => {
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formLoading = false;
            }
          })
          .catch((error) => {
            this.formError = error;
            // eslint-disable-next-line
            console.log('error ' + error);
            this.errored = true;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
  watch: {
    addAreaSurchargeForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
