<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="flex align-center pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="5"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <!-- Form -->
                  <v-form
                    @submit.prevent
                    v-model="formValid"
                    class="d-flex flex-column"
                    ref="form"
                  >
                    <h4 class="text-primary mb-5">gegevens gebruiker</h4>
                    <v-card
                      class="pa-6 mb-1"
                      flat
                    >
                      <v-row class="mt-0">
                        <!-- Voornaam -->
                        <v-col
                          cols="6"
                          class="py-0"
                        >
                          <v-text-field
                            class="text-primary"
                            label="voornaam"
                            v-model="editUserForm.firstName"
                            :rules="formValidationRules.required"
                            required
                            variant="outlined"
                          ></v-text-field>
                        </v-col>

                        <!-- Achternaam -->
                        <v-col
                          cols="6"
                          class="py-0"
                        >
                          <v-text-field
                            class="text-primary"
                            label="achternaam"
                            v-model="editUserForm.lastName"
                            :rules="formValidationRules.required"
                            required
                            variant="outlined"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!-- Email -->
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-text-field
                            class="text-primary"
                            label="e-mailadres"
                            v-model="editUserForm.email"
                            :rules="formValidationRules.emailRequired"
                            required
                            variant="outlined"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!-- Admin -->
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <v-checkbox
                            label="admin (medewerker DIP)"
                            v-model="editUserForm.isSuperUser"
                            class="checkbox text-primary ma-0"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-card>

                    <p class="grey--text text--lighten-1 mt-0">
                      <small
                        ><i>{{ this.lastEditedBy }}</i></small
                      >
                    </p>

                    <v-alert
                      v-if="formError != ''"
                      text
                      color="error"
                      >{{ formError }}</v-alert
                    >

                    <v-btn
                      rounded
                      large
                      elevation="0"
                      @click="submit"
                      :loading="formLoading"
                      color="secondary"
                      class="text-lowercase mb-5 align-self-start"
                      >Opslaan
                      <v-icon
                        small
                        right
                        >mdi-content-save</v-icon
                      ></v-btn
                    >
                  </v-form>
                </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'AdminUsersEdit',
  props: ['userId'],
  components: {
    Help,
  },
  data() {
    return {
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      editUserForm: {
        firstName: '',
        lastName: '',
        email: '',
        isSuperUser: false,
      },
      formValidationRules: {},
      lastEditedBy: '',
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/admin/gebruikers',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getUser();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getUser: function () {
      const vm = this;

      ApiService.get('/Users/Get', {
        userId: this.userId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const user = response.data.result;

            this.editUserForm.firstName = user.firstName != null ? user.firstName : '';
            this.editUserForm.lastName = user.lastName != null ? user.lastName : '';
            this.editUserForm.email = user.email;
            this.editUserForm.isSuperUser = user.isSuperUser;
            this.lastEditedBy = user.lastEditedBy;

            this.breadcrumbs[2].text = this.editUserForm.firstName + ' ' + this.editUserForm.lastName;
            this.breadcrumbs[2].href = '/admin/gebruikers/' + this.userId;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          router.back();
        });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editUserForm;

        ApiService.put('/Users/Update', {
          id: this.userId,
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          newPassword: '',
          api: '',
          isSuperUser: form.isSuperUser,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();

            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    editUserForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
