<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="57"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- Form -->
              <v-form
                @submit.prevent
                v-model="formValid"
                class="d-flex flex-column col col-6"
                ref="form"
              >
                <h4 class="text-primary mb-2 font-weight-regular">Toeslag toevoegen</h4>

                <v-card
                  class="pa-6 mb-1"
                  flat
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <!-- <v-text-field label="Start datum" placeholder="DD-MM-JJJJ" v-model="editAreaSurchargeForm.startDate" :rules="formValidationRules.dateRequired" required outlined dense></v-text-field> -->
                      <date-picker
                        v-if="datePickerDataLoaded"
                        v-model="editAreaSurchargeForm.startDate"
                        :passedDate="editAreaSurchargeForm.startDate"
                        rules="dateRequired"
                      ></date-picker>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        label="theatertoeslag"
                        prefix="€"
                        v-model="editAreaSurchargeForm.theaterFee"
                        :rules="formValidationRules.valutaRequired"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        label="consumptietoeslag"
                        prefix="€"
                        v-model="editAreaSurchargeForm.consumptionFee"
                        :rules="formValidationRules.valutaRequired"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        label="servicetoeslag"
                        prefix="€"
                        v-model="editAreaSurchargeForm.serviceFee"
                        :rules="formValidationRules.valutaRequired"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-text-field
                        label="kosten garderobe"
                        prefix="€"
                        v-model="editAreaSurchargeForm.wardrobeFee"
                        :rules="formValidationRules.valutaRequired"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <p class="grey--text text--lighten-1 mt-0">
                  <small
                    ><i>{{ this.lastEditedBy }}</i></small
                  >
                </p>

                <v-btn
                  rounded
                  large
                  elevation="0"
                  @click="submit"
                  :loading="formLoading"
                  color="secondary"
                  class="text-lowercase mb-5 align-self-start"
                  >Opslaan
                  <v-icon
                    small
                    right
                    >mdi-content-save</v-icon
                  ></v-btn
                >
              </v-form>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import DatePicker from '../../../components/DatePicker';

import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryAreasSurchargeEdit',
  props: ['theaterAreaId', 'surchargeId'],
  components: {
    Help,
    DatePicker,
  },
  data() {
    return {
      surchargeName: '',
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      datePickerDataLoaded: false,
      editAreaSurchargeForm: {
        startDate: '',
        theaterFee: '',
        consumptionFee: '',
        serviceFee: '',
        wardrobeFee: '',
      },
      lastEditedBy: '',
      userOptions: [],
      userRoleOptions: [],
      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'zalen',
          disabled: false,
          href: '/beheer/zalen',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'toeslag bewerken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryAreasSurchargeEdit');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getArea();
    this.getSurcharge();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getArea: function () {
      ApiService.get('/TheaterAreas/Get', {
        theaterAreaId: this.theaterAreaId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const area = response.data.result;
            this.breadcrumbs[2].text = area.name;
            this.breadcrumbs[2].href = '/beheer/zalen/' + this.theaterAreaId;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getSurcharge: function () {
      const vm = this;

      ApiService.get('/Surcharges/Get', {
        surchargeId: this.surchargeId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            this.datePickerDataLoaded = true;

            const link = response.data.result;
            this.editAreaSurchargeForm.startDate = functions.getDateView(link.startDate);
            this.editAreaSurchargeForm.theaterFee = functions.getEuroCurrencyView(link.theaterFee);
            this.editAreaSurchargeForm.consumptionFee = functions.getEuroCurrencyView(link.consumptionFee);
            this.editAreaSurchargeForm.serviceFee = functions.getEuroCurrencyView(link.serviceFee);
            this.editAreaSurchargeForm.wardrobeFee = functions.getEuroCurrencyView(link.wardrobeFee);
            this.lastEditedBy = link.lastEditedBy;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    submit() {
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editAreaSurchargeForm;

        ApiService.put('/Surcharges/Update', {
          id: this.surchargeId,
          startDate: functions.getDateDatabase(form.startDate),
          theaterFee: functions.getEuroCurrencyDatabase(form.theaterFee),
          consumptionFee: functions.getEuroCurrencyDatabase(form.consumptionFee),
          serviceFee: functions.getEuroCurrencyDatabase(form.serviceFee),
          wardrobeFee: functions.getEuroCurrencyDatabase(form.wardrobeFee),
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formLoading = false;
            }
          })
          .catch((error) => {
            this.formError = error;
            // eslint-disable-next-line
            console.log('error ' + error);
            this.errored = true;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
  watch: {
    editAreaSurchargeForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
