<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1130"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-lg-flex">
              <v-text-field
                v-model="tableTheatersFilter.theater"
                label="zoek op theater"
                hide-details
                density="compact"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-lg-none">
              <v-dialog
                v-model="tableTheatersFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-bind="activatorProps"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="text-primary">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="tableTheatersFilter.theater"
                      label="zoek op theater"
                      hide-details
                      dense
                      bg-color="#fff"
                      class="ma-1 input-sm"
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableTheatersFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      variant="outlined"
                      dark
                      @click="clearFilterTheaters()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableTheatersHeaders"
          :items="tableTheatersFiltered"
          :mobile-breakpoint="0"
          :loading="tableTheatersLoading"
          :page="tableTheatersPaging.page"
          :items-per-page="tableTheatersPaging.itemsPerPage"
          hide-default-footer
          @update:current-items="tableTheatersPaging.pageCount = Math.ceil(tableTheatersFiltered.length / tableTheatersPaging.itemsPerPage)"
          @update:items-per-page="tableTheatersPaging.pageCount = Math.ceil(tableTheatersFiltered.length / tableTheatersPaging.itemsPerPage)"
          class="table-action"
          fixed-header
          :height="tableTheatersFiltered.length > 10 ? '60vh' : ''"
          :sort-desc="false"
        >
          <template v-slot:[`item.lastSalesDate`]="{ item }">{{ getDateTimeView(item.lastSalesDate) }}</template>
        </v-data-table>

        <!-- Table settings -->
        <table-settings
          :items="tableTheatersFiltered"
          :paging="tableTheatersPaging"
          :export-columns="downloadExportColumns"
          :show-excel-button="true"
        ></table-settings>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '@/services/api.service';
import { TokenService } from '@/services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';
import functions from '@/services/functions.service';

export default {
  name: 'MonitorTicketSynchronization',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'monitor',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'kaartstanden synchronisatie',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      theaters: [],
      types: [],
      tableTheatersFiltered: [],
      tableTheatersLoading: true,
      tableTheatersHeaders: [
        { title: 'theater', key: 'theaterName' },
        { title: 'type sync', key: 'ticketLinkTypeString' },
        { title: 'Laatst bijgewerkt', key: 'lastSalesDate' },
      ],
      tableTheatersFilter: {
        theater: '',
      },
      tableTheatersPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableTheatersFilterDialog: false,
      downloadExportColumns: [
        { label: 'theater', field: 'theaterName' },
        { label: 'type sync', field: 'ticketLinkTypeString' },
        { label: 'Laatst bijgewerkt', field: 'lastSalesDate' },
      ],
    };
  },
  mounted() {
    // Filter
    this.checkFilter({ viewName: this.$options.name, filterObject: this.tableTheatersFilter });
    this.tableTheatersFilter = this.getFilter(this.$options.name);

    this.getTheaters();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    getDateTimeView: function (date) {
      return functions.getDateTimeView(date);
    },
    getTheaters: function () {
      this.theaters = [];
      this.tableTheatersFiltered = [];
      this.tableTheatersLoading = true;

      ApiService.get('/Monitor/GetLastSalesDates')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.theaters = response.data.result.map(theater => ({
            ...theater,
            ticketLinkTypeString: theater.ticketLinkType == 1
              ? 'handmatig'
              : theater.ticketLinkType == 2
              ? 'api'
              : theater.ticketLinkType == 3
              ? 'ticketmatic'
              : '-'
          }));

          this.tableTheatersFiltered = this.theaters;

          // Paging
          this.checkFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableTheatersPaging });
          this.tableTheatersPaging = this.getFilter(this.$options.name + '-paging');

          this.filterTheaters();

          this.tableTheatersLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterTheaters: function () {
      const vm = this;

      this.tableTheatersFiltered = this.theaters.filter(function (theater) {
        return cleanWords(theater.theaterName).indexOf(cleanWords(vm.tableTheatersFilter.theater)) !== -1;
      });

      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterTheaters: function () {
      this.tableTheatersFilter.theater = '';
      this.tableTheatersFilterDialog = false;
    },
  },
  computed: {
    ...mapGetters('storage', ['getFilter']),
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({ viewName: this.$options.name, filterObject: this.tableTheatersFilter });
    // Paging
    this.updateFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableTheatersPaging });
  },
  watch: {
    tableTheatersFilter: {
      handler: 'filterTheaters',
      deep: true,
    },
  },
};
</script>
