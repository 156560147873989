<template>
  <v-app>
    <v-navigation-drawer class="px-4" v-if="loggedIn" v-model="drawer" width="350" app floating disable-resize-watcher>
      <div class="py-7 d-flex align-center">
        <v-img src="/images/dip-logo.svg" contain height="65"></v-img>
      </div>

      <v-list nav>
        <template v-for="item in navigation">
          <template v-if="item.children.length > 0 && item.menu && item.children.some(e => e.menu)">
            <v-list-group no-action :key="item.menu.name">
              <template v-slot:activator="{ props }">
                <v-list-item v-bind="props">
                  <template #prepend>
                    <v-icon size="x-large" color="secondary">{{ item.menu.iconName }}</v-icon>
                  </template>
                  <v-list-item-title class="text-primary">{{ item.menu.name }}</v-list-item-title>
                </v-list-item>
              </template>
              <template v-if="item.menu.link">
                <v-list-item :key="item.menu.name" dense link :to="item.menu.link">
                  <v-list-item-title class="text-primary">{{ item.menu.name }}</v-list-item-title>
                </v-list-item>
              </template>
              <template v-for="subItem in item.children">
                <template v-if="subItem.menu">
                  <v-list-item :key="subItem.menu.name" dense link :to="subItem.menu.link">
                    <v-list-item-title class="text-primary">{{ subItem.menu.name }}</v-list-item-title>
                  </v-list-item>
                </template>
              </template>
            </v-list-group>
          </template>
          <template v-else-if="item.menu">
            <v-list-item :key="item.menu.name" link :to="item.menu.link">
              <template #prepend>
                <v-icon size="x-large" color="secondary">{{ item.menu.iconName }}</v-icon>
              </template>
              <v-list-item-title class="text-primary">{{ item.menu.name }}</v-list-item-title>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="loggedIn" app flat color="info" class="px-3" height="90">
      <div class="d-flex align-center">
        <v-btn @click="toggleDrawerState" small depressed icon="mdi-close" rounded="circle" color="warning"
          class="not-visible-on-print">
          <v-icon color="primary">{{ drawer ? 'mdi-close' : 'mdi-menu' }}</v-icon>
        </v-btn>
        <span class="pl-2 font-weight-bold text-primary pointer not-visible-on-print" @click="toggleDrawerState"
          v-if="drawer">sluit menu</span>
      </div>

      <v-spacer></v-spacer>

      <v-card flat plain color="info" class="text-right">
        <template #title class="font-weight-bold text-primary">{{ user.fullName }}</template>
        <template #subtitle>
          <div class="text-right" v-if="user.isSuperUser && (!user.loggedInAsUser || user.organisationId === 0)">admin
          </div>
          <div class="text-right" v-else-if="user.isSuperUser && user.roleName !== undefined && user.roleName !== ''">
            Ingelogd als <i>{{ user.loggedInAsUserFullName }} ({{ user.roleName }})</i> bij {{ user.organisationName }}
          </div>
          <div class="text-right" v-else-if="user.roleName !== undefined && user.roleName !== ''">
            Ingelogd als <i>{{ user.roleName }}</i> bij {{ user.organisationName }}
          </div>
        </template>
      </v-card>

      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" color="#fff" fab small depressed icon="mdi-close" rounded="circle">
            <v-icon color="primary">mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-card min-width="300">
          <v-list>
            <!-- Conditional rendering based on user state -->
            <template v-if="user.isSuperUser && user.loggedInAsUser">
              <v-list-item @click="switchRole({
                userId: user.userId,
                userRoleId: 0,
                organisationId: 0,
                organisationTypeId: 0,
              })" :to="{ path: '/' }">
                <template v-slot:prepend>
                  <v-icon size="x-large" color="primary">mdi-account-switch-outline</v-icon>
                </template>
                <v-list-item-title>Uitloggen gebruiker</v-list-item-title>
              </v-list-item>
            </template>

            <template v-else-if="user.isSuperUser && !user.loggedInAsUser">
              <v-list-item color="primary" :to="{ path: '/' }">
                <template v-slot:prepend>
                  <v-icon size="x-large" color="primary">mdi-account-switch-outline</v-icon>
                </template>
                <v-list-item-title>Inloggen als gebruiker</v-list-item-title>
              </v-list-item>
            </template>

            <template v-else-if="user.roles && user.roles.length > 1 && !user.isSuperUser">
              <v-list-group no-action class="switch-roles">
                <template v-slot:activator>
                  <v-icon color="primary">mdi-account-switch-outline</v-icon>
                  <v-list-item-title>Wissel rol</v-list-item-title>
                </template>
                <v-list-item dense v-for="role in user.roles" :key="role.roleId + role.organisationId" @click="switchRole({
                  userId: user.userId,
                  userRoleId: role.roleId,
                  organisationId: role.organisationId,
                  organisationTypeId: role.organisationTypeId,
                })">
                  <v-list-item-title :class="[role.active ? 'secondary--text' : '']">
                    {{ role.roleName + ' bij ' + role.organisationName }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
            </template>

            <!-- Other static menu items -->
            <v-list-item color="primary" :to="{ path: '/account' }">
              <template v-slot:prepend>
                <v-icon size="x-large" color="primary">mdi-account-outline</v-icon>
              </template>
              <v-list-item-title>Mijn account</v-list-item-title>
            </v-list-item>

            <v-list-item color="primary" href="https://support.dip.nl/support/solutions" target="_blank">
              <template v-slot:prepend>
                <v-icon size="x-large" color="primary">mdi-account-outline</v-icon>
              </template>
              <v-list-item-title>Instructies</v-list-item-title>
            </v-list-item>

            <v-list-item color="primary" href="https://dip.nl/actualiteiten/meldingen" target="_blank">
              <template v-slot:prepend>
                <v-icon size="x-large" color="primary">mdi-list-status</v-icon>
              </template>
              <v-list-item-title>Meldingen</v-list-item-title>
            </v-list-item>

            <v-list-item color="primary" @click="showSupport">
              <template v-slot:prepend>
                <v-icon size="x-large" color="primary">mdi-face-agent</v-icon>
              </template>
              <v-list-item-title>Support</v-list-item-title>
            </v-list-item>

            <v-list-item color="error" @click="logout">
              <template v-slot:prepend>
                <v-icon size="x-large" color="primary">mdi-logout-variant</v-icon>
              </template>
              <v-list-item-title>Uitloggen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'App',

  computed: {
    ...mapState('auth', ['loggedIn', 'navigation', 'user']),

    drawer: {
      get() {
        return this.$store.state.storage.drawer;
      },
      set(value) {
        this.$store.commit('storage/toggleDrawerState', value);
      }
    }
  },

  methods: {
    ...mapActions('auth', ['logout', 'switchRole']),

    toggleDrawerState() {
      this.drawer = !this.drawer;
    },

    showSupport() {
      if (window.FreshWidget) {
        window.FreshWidget.show();
      }
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: 'Axiforma', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(var(--v-theme-accent));
  font-weight: 300;
  font-size: 14px;
}

b,
strong {
  font-weight: bold;
}

// // Table
table {
  thead {
    th {
      background-color: #e0e6f2 !important;
      color: rgb(var(--v-theme-primary)) !important;
      border-color: rgb(var(--v-theme-info)) !important;
      font-size: 10px !important;
      font-weight: 700 !important;
      white-space: nowrap;
      line-height: 2;
    }
  }

  tbody {
    color: var(--v-theme-primary);

    td {
      border-color: rgb(var(--v-theme-info)) !important;
      border-bottom-width: 2px !important;
      padding: 5px 16px;
      font-size: 12px !important;
    }
  }
}

.tablehead-no-bg-color table thead th {
  background-color: transparent;
}

.table-p-small table {

  thead th,
  tbody td {
    padding: 5px;
  }
}

.table-first-fixed {
  table {
    tbody {
      tr {
        &:hover {
          background-color: #fff !important;
        }

        td:first-of-type {
          position: sticky;
          left: 0;
          top: 0;
          background: linear-gradient(-90deg,
              rgba(255, 255, 255, 0) 0px,
              rgba(255, 255, 255, 1) 25px,
              rgba(255, 255, 255, 1) 100%);
        }
      }
    }

    thead {
      tr {
        th:first-of-type {
          position: sticky;
          left: 0;
          top: 0;
          background: linear-gradient(-90deg,
              rgba(255, 255, 255, 0) 0px,
              rgba(255, 255, 255, 1) 25px,
              rgba(255, 255, 255, 1) 100%);
        }
      }
    }
  }
}

.table-action {
  table {
    tbody {
      tr {
        &:hover {
          background-color: #fff !important;
        }

        td:last-of-type {
          position: sticky;
          right: 0;
          top: 0;
          background: linear-gradient(90deg,
              rgba(255, 255, 255, 0) 0px,
              rgba(255, 255, 255, 1) 25px,
              rgba(255, 255, 255, 1) 100%);
          padding: 5px 16px;
          min-width: 9rem;
        }
      }
    }
  }

  &.row-is-clickable {
    table tbody tr {
      &:not(.v-data-table__empty-wrapper) {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.table-action.table-expand table tbody tr.v-data-table__expanded.v-data-table__expanded__row {
  td:last-of-type {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0px, rgba(2, 29, 113, 1) 25px, rgba(2, 29, 113, 1) 100%);

    .v-icon.v-icon::after {
      opacity: 1;
      background-color: #fff;
      z-index: -1;
    }
  }

  .table-action table tbody tr td:last-of-type {
    background: linear-gradient(90deg,
        rgba(255, 255, 255, 0) 0px,
        rgba(255, 255, 255, 1) 25px,
        rgba(255, 255, 255, 1) 100%);
  }
}

.table-expand table tbody tr.v-data-table__expanded.v-data-table__expanded__row {
  td:last-of-type {
    .v-icon.v-icon::after {
      opacity: 1;
      background-color: #fff;
      z-index: -1;
    }
  }
}

.v-data-table tbody tr {
  &.v-data-table__expanded__content {
    box-shadow: none;
  }

  &.v-data-table__expanded.v-data-table__expanded__row {
    background-color: var(--v-primary-base) !important;
    color: #fff !important;
  }
}

// Breadcrumbs
.v-breadcrumbs li:nth-child(2n) {
  padding: 0 5px;
}

// Pagination
.v-pagination__item,
.v-pagination__navigation {
  -webkit-box-shadow: none;
  box-shadow: none;
}

// Form input
.v-label,
.v-input {
  font-size: 12px !important;
  font-weight: 300;
}

.v-list-item-title,
.v-list-item-subtitle {
  white-space: inherit;
  font-size: 16px !important;
  font-weight: 300 !important;
}

.switch-roles .v-list-group__items .v-list-item {
  padding-left: 16px !important;
}

// .suffix-border-left-number .v-text-field__suffix {
//   border-left: 1px solid rgba(0, 0, 0, 0.38);
//   height: 100%;
//   width: 50px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 0;
//   margin-right: -12px;
// }

// //block dashboard
// .block-head p {
//   font-size: 14px;
// }

//dividers
.accent-hr {
  background-color: rgb(var(--v-theme-accent));
  ;
  height: 1.5px;
  border: none;
}

.primary-hr {
  background-color: rgb(var(--v-theme-primary));
  ;
  height: 1px;
  border: none;
}

/* Change Autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: none;
  box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

/* Action row styling */
.add-row {
  background: #fff;
  border-radius: 2.3rem;
  box-shadow: 0 3px 1px -2px #e8eff9;
  font-size: 1rem;
  color: #021e73;
  font-weight: 700;
}

// .widget-average {
//   font-size: 1rem;
//   color: #021e73;
//   font-weight: 700;

//   span {
//     font-size: .8rem;
//     font-weight: 500;
//     display: inline-flex;
//   }
// }

// // fix scrollbar issue in Firefox
// .v-data-table {
//   position: relative;
// }

.input-sm {
  min-width: 10rem;
}

//Progress styling
.progress-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  line-height: 1;
}

// /* Total row styling */
.v-application .custom_progress {
  border-radius: 3rem !important;
}

 .table-selection-total {
   background: #e1e7f2;
   border-radius: 1.5rem;
 }

 .table-action .table-selection-total:hover {
   background: #e1e7f2 !important;
 }

.v-application .table-selection-total .title {
  font-size: 1rem !important;
  color: #021e73;
}

.table-selection-total .text {
  color: #021e73;
}
.table-selection-total .text {
  color: #021e73;
}

.v-application .table-total .title {
  font-size: 1rem !important;
  color: #fff671;
}
.v-application .table-total .title {
  font-size: 1rem !important;
  color: #fff671;
}

.table-total .text {
  color: #fff;
}
.table-total .text {
  color: #fff;
}

.table-total {
  position: relative;
  z-index: 1;
  background: rgb(var(--v-theme-primary)) !important;
}

.table-action .table-total:hover {
  background: rgb(var(--v-theme-primary)) !important;
}

.orange {
  background-color: orange;
}

.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background-color: rgb(var(--v-theme-accent));
  box-shadow: none;
}

.v-data-table {
  .table-selection-total th {
    position: sticky;
    bottom: 3rem;
    z-index: 7;
    background-color: #e1e7f2;
  }

  .table-total th {
    position: sticky;
    bottom: 0;
    z-index: 7;
    background: rgb(var(--v-theme-primary)) !important;
  }
}

.table-total-head table thead th {
  background: rgb(var(--v-theme-primary)) !important;
  color: #fff !important;
}

.v-application .table-total-head table thead th.title {
  font-size: 1rem !important;
  color: #fff671 !important;
}

.progress-circular {
  margin-top: calc(50vh - 15rem); //calculated from nav
}

// .input-radio-row-label legend.v-label {
//   width: 100%;
//   padding-right: 0;
//   margin-bottom: 10px;
// }

.tooltip {
  display: none;
  padding: 0.25rem 1.25rem;
  background-color: #fff;
  border-radius: 6px;
  border: solid 1px #e0e5ef;
  box-shadow: 0 5px 10px rgb(171 171 171 / 75%);
  position: absolute;
  top: 0;
  left: calc(100% - 12px);
  transform: translateY(-25%);
  z-index: 99;
  padding: 0.5rem;
  white-space: nowrap;
  transition: all 0.3s ease;

  table {
    border-collapse: collapse;
    border-spacing: 0;

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;

      tr {
        line-height: 1.4;
        border-bottom: solid 1px #f0f2f7;

        th {
          font-weight: normal;
          text-align: left;
          width: 100px;
          padding: 0 !important;
        }

        td {
          font-weight: bold;
          text-align: right;
          border: 0;
          background: unset !important;
          min-width: unset !important;
        }
      }
    }
  }
}

.tooltip-container {
  position: relative;

  &:hover {
    .tooltip {
      display: inline-block;
    }
  }
}

// .pointer {
//   cursor: pointer;
// }

// .too-few-purchases-fallback {
//   background-color: #fff;
//   padding: 1rem;
// }

// //Contracts previous approved styling
// .v-application .prev-approved--text {
//   color: var(--v-secondary-base);
// }

// .widget-average h4 {
//   text-transform: lowercase;
//   font-weight: 400;
// }

.v-main {
  background-color: rgb(var(--v-theme-accent)) !important;
}

@media print {
  .v-navigation-drawer {
    width: 0 !important;
    transform: translateX(-100%) !important;
    display: none !important;
  }

  .v-main {
    padding: 90px 0px 0px 0 !important;
  }

  .not-visible-on-print {
    display: none !important;
  }
}
</style>