<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row class="d-flex" justify="center" align="center" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="49"></breadcrumbs-with-help>

          <v-col cols="12">
            <v-card title="Productie gegevens">
              <v-divider></v-divider>
              <v-card-text>
                <v-col cols="12">
                  <v-form @submit.prevent v-model="formValid" class="d-flex flex-column" ref="form">
                    <!-- Producer -->
                    <v-row v-if="this.user.organisationTypeId == 1">
                      <v-col cols="12" class="py-0">
                        <v-autocomplete label="producent" v-model="editProductionForm.producerId"
                          :items="producerOptions" :rules="formValidationRules.required" required @change="getSubventions();
                          editProductionForm.subventions = [];"></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Title -->
                      <v-col cols="12" class="py-0">
                        <v-text-field class="text-primary" label="titel" v-model="editProductionForm.title"
                          :rules="formValidationRules.required" required></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- New/Reprise -->
                      <v-col cols="12" class="py-0">
                        <v-autocomplete label="nieuw / reprise" v-model="editProductionForm.isReprise"
                          :items="productiontypes" :rules="formValidationRules.required" required
                          @change="getRepriseProductions(editProductionForm.isReprise)"></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row v-if="editProductionForm.isReprise == 'reprise'">
                      <!-- oorspronkelijke productie -->
                      <v-col cols="12" class="py-0">
                        <v-autocomplete label="oorspronkelijke productie" v-model="editProductionForm.repriseNumber"
                          :items="repriseProductions">
                          <template v-slot:selection="data"> {{ data.item.text }} {{ data.item.value }} </template>

                          <template v-slot:item="data"> {{ data.item.text }} {{ data.item.value }} </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Eigenaar -->
                      <v-col cols="12" class="py-0">
                        <v-autocomplete v-on:change="editProductionForm.coProducer = []" label="eigenaar"
                          v-model="editProductionForm.productionType" :items="ownerOptions"
                          :rules="formValidationRules.required" required></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row v-if="editProductionForm.productionType == 'co_production'">
                      <!-- Coproducenten -->
                      <v-col cols="12" class="py-0">
                        <v-autocomplete multiple label="coproducenten" v-model="editProductionForm.coproduction"
                          :items="coProducerOptions" :rules="formValidationRules.required" required outlined
                          dense></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-autocomplete multiple label="begeleid door"
                          v-model="editProductionForm.accompanyingProducers"
                          :items="accompanyingProducersOptions"></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Musician count -->
                      <v-col cols="12" class="py-0">
                        <v-text-field class="text-primary" label="aantal muzikanten" type="number"
                          v-model="editProductionForm.musicianCount" :rules="formValidationRules.numberRequired"
                          required></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Synopsis -->
                      <v-col cols="12" class="py-0">
                        <v-textarea class="text-primary" label="synopsis"
                          v-model="editProductionForm.synopsis"></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row>
                      <!-- Genre -->
                      <v-col cols="12" class="py-0">
                        <v-select label="genre" v-model="editProductionForm.genreId" :items="genreOptions"
                          :rules="formValidationRules.required" required></v-select>
                      </v-col>
                    </v-row>

                    <template v-if="this.subventionRights.approve">
                      <v-row>
                        <!-- Subsidie -->
                        <v-col cols="12" class="py-0">
                          <v-autocomplete v-model="editProductionForm.subventions" label="subsidies"
                            :items="subventionOptions" multiple></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row v-if="editProductionForm.subventions.length > 0">
                        <!-- data gebruik subsidie -->
                        <v-col cols="12" class="py-0">
                          <v-tooltip top max-width="500">
                            <template v-slot:activator="{ props: activatorProps }">
                              <v-btn color="info" width="24" height="24" fab x-small depressed light
                                v-bind="activatorProps">
                                <v-icon color="primary" x-small>mdi-help</v-icon>
                              </v-btn>
                            </template>
                            <span>Wil je gebruik maken van de koppeling met Fonds Podiumkunsten, geef dan toestemming om
                              de
                              data uit DIP door te zetten naar de verantwoordingsmodule van het Fonds. Wij zetten dan
                              beschikbare gegevens voor je over, zodat je niet dubbel hoeft in te voeren. Overdracht
                              vindt plaats per kwartaal op 1 februari, 1 mei, 1 augustus en 1 november.<br />LET OP: Het
                              definitief doorsturen van de informatie naar Fonds Podiumkunsten doe je vervolgens zelf
                              vanuit de verantwoordingsmodule Mijn Fonds.</span>
                          </v-tooltip>
                          <v-checkbox label="toestemming data gebruiken voor subsidieverantwoording"
                            v-model="editProductionForm.hasTransferPermission"
                            class="checkbox text-primary ma-0"></v-checkbox>
                        </v-col>
                      </v-row>
                    </template>
                    <v-row>
                      <!-- Start datum -->
                      <v-col cols="12" class="py-0">
                        <date-picker v-if="datePickerDataLoaded" v-model="editProductionForm.startDateTime"
                          :passedDate="editProductionForm.startDateTime" rules="dateRequired"></date-picker>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
              </v-card-actions>
            </v-card>

            <v-alert v-if="formError != ''" text color="error">{{ formError }}</v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '../../../components/BreadcrumbsWithHelp.vue';
import DatePicker from '../../../components/DatePicker';

export default {
  name: 'LibraryProductionsEdit',
  props: ['productionNumber'],
  components: {
    BreadcrumbsWithHelp,
    DatePicker,
  },
  data() {
    return {
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      datePickerDataLoaded: false,
      editProductionForm: {
        producerId: '',
        title: '',
        owner: '',
        coproduction: [],
        accompanyingProducers: [],
        subventions: [],
        hasTransferPermission: true,
        isReprise: '',
        productionType: '',
        musicianCount: '0',
        synopsis: '',
        genreId: '',
        startDateTime: '',
        repriseTitle: '',
        repriseNumber: '',
      },
      genreOptions: [],
      producerOptions: [],
      coProducerOptions: [],
      accompanyingProducersOptions: [],
      subventionOptions: [],
      repriseProductions: [],
      ownerOptions: [
        { title: 'eigen productie', value: 'own_production' },
        { title: 'coproductie', value: 'co_production' },
      ],
      productiontypes: [
        { title: 'nieuwe productie', value: 'normal' },
        { title: 'reprise / herneming', value: 'reprise' },
      ],
      formValidationRules: {},
      showCoProducers: false,
      rights: {
        edit: false,
        approve: false,
      },
      subventionRights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/beheer/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'bewerken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryProductionsEdit');
    this.subventionRights = this.getComponentRights('Subventions');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getProduction();
    this.getGenres();
    this.getProducers();
    this.getCoProducers();
    this.getAccompanyingProducers();
    this.getSubventions();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getProduction: function () {
      const vm = this;

      ApiService.get('/Productions/Get', {
        productionNumber: this.productionNumber,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            this.datePickerDataLoaded = true;
            const production = response.data.result;

            this.breadcrumbs[2].text = production.title;
            this.breadcrumbs[2].href = '/beheer/producties/' + this.productionNumber;

            this.editProductionForm.producerId = production.producer.id;
            this.editProductionForm.id = production.id;
            this.editProductionForm.number = production.number;
            this.editProductionForm.title = production.title;
            this.editProductionForm.productionType = production.productionType;
            this.editProductionForm.isReprise = production.isReprise;
            this.editProductionForm.musicianCount = production.musicianCount.toString();
            this.editProductionForm.synopsis = production.synopsis;
            this.editProductionForm.startDateTime = functions.getDateView(production.startDateTime);
            this.editProductionForm.genreId = production.genre.id;
            this.editProductionForm.hasTransferPermission = production.hasTransferPermission;

            if (!production.isOwn) {
              router.back();
            }

            if (this.editProductionForm.isReprise == 'reprise') {
              this.getRepriseProductions();
              this.editProductionForm.repriseTitle = production.reprise.title;
              this.editProductionForm.repriseNumber = production.reprise.number;
            }

            var coProducers = [];
            for (var i = 0; i < production.coProducers.length; i++) {
              coProducers.push(production.coProducers[i].id);
            }
            this.editProductionForm.coproduction = coProducers;

            var accompanyingProducers = [];
            for (var j = 0; j < production.accompanyingProducers.length; j++) {
              accompanyingProducers.push(production.accompanyingProducers[j].id);
            }
            this.editProductionForm.accompanyingProducers = accompanyingProducers;

            var subventions = [];
            for (var k = 0; k < production.subventions.length; k++) {
              subventions.push(production.subventions[k].id);
            }
            this.editProductionForm.subventions = subventions;

            this.getSubventions();

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getGenres: function () {
      ApiService.get('/Genres/GetAll').then((response) => {
        const genres = response.data.result;
        for (let i = 0; i < genres.length; i++) {
          this.genreOptions.push({ value: genres[i].id, title: genres[i].name });
        }
      });
    },
    getProducers: function () {
      ApiService.get('/Productions/GetLinkedProducers').then((response) => {
        const producers = response.data.result;
        for (let i = 0; i < producers.length; i++) {
          this.producerOptions.push({
            value: producers[i].id,
            title: producers[i].name,
          });
        }
      });
    },
    getCoProducers: function () {
      ApiService.get('/Productions/GetProducers').then((response) => {
        const producers = response.data.result;
        for (let i = 0; i < producers.length; i++) {
          this.coProducerOptions.push({
            value: producers[i].id,
            title: producers[i].name,
          });
        }
      });
    },
    getAccompanyingProducers: function () {
      ApiService.get('/Producers/GetAllDropDown', {
        IsAccompanying: true,
      }).then((response) => {
        const accompanyingProducers = response.data.result;
        for (let i = 0; i < accompanyingProducers.length; i++) {
          this.accompanyingProducersOptions.push({
            value: accompanyingProducers[i].id,
            title: accompanyingProducers[i].name,
          });
        }
      });
    },
    getRepriseProductions: function () {
      ApiService.get('/Productions/GetPerProducer', {
        ProducerId: this.user.organisationTypeId === 1 ? this.editProductionForm.producerId : this.user.organisationId,
        SeasonId: 0,
      }).then((response) => {
        let result = response.data.result;
        let sortedRepriseProductions = [];

        sortedRepriseProductions = result.map((element) => ({
          value: element.number,
          title: element.title,
        }));

        sortedRepriseProductions.sort(function (a, b) {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        this.repriseProductions = sortedRepriseProductions;
      });
    },
    getSubventions: function () {
      this.subventionOptions = [];
      // this.editProductionForm.subventions = []
      ApiService.get('/Productions/GetProducerSubventions', {
        ProducerId: this.user.organisationTypeId === 1 ? this.editProductionForm.producerId : this.user.organisationId,
      }).then((response) => {
        const subventions = response.data.result;
        const actualSubventions = [];
        const oldSubventions = [];

        for (let i = 0; i < subventions.length; i++) {
          if (subventions[i].isObsolete) {
            oldSubventions.push(subventions[i]);
          } else {
            actualSubventions.push(subventions[i]);
          }
        }

        if (actualSubventions.length > 0) {
          this.subventionOptions.push({ header: 'actueel' });
          for (let j = 0; j < actualSubventions.length; j++) {
            this.subventionOptions.push({
              value: actualSubventions[j].id,
              title:
                functions.getSubventionTypeView(actualSubventions[j].type) +
                ' ' +
                actualSubventions[j].organisation +
                ' (' +
                functions.getDateView(actualSubventions[j].startDate) +
                ' t/m ' +
                functions.getDateView(actualSubventions[j].endDate) +
                ')',
            });
          }
        }

        if (oldSubventions.length > 0) {
          this.subventionOptions.push({ header: 'ouder' });
          for (let k = 0; k < oldSubventions.length; k++) {
            this.subventionOptions.push({
              value: oldSubventions[k].id,
              title:
                functions.getSubventionTypeView(oldSubventions[k].type) +
                ' ' +
                oldSubventions[k].organisation +
                ' (' +
                functions.getDateView(oldSubventions[k].startDate) +
                ' t/m ' +
                functions.getDateView(oldSubventions[k].endDate) +
                ')',
            });
          }
        }
      });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.editProductionForm;

        ApiService.put('/Productions/Update', {
          number: this.productionNumber,
          producerId: form.producerId,
          title: form.title,
          productionType: form.productionType,
          coProducerIds: form.coproduction,
          accompanyingProducerIds: form.accompanyingProducers,
          subventionIds: form.subventions,
          hasTransferPermission: form.hasTransferPermission,
          isReprise: form.isReprise,
          musicianCount: parseInt(form.musicianCount),
          synopsis: form.synopsis,
          genreId: form.genreId,
          startDateTime: functions.getDateDatabase(form.startDateTime),
          repriseNumber: form.isReprise == 'reprise' ? form.repriseNumber : '',
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
    ownerSwitch: function () {
      if (this.editProductionForm.owner == 'co-production') {
        this.showCoProducers = true;
      } else {
        this.showCoProducers = false;
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
  watch: {
    editProductionForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
