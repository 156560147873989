<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex fill-height"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="text-primary font-weight-medium mb-0">kaartverkoop over tijd</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          align="center"
          justify="center"
          class="py-2 flex-column flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-row class="pb-6">
              <v-col cols="12">
                <Line
                  v-if="chart.loaded"
                  :chart-data="chart.data"
                  :chart-options="chart.options"
                ></Line>
              </v-col>
              <v-col
                v-for="option in dateRangeOptions"
                cols="3"
                :key="option.text"
              >
                <v-btn
                  v-if="selectedDateRange == option.range"
                  @click="changeDateRange(option)"
                  class="font-weight-bold text-lowercase"
                  color="primary"
                  depressed
                  block
                  >{{ option.text }}</v-btn
                >
                <v-btn
                  v-else
                  @click="changeDateRange(option)"
                  class="font-weight-bold text-lowercase"
                  color="primary"
                  variant="outlined"
                  block
                  depressed
                  >{{ option.text }}</v-btn
                >
              </v-col>
            </v-row>
          </template>

          <template v-else>
            <p class="text-primary font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';

import Help from '../../Help.vue';
import functions from '../../../services/functions.service';
import { Line } from 'vue-chartjs';
import { DateTime } from 'luxon';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop', 'widgetData'],
  components: {
    Help,
    Line,
  },
  data() {
    return {
      total: null,
      chart: {
        loaded: false,
        data: {},
        options: {
          scales: {
            x: {
              type: 'time',
              time: {
                tooltipFormat: 'dd MMM yyyy',
              },
              min: null,
            },
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
        },
      },
      maxDate: null,
      dateRangeOptions: [
        {
          text: '7d',
          range: { days: 7 },
        },
        {
          text: '1m',
          range: { month: 1 },
        },
        {
          text: '3m',
          range: { month: 3 },
        },
        {
          text: 'alle',
          range: null,
        },
      ],
      selectedDateRange: null,
    };
  },
  methods: {
    getData() {
      this.chart.loaded = false;

      const data = {
        ticketSalesOverTime: this.widgetData,
      };

      const resPerDay = data.ticketSalesOverTime.values;
      const resPerDayCompared = data.ticketSalesOverTime.comparison != null ? data.ticketSalesOverTime.comparison : [];

      this.maxDate = DateTime.fromISO(resPerDay[resPerDay.length - 1].day);
      const types = [...new Set(resPerDay.map((r) => r.salesType))];
      const typesCompared = [...new Set(resPerDayCompared.map((r) => r.salesType))];

      this.chart.data = {
        datasets: [
          ...types.map((type) => ({
            label: type,
            borderColor: functions.getTypeColor(type.toLowerCase()),
            data: resPerDay.filter((t) => t.salesType === type).map((t) => ({ x: t.day, y: t.tickets })),
          })),
          ...typesCompared.map((type) => ({
            label: type,
            borderColor: functions.getTypeColor(type.toLowerCase()),
            backgroundColor: ['#0abfb8'],
            data: resPerDayCompared.filter((t) => t.salesType === type).map((t) => ({ x: t.day, y: t.tickets })),
          })),
        ],
      };

      this.chart.loaded = true;
    },
    changeDateRange: function (option) {
      this.selectedDateRange = option.range;
      if (option.range) {
        this.chart.options.scales.x.min = this.maxDate.minus(option.range).toISODate();
      } else {
        this.chart.options.scales.x.min = null;
      }
      this.chart.options.scales.x.time.unit = option.range?.days ? 'day' : false;
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    widgetData() {
      this.getData();
    },
  },
};
</script>