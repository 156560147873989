<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="10"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- Theater Details -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <h1 class="text-primary mb-5">{{ this.theater.name }}</h1>
                  <h4 class="text-primary mb-2 font-weight-regular">gegevens theater</h4>

                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>klant DIP:</small><br />
                          <strong><span v-if="theater.isMember">Ja</span><span v-else>Nee</span></strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>startdatum:</small><br /><strong>{{ theater.isMemberSince }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>branchevereniging:</small><br /><strong>{{ theater.branchAssociation }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>ticketing systeem:</small><br /><strong>{{ theater.ticketSystem }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>kaartstanden bijwerken:</small><br /><strong>{{
                            theater.ticketLinkTypeString
                          }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <template v-if="theater.ticketLinkType == 3">
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="py-0"
                        >
                          <p class="text-primary mb-0">
                            <small>ticketmatic clientname:</small><br /><strong>{{ theater.ticketClientName }}</strong>
                          </p>
                        </v-col>
                      </v-row>
                      <hr class="accent-hr my-2" />
                    </template>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>voorwaarden DIP geaccepteerd:</small>
                          <br /><strong><span v-if="theater.agreedToTerms">Ja</span><span v-else>Nee</span></strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>toon publieksmonitor:</small> <br /><strong
                            ><span v-if="theater.hasPublicMonitorAccess">Ja</span><span v-else>Nee</span></strong
                          >
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>toon benchmark:</small> <br /><strong
                            ><span v-if="theater.hasBenchmarkAccess">Ja</span><span v-else>Nee</span></strong
                          >
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>

                  <h4 class="text-primary mb-2 font-weight-regular">adres</h4>
                  <v-card
                    class="pa-6 mb-1"
                    flat
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>straat en huisnummer:</small> <br />
                          <strong>{{ theater.street + ' ' + theater.number }}</strong>
                        </p>
                        <hr class="accent-hr my-2" />
                        <p class="text-primary mb-0">
                          <small>postcode:</small> <br />
                          <strong>{{ theater.zipCode }}</strong>
                        </p>
                        <hr class="accent-hr my-2" />
                        <p class="text-primary mb-0">
                          <small>plaats:</small> <br />
                          <strong>{{ theater.city }}</strong>
                        </p>
                        <hr class="accent-hr my-2" />
                        <p class="text-primary mb-0">
                          <small>provincie:</small> <br />
                          <strong>{{ theater.province }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                  <div>
                    <p class="grey--text text--lighten-1 mt-0">
                      <small
                        ><i>{{ this.theater.lastEditedBy }}</i></small
                      >
                    </p>
                    <!-- Bewerken -->
                    <v-btn
                      rounded
                      large
                      elevation="0"
                      outlined
                      color="primary"
                      class="text-lowercase mb-5 mr-5 align-self-start"
                      :to="{ name: 'AdminTheatersEdit' }"
                      >Gegevens Bewerken
                      <v-icon
                        small
                        right
                        >mdi-square-edit-outline</v-icon
                      ></v-btn
                    >

                    <!-- Verwijderen -->
                    <v-btn
                      rounded
                      large
                      elevation="0"
                      @click="deleteItemDialog = true"
                      color="error"
                      class="text-lowercase mb-5 align-self-start"
                      >Verwijderen
                      <v-icon
                        small
                        right
                        >mdi-delete</v-icon
                      ></v-btn
                    >
                  </div>
                </v-col>
              </v-row>

              <!-- Active User overview -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                    <h4 class="text-primary font-weight-regular">gebruikers</h4>
                    <v-btn
                      rounded
                      small
                      elevation="0"
                      color="primary"
                      class="text-lowercase"
                      :to="{ name: 'AdminTheatersLinkAdd' }"
                      >gebruiker toevoegen</v-btn
                    >
                  </v-row>

                  <!-- Table -->
                  <v-data-table
                    :headers="tableTheatersUsersHeaders"
                    :items="tableTheatersUsersFiltered"
                    :mobile-breakpoint="0"
                    :loading="tableTheatersUsersLoading"
                    :items-per-page="-1"
                    hide-default-footer
                    class="table-action"
                  >
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="info"
                        class="ml-1"
                        :to="{
                          name: 'AdminTheatersLinkEdit',
                          params: { linkId: item.linkId, theaterId: theaterId },
                        }"
                      >
                        <v-icon color="primary">mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="error"
                        class="ml-1"
                        @click="(deleteItemLink = item), (deleteItemLinkDialog = true);"
                      >
                        <v-icon color="#fff">mdi-delete</v-icon>
                      </v-btn>
                    </template>


                  </v-data-table>
                </v-col>
              </v-row>

              <!-- Theater Areas overview -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                    <h4 class="text-primary font-weight-regular">zalen</h4>
                    <v-btn
                      rounded
                      small
                      elevation="0"
                      color="primary"
                      class="text-lowercase"
                      :to="{ name: 'AdminTheatersAreaAdd' }"
                      >zaal toevoegen</v-btn
                    >
                  </v-row>

                  <!-- Table -->
                  <v-data-table
                    :headers="tableTheatersAreasHeaders"
                    :items="tableTheatersAreasFiltered"
                    :mobile-breakpoint="0"
                    :loading="tableTheatersAreasLoading"
                    :items-per-page="-1"
                    hide-default-footer
                    class="table-action"
                  >
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="info"
                        class="ml-1"
                        :to="{
                          name: 'AdminTheatersAreaEdit',
                          params: { areaId: item.id, theaterId: theaterId },
                        }"
                      >
                        <v-icon color="primary">mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="error"
                        class="ml-1"
                        @click="(deleteItemArea = item), (deleteItemAreaDialog = true);"
                      >
                        <v-icon color="#fff">mdi-delete</v-icon>
                      </v-btn>
                    </template>


                  </v-data-table>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.theater.name }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.theater.name }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemTheater()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog link starts here -->
    <v-dialog
      v-model="deleteItemLinkDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Gebruikersrol verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je deze gebruikersrol wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemLinkDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemLinkUser()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog area starts here -->
    <v-dialog
      v-model="deleteItemAreaDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Zaal verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je deze zaal wil verwijderen? Het is daarna niet meer terug te vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemAreaDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemTheaterArea()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';

import Help from '../../../components/Help.vue';

export default {
  name: 'AdminTheatersDetail',
  props: ['theaterId'],
  components: {
    Help,
  },
  data() {
    return {
      theater: {
        name: '',
        isMember: false,
        isMemberSince: '',
        branchAssociation: '',
        ticketSystem: '',
        ticketLinkType: 0,
        ticketLinkTypeString: '',
        ticketClientName: '',
        agreedToTerms: false,
        hasPublicMonitorAccess: false,
        hasBenchmarkAccess: false,
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: '',
        lastEditedBy: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'theaters',
          disabled: false,
          href: '/admin/theaters',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemDialog: false,
      deleteItemLinkDialog: false,
      deleteItemAreaDialog: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',

      tableTheatersUsersFiltered: [],
      tableTheatersUsersLoading: true,
      tableTheatersUsersHeaders: [
        { title: 'naam', key: 'userFullName' },
        { title: 'rol', key: 'roleName' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],

      tableTheatersAreasFiltered: [],
      tableTheatersAreasLoading: true,
      tableTheatersAreasHeaders: [
        { title: 'naam', key: 'name' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
    };
  },
  mounted() {
    this.getTheater();
    this.getTheaterUsers();
    this.getTheaterAreas();
  },
  methods: {
    getTheater: function () {
      ApiService.get('/Theaters/Get', {
        theaterId: this.theaterId,
      })
        .then((response) => {
          if (response.data.success) {
            const theater = response.data.result;

            this.breadcrumbs[2].text = theater.name;
            this.breadcrumbs[2].href = '/admin/theaters/' + theater.id;
            this.theater.name = theater.name;
            this.theater.isMember = theater.isMember;
            this.theater.isMemberSince = functions.getDateView(theater.isMemberSince);
            this.theater.branchAssociation = theater.branchAssociation != null ? theater.branchAssociation.name : '';
            this.theater.ticketSystem = theater.ticketSystem != null ? theater.ticketSystem.name : '';
            this.theater.ticketLinkType = theater.ticketLinkType;
            this.theater.ticketLinkTypeString =
              theater.ticketLinkType == 1
                ? 'handmatig'
                : theater.ticketLinkType == 2
                ? 'api'
                : theater.ticketLinkType == 3
                ? 'ticketmatic'
                : '-';
            this.theater.ticketClientName = theater.ticketClientName;
            this.theater.agreedToTerms = theater.agreedToTerms;
            this.theater.hasPublicMonitorAccess = theater.hasPublicMonitorAccess;
            this.theater.hasBenchmarkAccess = theater.hasBenchmarkAccess;
            this.theater.street = theater.street;
            this.theater.number = theater.number;
            this.theater.zipCode = theater.zipCode;
            this.theater.city = theater.city;
            this.theater.province = theater.province.name;
            this.theater.lastEditedBy = theater.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getTheaterUsers: function () {
      ApiService.get('/Theaters/GetUsers', {
        theaterId: this.theaterId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableTheatersUsersFiltered = response.data.result;
            this.tableTheatersUsersLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getTheaterAreas: function () {
      ApiService.get('/TheaterAreas/GetAll', {
        TheaterId: this.theaterId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.areas = response.data.result;

          this.tableTheatersAreasFiltered = this.areas;
          this.tableTheatersAreasLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    deleteItemTheater: function () {
      ApiService.delete('/Theaters/Delete', {
        theaterId: this.theaterId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.theater.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/admin/theaters' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    deleteItemLinkUser: function () {
      ApiService.delete('/UserRoles/Delete', {
        linkId: this.deleteItemLink.linkId,
        organisationTypeId: this.deleteItemLink.organisationTypeId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableTheatersUsersFiltered.map((item) => item.linkId).indexOf(this.deleteItemLink.linkId);
            this.tableTheatersUsersFiltered.splice(i, 1);

            this.snackbarText = 'Gebruiker is verwijderd';
            this.deleteItemLinkDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    deleteItemTheaterArea: function () {
      ApiService.delete('/TheaterAreas/Delete', {
        theaterAreaId: this.deleteItemArea.id,
        organisationTypeId: this.deleteItemArea.organisationTypeId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableTheatersAreasFiltered.map((item) => item.id).indexOf(this.deleteItemArea.id);
            this.tableTheatersAreasFiltered.splice(i, 1);

            this.snackbarText = 'Zaal is verwijderd';
            this.deleteItemAreaDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
  },
  computed: {},
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
