<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row
          align="center"
          class="px-3 mb-4"
        >
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Een nieuwe notificatie toevoegen?</span>
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
              :to="{ name: 'AdminNotificationsAdd' }"
            >
              Aanmaken nieuwe notificatie
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="36"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field
                v-model="tableNotificationsFilter.title"
                label="zoek op titel"
                hide-details
                variant="outlined"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-text-field
                v-model="tableNotificationsFilter.showDate"
                label="zoek op datum"
                hide-details
                variant="outlined"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-text-field
                v-model="tableNotificationsFilter.organisationTypeName"
                label="zoek op groep"
                hide-details
                variant="outlined"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-text-field
                v-model="tableNotificationsFilter.lastEditedByName"
                label="zoek op schrijver"
                hide-details
                variant="outlined"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableNotificationsFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-bind="activatorProps"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="text-primary">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="tableNotificationsFilter.title"
                      label="zoek op titel"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                    <v-text-field
                      v-model="tableNotificationsFilter.showDate"
                      label="zoek op datum"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                    <v-text-field
                      v-model="tableNotificationsFilter.organisationTypeName"
                      label="zoek op groep"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-text-field>
                    <v-text-field
                      v-model="tableNotificationsFilter.lastEditedByName"
                      label="zoek op schrijver"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableNotificationsFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      variant="outlined"
                      dark
                      @click="clearFilterNotifications()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableNotificationsHeaders"
          :items="tableNotificationsFiltered"
          :mobile-breakpoint="0"
          :loading="tableNotificationsLoading"
          :page="tableNotificationsPaging.page"
          :items-per-page="tableNotificationsPaging.itemsPerPage"
          hide-default-footer
          @update:current-items="tableNotificationsPaging.pageCount = Math.ceil(tableNotificationsFiltered.length / tableNotificationsPaging.itemsPerPage)"
          @update:items-per-page="tableNotificationsPaging.pageCount = Math.ceil(tableNotificationsFiltered.length / tableNotificationsPaging.itemsPerPage)"
          @click:row="goToDetail"
          class="table-action row-is-clickable"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              rounded="circle" 
              elevation="0" 
              size="small" 
              icon="mdi-eye"
              color="warning"
              class="ml-1"
              :to="{ name: 'AdminNotificationsDetail', params: { notificationId: item.id } }"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
            <v-btn
              rounded="circle" 
              elevation="0" 
              size="small" 
              icon="mdi-square-edit-outline"
              color="info"
              class="ml-1"
              :to="{ name: 'AdminNotificationsEdit', params: { notificationId: item.id } }"
            >
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn
              rounded="circle" 
              elevation="0" 
              size="small" 
              icon="mdi-delete"
              color="error"
              class="ml-1"
              @click="(deleteItem = item), (deleteItemDialog = true);"
            >
              <v-icon color="#fff">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption text-primary ma-0 pa-0">
              totaal: {{ this.tableNotificationsFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableNotificationsFiltered.length > 25"
              @click="tableNotificationsPaging.itemsPerPage = 25"
              :color="tableNotificationsPaging.itemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableNotificationsFiltered.length > 25"
              @click="tableNotificationsPaging.itemsPerPage = 50"
              :color="tableNotificationsPaging.itemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableNotificationsFiltered.length > 50"
              @click="tableNotificationsPaging.itemsPerPage = 100"
              :color="tableNotificationsPaging.itemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableNotificationsPaging.pageCount > 1"
              v-model="tableNotificationsPaging.page"
              :length="tableNotificationsPaging.pageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>

          <!-- Table download -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="tableNotificationsFiltered"
                :columns="downloadExportColumns"
                :filename="'dip-notificaties-export'"
                :sheetname="'Notificaties'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.deleteItem.title }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.deleteItem.title }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(deleteItemDialog = false), (deleteItem = '');"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemNotification()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
      elevation="0"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';
import functions from '../../../services/functions.service';

import Help from '../../../components/Help.vue';

export default {
  name: 'AdminNotifications',
  components: {
    Help,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'notificaties',
          disabled: false,
          href: '/admin/notificaties',
          bold: true,
        },
      ],
      notifications: [],
      tableNotificationsFiltered: [],
      tableNotificationsLoading: true,
      tableNotificationsHeaders: [
        { title: 'titel', key: 'title' },
        { title: 'datum', key: 'showDate' },
        { title: 'groep', key: 'organisationTypeName' },
        { title: 'schrijver', key: 'lastEditedByName' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableNotificationsFilter: {
        title: '',
        showDate: '',
        organisationTypeName: '',
        lastEditedByName: '',
      },
      tableNotificationsPaging: {
        page: 1,
        pageCount: 25,
        itemsPerPage: 25,
      },
      tableNotificationsFilterDialog: false,
      downloadExportColumns: [
        { label: 'ID', field: 'id' },
        { label: 'titel', field: 'title' },
        { label: 'schrijver', field: 'lastEditedByName' },
        { label: 'type', field: 'organisationTypeName' },
        { label: 'tekst', field: 'text' },
        { label: 'verzenddatum', field: 'showDate' },
      ],
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
    };
  },
  mounted() {
    // Filter
    this.checkFilter({ viewName: this.$options.name, filterObject: this.tableNotificationsFilter });
    this.tableNotificationsFilter = this.getFilter(this.$options.name);

    this.getNotifications();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'AdminNotificationsDetail',
        params: { notificationId: row.id },
      });
    },
    getNotifications: function () {
      ApiService.get('/Notifications/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.notifications = response.data.result.map(notification => ({
            ...notification,
            showDate: functions.getDateTimeView(notification.showDate)
          }));

          this.tableNotificationsFiltered = this.notifications;

          // Paging
          this.checkFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableNotificationsPaging });
          this.tableNotificationsPaging = this.getFilter(this.$options.name + '-paging');

          this.filterNotifications();

          this.tableNotificationsLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterNotifications: function () {
      const vm = this;
      this.tableNotificationsFiltered = this.notifications.filter(function (notification) {
        return (
          cleanWords(notification.title).indexOf(cleanWords(vm.tableNotificationsFilter.title)) !== -1 &&
          notification.showDate.indexOf(vm.tableNotificationsFilter.showDate) !== -1 &&
          cleanWords(notification.organisationTypeName).indexOf(
            cleanWords(vm.tableNotificationsFilter.organisationTypeName)
          ) !== -1 &&
          cleanWords(notification.lastEditedByName).indexOf(
            cleanWords(vm.tableNotificationsFilter.lastEditedByName)
          ) !== -1
        );
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterNotifications: function () {
      this.tableNotificationsFilter.title = '';
      this.tableNotificationsFilter.showDate = '';
      (this.tableNotificationsFilter.organisationTypeName = ''),
        (this.tableNotificationsFilter.lastEditedByName = ''),
        (this.tableNotificationsFilterDialog = false);
    },
    deleteItemNotification: function () {
      ApiService.delete('/Notifications/Delete', {
        notificationId: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.notifications.map((item) => item.Id).indexOf(this.deleteItem.Id);
            this.notifications.splice(i, 1);
            this.filterNotifications();

            this.snackbarText = this.deleteItem.title + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('storage', ['getFilter']),
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({ viewName: this.$options.name, filterObject: this.tableNotificationsFilter });
    // Paging
    this.updateFilter({ viewName: this.$options.name + '-paging', filterObject: this.tableNotificationsPaging });
  },
  watch: {
    tableNotificationsFilter: {
      handler: 'filterNotifications',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
