const typeColors = {
  overig: '#fff453',
  normaal: '#021e73',
  vrijkaart: '#0abfb8',
  korting: '#d92853',
  reservering: '#888',
  beschikbaar: '#eee',
  blokkade: '#444',
};

const functions = {
  getEuroSignView(value, digits) {
    var formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: digits,
    });
    return formatter.format(value);
  },

  getEuroCurrencyView(value) {
    var newValue = '';
    var spiltNewValue = value.toString().split('.');

    if (spiltNewValue[1]) {
      if (spiltNewValue[1] && spiltNewValue[1].length < 2) {
        spiltNewValue[1] = spiltNewValue[1] + '0';
      }
      newValue = spiltNewValue[0] + ',' + spiltNewValue[1];
      newValue = newValue.replace(/[^0-9.,]/g, '').replace('.', ',');
    } else {
      newValue = value
        .toString()
        .replace(/[^0-9.,]/g, '')
        .replace('.', ',');
      newValue = newValue.toString() + ',00';
    }

    return newValue;
  },
  getEuroCurrencyDatabase(value) {
    var newValue = value
      ?.toString()
      .replace(/[^0-9.,]/g, '')
      .replace(',', '.');
    return newValue;
  },
  getDecimalDatabase(value) {
    var newValue = value
      .toString()
      .replace(/[^0-9.,]/g, '')
      .replace(',', '.');
    return newValue;
  },
  getPercentageView(value) {
    if (value != null && value != '') {
      var newValue = parseFloat(value).toFixed(2) + '%';
      return newValue;
    }
    return '';
  },
  getPercentageViewWithoutDecimals(value) {
    if (value != null && value != '') {
      var newValue = parseFloat(value).toFixed(0) + '%';
      return newValue;
    }
    return '';
  },
  jsDateToStringDate(date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var hours = date.getHours();
    var minutes = date.getMinutes();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    var newDate = year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':00';

    return newDate;
  },

  getDateView(date) {
    if (date == null || date == '') {
      return '';
    }
    var newDate = new Date(date);
    if (newDate == 'Invalid Date') {
      return '';
    }

    var dateArray = date.split('T');
    var dateString = dateArray[0].split('-').reverse().join('-');

    if (dateString == '01-01-1900') {
      return ''; // apparently we use this as a dummy date value
    }

    return dateString;
  },

  getTypeColor(type) {
    return typeColors[type];
  },

  getTimeView(date) {
    if (date == null || date == '') {
      return '';
    }
    var newDate = new Date(date);
    if (newDate == 'Invalid Date') {
      return '';
    }

    var dateArray = date.split('T');
    var dateTimeArray = dateArray[1].split(':');
    var timeString = dateTimeArray[0] + ':' + dateTimeArray[1];

    return timeString;
  },

  getDateTimeView(date) {
    if (date == null || date == '') {
      return '';
    }
    var newDate = new Date(date);
    if (newDate == 'Invalid Date') {
      return '';
    }

    var dateArray = date.split('T');
    var dateDate = dateArray[0].split('-').reverse().join('-');
    var dateTimeArray = dateArray[1].split(':');
    var dateString = dateDate + ' ' + dateTimeArray[0] + ':' + dateTimeArray[1];

    return dateString;
  },

  getDateDatabase(date) {
    if (date == null || date == '') {
      return '';
    }

    var dateString = date.split('-').reverse().join('-') + 'T00:00:00';

    return dateString;
  },
  getDateTimeDatabase(date) {
    if (date == null || date == '') {
      return '';
    }

    var dateArray = date.split(' ');
    dateArray[1] = dateArray[1] + ':00';
    var dateString = dateArray[0].split('-').reverse().join('-') + 'T' + dateArray[1];

    return dateString;
  },
  changeState(state) {
    if (state == 'interest') {
      return 'interesse';
    } else if (state == 'option') {
      return 'optie';
    } else if (state == 'agreed') {
      return 'geaccepteerd';
    } else if (state == 'success') {
      return 'succesoptie';
    } else {
      return 'annulering';
    }
  },
  changeType(type) {
    if (type == 'normal') {
      return 'normaal';
    } else if (type == 'tryout') {
      return 'tryout';
    } else if (type == 'premiere') {
      return 'premiere';
    } else if (type == 'montage') {
      return 'montage';
    } else {
      return 'derniere';
    }
  },
  getStatusNameNL(status) {
    return status == 'concept'
      ? 'concept'
      : status == 'proposed'
      ? 'voorstel'
      : status == 'revised'
      ? 'aangepast voorstel'
      : status == 'revised-in-pki'
      ? 'herzien'
      : status == 'rejected'
      ? 'afgekeurd'
      : status == 'approved'
      ? 'goedgekeurd'
      : '';
  },
  getSubventionTypeView(type) {
    return type == 'basic'
      ? 'basisinfrastructuur (OCW)'
      : type == 'multiyear'
      ? 'meerjarige activiteitensubsidie (FPK)'
      : type == 'project'
      ? 'projectsubsidie (FPK)'
      : '';
  },
  uniqueSorted(collection, by) {
    if (collection.length === 0) return collection;
    collection.sort((a,b) => by(a) > by(b) ? 1 : -1);
    const res = [collection[0]];
    for (let i = 1; i < collection.length; i++) {
      if (by(collection[i-1]) !== by(collection[i])) {
        res.push(collection[i]);
      }
    }
    return res;
  },
  getFormValidationRules() {
    return {
      required: [(v) => !!v || 'dit veld is verplicht'],
      number: [(v) => /^\d+$/i.test(v) || v == '' || 'voer een rond getal in'],
      numberRequired: [
        (v) => !!v || 'dit veld is verplicht',
        (v) => /^\d+$/i.test(v) || v == '' || 'voer een rond getal in',
      ],
      numberDecimal: [(v) => /^\d+([,.]\d{1,2})?$/i.test(v) || v == '' || 'voer een getal in'],
      numberDecimalRequired: [
        (v) => !!v || 'dit veld is verplicht',
        (v) => /^\d+([,.]\d{1,2})?$/i.test(v) || v == '' || 'voer een getal in',
      ],
      valuta: [(v) => /^\d+[,.]\d\d$/i.test(v) || v == '' || 'voer een geldig bedrag in'],
      valutaRequired: [
        (v) => !!v || 'dit veld is verplicht',
        (v) => /^(\d+|\d+[,.]\d\d)$/i.test(v) || v == '' || 'voer een geldig bedrag in',
      ],
      date: [
        (v) =>
          /^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d\d\d\d$/i.test(v) ||
          v == '' ||
          'geen geldige datum (DD-MM-JJJJ)',
      ],
      dateMaxToday: [
        (v) =>
          /^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d\d\d\d$/i.test(v) ||
          v == '' ||
          'geen geldige datum (DD-MM-JJJJ)',
        (v) =>
          !v ||
          new Date(v.split('-').reverse().join('-')).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) ||
          'datum mag niet in de toekomst liggen',
      ],
      dateRequired: [
        (v) => !!v || 'dit veld is verplicht',
        (v) => /^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d\d\d\d$/i.test(v) || 'geen geldige datum (DD-MM-JJJJ)',
      ],
      dateRequiredMaxToday: [
        (v) => !!v || 'dit veld is verplicht',
        (v) => /^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d\d\d\d$/i.test(v) || 'geen geldige datum (DD-MM-JJJJ)',
        (v) =>
          !v ||
          new Date(v.split('-').reverse().join('-')).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) ||
          'datum mag niet in de toekomst liggen',
      ],
      time: [
        (v) =>
          /^(([1-9]{1})|([0-1][0-9])|([1-2][0-3])):([0-5][0-9])$/i.test(v) || v == '' || 'geen geldige tijd (UU:MM)',
      ],
      timeRequired: [
        (v) => !!v || 'dit veld is verplicht',
        (v) => /^(([1-9]{1})|([0-1][0-9])|([1-2][0-3])):([0-5][0-9])$/i.test(v) || 'geen geldige tijd (UU:MM)',
      ],
      dateTime: [
        (v) =>
          /^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d\d\d\d (([1-9]{1})|([0-1][0-9])|([1-2][0-3])):([0-5][0-9])$/i.test(
            v
          ) ||
          v == '' ||
          'geen geldige datum en tijd (DD-MM-JJJJ UU:MM)',
      ],
      dateTimeRequired: [
        (v) => !!v || 'dit veld is verplicht',
        (v) =>
          /^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d\d\d\d (([1-9]{1})|([0-1][0-9])|([1-2][0-3])):([0-5][0-9])$/i.test(
            v
          ) || 'geen geldige datum en tijd (DD-MM-JJJJ UU:MM)',
      ],
      zipCode: [(v) => /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(v) || v == '' || 'geen geldige postcode'],
      zipCodeRequired: [
        (v) => !!v || 'dit veld is verplicht',
        (v) => /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(v) || 'geen geldige postcode',
      ],
      email: [(v) => /.+@.+\..+/.test(v) || 'geen geldig e-mailadres'],
      emailRequired: [(v) => !!v || 'dit veld is verplicht', (v) => /.+@.+\..+/.test(v) || 'geen geldig e-mailadres'],
    };
  },
};

export default functions;
