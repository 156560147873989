<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row
          align="center"
          class="px-3 mb-4"
        >
          <v-col class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">Een nieuw theater toevoegen?</span>
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
              :to="{ name: 'AdminTheatersAdd' }"
            >
              Aanmaken nieuw theater
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="8"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field
                v-model="tableTheatersFilter.name"
                label="op naam zoeken"
                hide-details
                variant="outlined"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-text-field
                v-model="tableTheatersFilter.city"
                label="zoek op plaats"
                hide-details
                variant="outlined"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-autocomplete
                v-model="tableTheatersFilter.customer"
                :items="[
                  { value: 'ja', title: 'ja' },
                  { value: 'nee', title: 'nee' },
                  { value: '', title: 'ja/nee' },
                ]"
                type="text"
                label="klant DIP"
                hide-details
                variant="outlined"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
              <v-autocomplete
                v-model="tableTheatersFilter.status"
                :items="[
                  { value: 1, title: 'ja' },
                  { value: 6, title: 'nee' },
                  { value: '', title: 'ja/nee' },
                ]"
                type="text"
                label="actief"
                hide-details
                variant="outlined"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableTheatersFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-bind="activatorProps"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="text-primary">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="tableTheatersFilter.name"
                      label="op naam zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                    <v-text-field
                      v-model="tableTheatersFilter.city"
                      label="zoek op plaats"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                    <v-autocomplete
                      v-model="tableTheatersFilter.customer"
                      :items="[
                        { value: 'ja', text: 'ja' },
                        { value: 'nee', text: 'nee' },
                        { value: '', text: 'ja/nee' },
                      ]"
                      item-text="text"
                      label="klant DIP"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-autocomplete>
                    <v-autocomplete
                      v-model="tableTheatersFilter.status"
                      :items="[
                        { value: 1, text: 'ja' },
                        { value: 6, text: 'nee' },
                        { value: '', text: 'ja/nee' },
                      ]"
                      item-text="text"
                      label="actief"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableTheatersFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterTheaters()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableTheatersHeaders"
          :items="tableTheatersFiltered"
          :mobile-breakpoint="0"
          :loading="tableTheatersLoading"
          :page="tableTheatersPaging.page"
          :items-per-page="tableTheatersPaging.itemsPerPage"
          hide-default-footer
          @update:current-items="tableTheatersPaging.pageCount = Math.ceil(tableTheatersFiltered.length / tableTheatersPaging.itemsPerPage)"
          @update:items-per-page="tableTheatersPaging.pageCount = Math.ceil(tableTheatersFiltered.length / tableTheatersPaging.itemsPerPage)"
          @click:row="goToDetail"
          class="table-action row-is-clickable"
          :height="tableTheatersFiltered.length > 10 ? '55vh' : ''"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              v-if="item.status == 1"
              fab
              x-small
              depressed
              color="warning"
              class="ml-1"
              :to="{
                name: 'AdminTheatersDetail',
                params: { theaterId: item.id },
              }"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
            <v-btn
              v-if="item.status == 1"
              fab
              x-small
              depressed
              color="info"
              class="ml-1"
              :to="{
                name: 'AdminTheatersEdit',
                params: { theaterId: item.id },
              }"
            >
              <v-icon color="primary">mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="item.status == 1"
              fab
              x-small
              depressed
              color="error"
              class="ml-1"
              @click="(deleteItem = item), (deleteItemDialog = true);"
            >
              <v-icon color="#fff">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
          v-if="!tableTheatersLoading"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption text-primary ma-0 pa-0">
              totaal: {{ this.tableTheatersFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableTheatersFiltered.length > 25"
              @click="tableTheatersPaging.itemsPerPage = 25"
              :color="tableTheatersPaging.itemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableTheatersFiltered.length > 25"
              @click="tableTheatersPaging.itemsPerPage = 50"
              :color="tableTheatersPaging.itemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableTheatersFiltered.length > 50"
              @click="tableTheatersPaging.itemsPerPage = 100"
              :color="tableTheatersPaging.itemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableTheatersPaging.pageCount > 1"
              v-model="tableTheatersPaging.page"
              :length="tableTheatersPaging.pageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>

          <!-- Table download -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="tableTheatersFiltered"
                :columns="downloadExportColumns"
                :filename="'dip-theaters-export'"
                :sheetname="'Theaters'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.deleteItem.name }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.deleteItem.name }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(deleteItemDialog = false), (deleteItem = '');"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemTheater()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
      elevation="0"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';

import Help from '../../../components/Help.vue';
import functions from '../../../services/functions.service';

export default {
  name: 'AdminTheaters',
  components: {
    Help,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'theaters',
          disabled: false,
          href: '/admin/theaters',
          bold: true,
        },
      ],
      theaters: [],
      tableTheatersFiltered: [],
      tableTheatersLoading: true,
      tableTheatersHeaders: [
        { title: 'id', key: 'id' },
        { title: 'naam', key: 'name' },
        { title: 'plaats', key: 'city' },
        { title: 'provincie', key: 'province.name' },
        { title: 'klant DIP', key: 'isMember' },
        { title: 'branchevereniging', key: 'brancheAssociationName' },
        { title: 'ticketing systeem', key: 'ticketSystemName' },
        { title: 'actief', key: 'statusString' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableTheatersFilter: {
        name: '',
        city: '',
        customer: '',
        status: 1,
      },
      tableTheatersPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableTheatersFilterDialog: false,
      downloadExportColumns: [
        { label: 'ID', field: 'id' },
        { label: 'PNL-ID', field: 'pnlId' },
        { label: 'naam', field: 'name' },
        { label: 'straat', field: 'street' },
        { label: 'huisnummer', field: 'number' },
        { label: 'postcode', field: 'zipCode' },
        { label: 'plaats', field: 'city' },
        { label: 'klant DIP', field: 'isMember' },
        {
          label: 'klant since',
          field: 'isMemberSince',
          dataFormat: functions.getDateView,
        },
        { label: 'actief', field: 'statusString' },
        { label: 'branchevereniging', field: 'brancheAssociationName' },
        { label: 'ticketing systeem', field: 'ticketSystemName' },
        { label: 'voorwaarden', field: 'agreedToTerms' },
        { label: 'provincie', field: 'provinceName' },
      ],
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
    };
  },
  mounted() {
    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableTheatersFilter,
    });
    this.tableTheatersFilter = this.getFilter(this.$options.name);

    this.getTheaters();
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),

    goToDetail(row) {
      this.$router.push({
        name: 'AdminTheatersDetail',
        params: { theaterId: row.id },
      });
    },
    getTheaters: function () {
      ApiService.get('/Theaters/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          this.theaters = response.data.result.map(theater => ({
            ...theater,
            isMember: theater.isMember ? 'Ja' : 'Nee',
            statusString: theater.status == 1 ? 'Ja' : 'Nee',
            agreedToTerms: theater.agreedToTerms ? 'Ja' : 'Nee',
            provinceName: theater.province.name,
            brancheAssociationName: theater.branchAssociation ? theater.branchAssociation.name : null,
            ticketSystemName: theater.ticketSystem ? theater.ticketSystem.name : null,
          }));

          this.tableTheatersFiltered = this.theaters;

          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tableTheatersPaging,
          });
          this.tableTheatersPaging = this.getFilter(this.$options.name + '-paging');

          this.filterTheaters();

          this.tableTheatersLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterTheaters: function () {
      const vm = this;
      this.tableTheatersFiltered = this.theaters.filter(function (theater) {
        return (
          cleanWords(theater.name).indexOf(cleanWords(vm.tableTheatersFilter.name)) !== -1 &&
          cleanWords(theater.city).indexOf(cleanWords(vm.tableTheatersFilter.city)) !== -1 &&
          cleanWords(theater.isMember).indexOf(cleanWords(vm.tableTheatersFilter.customer)) !== -1 &&
          (theater.status == vm.tableTheatersFilter.status || vm.tableTheatersFilter.status == '')
        );
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterTheaters: function () {
      this.tableTheatersFilter.name = '';
      this.tableTheatersFilter.city = '';
      this.tableTheatersFilter.customer = '';
      this.tableTheatersFilter.status = 1;
      this.tableTheatersFilterDialog = false;
    },
    deleteItemTheater: function () {
      ApiService.delete('/Theaters/Delete', {
        theaterId: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableTheatersLoading = true;
            this.getTheaters();

            this.snackbarText = this.deleteItem.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('storage', ['getFilter']),
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableTheatersFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableTheatersPaging,
    });
  },
  watch: {
    tableTheatersFilter: {
      handler: 'filterTheaters',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>
