<template>
  <v-col class="d-flex">
    <v-card
      class="pa-5 mb-1"
      flat
      width="100%"
      height="100%"
    >
      <v-container>
        <v-row
          flex
          class="d-flex flex-grow-1"
        >
          <template>
            <v-col
              cols="6"
              sm="12"
              md="6"
              class="py-0"
            >
              <p class="text-primary font-weight-medium mb-0">capaciteit</p>
              <h3 class="text-primary border-bottom pb-0 mb-4 d-flex">
                {{ formatThousandsWithDot(this.publicMonitorOccupancyRate.capacity) }}
                <div
                  v-if="this.publicMonitorOccupancyRate.comparison != null"
                  class=""
                >
                  /{{ formatThousandsWithDot(this.publicMonitorOccupancyRate.comparison.capacity) }}
                </div>
              </h3>
            </v-col>
            <v-col
              cols="6"
              sm="12"
              md="6"
              class="py-0"
            >
              <p class="text-primary font-weight-medium mb-0">aantal verkochte kaarten</p>
              <h3 class="text-primary border-bottom pb-0 mb-4 d-flex">
                {{ formatThousandsWithDot(this.publicMonitorOccupancyRate.tickets) }}
                <div v-if="this.publicMonitorOccupancyRate.tickets != this.publicMonitorOccupancyRate.totalTickets">
                  /{{ formatThousandsWithDot(this.publicMonitorOccupancyRate.totalTickets) }}
                </div>
                <div v-if="this.publicMonitorOccupancyRate.comparison != null">
                  /{{ formatThousandsWithDot(this.publicMonitorOccupancyRate.comparison.tickets) }}
                </div>
              </h3>
            </v-col>
            <v-col
              cols="6"
              sm="12"
              md="6"
              class="py-0"
              v-if="this.publicMonitorOccupancyRate != null"
            >
              <p class="text-primary font-weight-medium mb-0">aantal kaartkopers</p>
              <h3 class="text-primary border-bottom pb-0 mb-4 d-flex">
                {{ formatThousandsWithDot(this.publicMonitorOccupancyRate.purchases) }}
                <div v-if="this.publicMonitorOccupancyRate.purchases != this.publicMonitorOccupancyRate.totalPurchases">
                  /{{ this.publicMonitorOccupancyRate.totalPurchases }}
                </div>
                <div v-if="this.publicMonitorOccupancyRate.comparison != null">
                  /{{ formatThousandsWithDot(this.publicMonitorOccupancyRate.comparison.purchases) }}
                </div>
              </h3>
            </v-col>

            <v-col
              cols="6"
              sm="12"
              md="6"
              class="py-0"
              v-if="this.publicMonitorOccupancyRate != null"
            >
              <p class="text-primary font-weight-medium mb-0">aantal nieuwe kaartkopers</p>
              <h3 class="text-primary border-bottom pb-0 mb-4 d-flex">
                {{ formatThousandsWithDot(this.publicMonitorOccupancyRate.newPurchases) }} ({{ this.newPurchasesPercentage }}% van totaal)
              </h3>
            </v-col>

            <v-col
              cols="12"
              class="pt-1 pb-4"
              v-if="this.publicMonitorOccupancyRate.rate > 0"
            >
              <v-progress-linear
                class="custom_progress"
                bg-color="#e1e7f2"
                height="20"
                width="200"
                rounded="true"
                :model-value="this.publicMonitorOccupancyRate.rate"
                color="#0abfb8"
              >
                <div
                  class="target"
                  :style="{
                    left: this.publicMonitorOccupancyRate.comparison.rate + '%',
                  }"
                  v-if="this.publicMonitorOccupancyRate.comparison != null"
                />
              </v-progress-linear>
              <i v-if="this.publicMonitorOccupancyRate.rate > 0 && this.publicMonitorOccupancyRate.comparison == null">
                bezettingspercentage
                {{ getPercentageView(this.publicMonitorOccupancyRate.rate) }}
              </i>
              <i
                v-else-if="
                  this.publicMonitorOccupancyRate.rate > 0 && this.publicMonitorOccupancyRate.comparison != null
                "
              >
                {{
                  `bezettingspercentage ${getPercentageView(this.publicMonitorOccupancyRate.rate)} ${
                    filterWidgets.compareWith == 'AllPerformances' ? '(alle voorstellingen:' : '(zelfde genre'
                  } ${getPercentageView(this.publicMonitorOccupancyRate.comparison.rate)})`
                }}
              </i>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import functions from '@/services/functions.service';

export default {
  props: {
    publicMonitorOccupancyRate: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      filterWidgets: {},
    };
  },
  computed: {
    newPurchasesPercentage() {
      const value = (this.publicMonitorOccupancyRate.newPurchases / this.publicMonitorOccupancyRate.purchases) * 100;
      return value.toFixed(2);
    },
  },
  methods: {
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
  },
};
</script>