<template>
  <div>
    <h2 class="text-primary mb-2">wat houdt de financiële afspraak in?</h2>

    <!-- Form -->
    <v-form
      @submit.prevent
      v-model="formValid"
      class="d-flex flex-column"
      ref="form"
    >
      <v-card
        class="py-6 pb-0 px-3 mb-10"
        flat
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="text-primary"
                label="theatertoeslag"
                prefix="€"
                v-model="form.theaterFee"
                :rules="formValidationRules.valutaRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="text-primary"
                label="auteursrechten"
                suffix="%"
                v-model="form.copyrightPercentage"
                :rules="formValidationRules.numberDecimalRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-radio-group
                v-model="form.calculateCopyrightPercentage"
                label="AR percentageberekening"
                row
                class="input-radio-row-label ma-0 pa-0"
              >
                <v-radio
                  label="10/110"
                  :value="true"
                ></v-radio>
                <v-radio
                  label="10/100"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="text-primary"
                label="partage aanbieder"
                suffix="%"
                v-model="form.partageProviderPercentage"
                :rules="formValidationRules.numberDecimalRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="text-primary"
                label="aanvulling tot (excl. BTW)"
                prefix="€"
                v-model="form.amount"
                :rules="formValidationRules.valutaRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="py-0"
            >
              <v-text-field
                class="text-primary"
                label="maximale aanvulling (excl. BTW)"
                prefix="€"
                v-model="form.maximumAmount"
                :rules="formValidationRules.valutaRequired"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-radio-group
                v-model="form.guaranteePerPerformance"
                label="aanvullingsberekening"
                row
                class="input-radio-row-label ma-0 pa-0"
              >
                <v-radio
                  label="afspraak over het totaal"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="afspraak per voorstelling"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
//import ApiService from '../../../services/api.service'
import functions from '../../../../services/functions.service';

export default {
  props: ['form'],
  data() {
    return {
      formValid: false,
      formError: '',
      formValidationRules: {},
    };
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    if (!this.form.theaterFee) {
      this.form.theaterFee =
          this.form.performances[0].theaterFee == null
              ? '0,00'
              : functions.getEuroCurrencyView(this.form.performances[0].theaterFee);
    }
  },
  methods: {
    validateForm() {
      this.$refs.form.validate();
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('form', this.form);
      },
    },
    formValid: function () {
      this.$emit('valid', this.formValid);
    },
  },
};
</script>
