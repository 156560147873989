<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <v-text-field
                v-model="tableUsersFilter.place"
                label="zoek op plaatsnaam"
                hide-details
                variant="outlined"
                density="compact"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-text-field
                v-model="tableUsersFilter.name"
                label="op naam zoeken"
                hide-details
                variant="outlined"
                density="compact"
                bg-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableUsersFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-bind="activatorProps"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="text-primary">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="tableUsersFilter.place"
                      label="zoek op plaatsnaam"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                    <v-text-field
                      v-model="tableUsersFilter.name"
                      label="op naam zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="mb-3"
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableUsersFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      variant="outlined"
                      dark
                      @click="clearFilterUsers()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableUsersHeaders"
          :items="tableUsersFiltered"
          :mobile-breakpoint="0"
          :loading="tableUsersLoading"
          :page="tableUsersPage.page"
          :items-per-page="tableUsersPage.itemsPerPage"
          @update:current-items="tableUsersPage.pageCount = Math.ceil(tableUsersFiltered.length / tableUsersPage.itemsPerPage)"
          @update:items-per-page="tableUsersPage.pageCount = Math.ceil(tableUsersFiltered.length / tableUsersPage.itemsPerPage)"
          class="table-action table-expand mt-2"
          hide-default-footer
          fixed-header
          single-expand
          show-expand
          :height="tableUsersFiltered.length > 10 ? '60vh' : ''"
        >
          <template v-slot:expanded-row="{ columns, item }">
            <td
              :colspan="columns.length"
              class="pa-0"
            >
              <v-data-table
                :headers="tableUsersDetailHeaders"
                :items="item.users"
                :mobile-breakpoint="0"
                hide-default-footer
                :items-per-page="-1"
                class="table-action"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    rounded="circle" 
                    elevation="0" 
                    size="small" 
                    icon="mdi-login-variant"
                    color="warning"
                    class="ml-1"
                    @click="
                      switchRole({
                        userId: item.userId,
                        userRoleId: item.roleId,
                        organisationId: item.organisationId,
                        organisationTypeId: item.organisationTypeId,
                      })
                    "
                  >
                    <v-icon color="primary">mdi-login-variant</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>

        <!-- Tabel settings -->
        <table-settings
          :items="tableUsersFiltered"
          :paging="tableUsersPage"
          :show-excel-button="false"
        ></table-settings>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import { TokenService } from '../../../services/storage.service';
import functions from '../../../services/functions.service';

import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings.vue';

export default {
  name: 'AdminLogin',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      title: 'Gebruikers',
      breadcrumbs: [
        {
          text: 'inloggen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      users: [],
      tableUsersFiltered: [],
      tableUsersLoading: true,
      tableUsersHeaders: [
        { title: 'naam', key: 'name' },
        { title: 'plaatsnaam', key: 'placeName' },
        { title: 'type organisatie', key: 'organisationType' },
        { title: 'aantal accounts', key: 'userCount' },
        { title: 'organisatie id', key: 'organisationId' },
        { title: '', key: 'data-table-expand', align: 'end' },
      ],
      tableUsersFilter: {
        name: '',
        place: '',
      },
      tableUsersPage: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableUsersFilterDialog: false,
      tableUsersDetailHeaders: [
        { title: 'naam', key: 'userName' },
        { title: 'e-mail', key: 'email' },
        { title: 'rol', key: 'role' },
        { title: 'laatste login', key: 'lastLoginDate' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('AdminGebruikers');
    this.getUsers();
  },
  methods: {
    ...mapActions('auth', ['switchRole']),
    getUsers: function () {

      ApiService.get('/Account/GetOrganisationSelection')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.users = response.data.result;

          this.users = this.users.map(user => ({
            ...user,
            id: `${user.id}-${user.name}`,
            organisationId: user.id,
            users: user.users.map(u => ({
              ...u,
              lastLoginDate: functions.getDateTimeView(u.lastLoginDate)
            }))
          }));
          
          this.tableUsersFiltered = this.users;
          this.tableUsersLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    filterUsers: function () {
      const vm = this;
      this.tableUsersFiltered = this.users.filter(function (user) {
        return (
          cleanWords(user.name).indexOf(cleanWords(vm.tableUsersFilter.name)) !== -1 &&
          cleanWords(user.placeName).indexOf(cleanWords(vm.tableUsersFilter.place)) !== -1
        );
      });
      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterUsers: function () {
      this.tableUsersFilter.name = '';
      this.tableUsersFilter.place = '';
      this.tableUsersFilterDialog = false;
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
  watch: {
    tableUsersFilter: {
      handler: 'filterUsers',
      deep: true,
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}
</style>