<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="60"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- User details -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <h1 class="text-primary mb-5">{{ this.areacapacitie.name }}</h1>
                  <h4 class="text-primary mb-2 font-weight-regular">gegevens Zaalcapaciteit</h4>

                  <v-card
                    class="pa-6 mb-1"
                    flat
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>zaal:</small> <br />
                          <strong>{{ areacapacitie.areaName }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>naam:</small> <br />
                          <strong>{{ areacapacitie.name }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>totale capaciteit:</small> <br />
                          <strong>{{ formatThousandsWithDot(areacapacitie.total) }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>

                  <div>
                    <p class="grey--text text--lighten-1 mt-0">
                      <small
                        ><i>{{ this.areacapacitie.lastEditedBy }}</i></small
                      >
                    </p>

                    <!-- Bewerken -->
                    <v-btn
                      v-if="rights.edit"
                      rounded
                      large
                      elevation="0"
                      outlined
                      color="primary"
                      class="text-lowercase mb-5 mr-5 align-self-start"
                      :to="{ name: 'LibraryAreaCapacitiesEdit' }"
                      >gegevens Bewerken
                      <v-icon
                        small
                        right
                        >mdi-square-edit-outline</v-icon
                      ></v-btn
                    >

                    <!-- Verwijderen -->
                    <v-btn
                      v-if="rights.edit"
                      rounded
                      large
                      elevation="0"
                      @click="deleteItemDialog = true"
                      color="error"
                      class="text-lowercase mb-5 align-self-start"
                    >
                      verwijderen
                      <v-icon
                        small
                        right
                        >mdi-delete</v-icon
                      ></v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog AreaCapacityId starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.areacapacitie.name }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.areacapacitie.name }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemAreaCapacities()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';

import Help from '../../../components/Help.vue';

export default {
  name: 'LibraryAreaCapacitiesDetail',
  props: ['theaterAreaId', 'AreaCapacityId'],
  components: {
    Help,
  },
  data() {
    return {
      areacapacitie: {
        areaId: '',
        name: '',
        total: '0',
        lastEditedBy: '',
      },
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'zaalcapaciteit',
          disabled: false,
          href: '/beheer/zaalcapaciteit',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemDialog: false,
      deleteItemLinkDialog: false,
      deleteItemLink: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
    };
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryAreaCapacitiesDetail');
    this.getAreaCapacitie();
  },
  methods: {
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getAreaCapacitie: function () {
      ApiService.get('/AreaCapacities/Get', {
        AreaCapacityId: this.AreaCapacityId,
      })
        .then((response) => {
          if (response.data.success) {
            const areacapacitie = response.data.result;
            this.breadcrumbs[2].text = areacapacitie.name;
            this.areacapacitie.name = areacapacitie.name;
            this.areacapacitie.id = areacapacitie.id;
            this.areacapacitie.areaName = areacapacitie.areaName;
            this.areacapacitie.areaId = areacapacitie.areaId;
            this.areacapacitie.name = areacapacitie.name;
            this.areacapacitie.total = areacapacitie.total;
            this.areacapacitie.theaterId = areacapacitie.theaterId;
            this.areacapacitie.lastEditedBy = areacapacitie.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    deleteItemAreaCapacities: function () {
      ApiService.delete('/AreaCapacities/Delete', {
        AreaCapacityId: this.areacapacitie.id,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.areacapacitie.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/beheer/zaalcapaciteit' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
