<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="flex align-center pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="50"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <v-col
                cols="12"
                md="6"
                sm="10"
              >
                <!-- Form -->
                <v-form
                  @submit.prevent
                  v-model="formValid"
                  class="d-flex flex-column"
                  ref="form"
                >
                  <h1 class="text-primary mb-5">
                    {{ this.production.title }}
                  </h1>
                  <h4 class="text-primary mb-2 font-weight-regular">voorstelling toevoegen</h4>

                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="theater"
                          v-on:change="getAreas"
                          v-model="addProductionPerformanceForm.theaterId"
                          :items="theaters"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="zalen"
                          v-model="addProductionPerformanceForm.areaId"
                          :items="areas"
                          :rules="formValidationRules.required"
                          required
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-checkbox
                          label="In het kader van een festival?"
                          v-model="addProductionPerformanceForm.festival"
                          required
                          outlined
                          dense
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <date-picker
                          v-model="addProductionPerformanceForm.startDate"
                          rules="dateRequired"
                          label=""
                        ></date-picker>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-text-field
                          placeholder="UU:MM"
                          v-model="addProductionPerformanceForm.startDateTime"
                          :rules="formValidationRules.timeRequired"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="Status"
                          v-model="addProductionPerformanceForm.state"
                          :items="performanceStatus"
                          :rules="formValidationRules.required"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          label="Type voorstelling"
                          v-model="addProductionPerformanceForm.type"
                          :items="performanceType"
                          :rules="formValidationRules.required"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-checkbox
                          label="besloten voorstelling"
                          v-model="addProductionPerformanceForm.private"
                          required
                          outlined
                          dense
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-autocomplete
                          multiple
                          label="Kenmerk voorstelling "
                          v-model="addProductionPerformanceForm.characteristicIds"
                          :items="characteristicsOptions"
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-alert
                    v-if="formError != ''"
                    text
                    color="error"
                    >{{ formError }}</v-alert
                  >

                  <v-btn
                    rounded
                    large
                    elevation="0"
                    @click="hasPerformanceDuplicatesCheck"
                    :loading="formLoading"
                    color="secondary"
                    class="text-lowercase mb-5 align-self-start"
                    >Opslaan
                    <v-icon
                      small
                      right
                      >mdi-content-save</v-icon
                    ></v-btn
                  >
                </v-form>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-model="duplicatePerformanceDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title> dubbele voorstelling </v-card-title>
        <v-card-text>
          {{ this.duplicatePerformanceDialogMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(duplicatePerformanceDialog = false), (formLoading = false);"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="submit()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';
import DatePicker from '../../../components/DatePicker.vue';

export default {
  name: 'LibraryProductionsPerformanceAdd',
  props: ['productionNumber'],
  components: {
    Help,
    DatePicker,
  },
  data() {
    return {
      production: {
        name: '',
      },
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      addProductionPerformanceForm: {
        seasonId: '',
        theaterId: '',
        areaId: '',
        templateId: '',
        characteristicIds: [],
        contractId: '',
        startDate: '',
        startDateTime: '',
        dateTime: '',
        type: '',
        festival: false,
        private: false,
        state: '',
      },
      theaters: [],
      areas: [],
      performanceStatus: [
        { text: 'interesse', value: 'interest' },
        { text: 'optie', value: 'option' },
        { text: 'geaccepteerd', value: 'agreed' },
        { text: 'succesoptie', value: 'success' },
        { text: 'annulering', value: 'cancellation' },
      ],
      performanceType: [
        { text: 'normaal', value: 'normal' },
        { text: 'tryout', value: 'tryout' },
        { text: 'premiere', value: 'premiere' },
        { text: 'montage', value: 'montage' },
        { text: 'derniere', value: 'derniere' },
      ],
      characteristicsOptions: [],
      formValidationRules: {},
      duplicatePerformanceDialog: false,
      duplicatePerformanceDialogMessage: '',
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/beheer/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'voorstelling toevoegen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryProductionsPerformanceAdd');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getProduction();
    this.getOrganisations();
    this.getCharacteristics();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getProduction: function () {
      ApiService.get('/Productions/Get', {
        productionNumber: this.productionNumber,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const production = response.data.result;
            this.breadcrumbs[2].text = production.title;
            this.breadcrumbs[2].href = '/beheer/producties/' + this.productionNumber + '/';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getOrganisations: function () {
      ApiService.get('/Theaters/GetAllDropDown')
        .then((response) => {
          response.data.result.forEach((element) => {
            this.theaters.push({
              value: element.id,
              text: `${element.name} (${element.city}, ${element.id})`,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getAreas: function () {
      this.areas = [];
      ApiService.get('/TheaterAreas/GetAll', {
        theaterId: this.addProductionPerformanceForm.theaterId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.areas.push({ value: element.id, text: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getCharacteristics: function () {
      ApiService.get('/Characteristics/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.characteristicsOptions.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    hasPerformanceDuplicatesCheck() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.addProductionPerformanceForm;

        // combine startDate and startDateTime
        let combinedDate =
          this.addProductionPerformanceForm.startDate + ' ' + this.addProductionPerformanceForm.startDateTime;

        ApiService.get('/Performances/GetDuplicate', {
          theaterId: form.theaterId,
          areaId: form.areaId,
          startDate: functions.getDateTimeDatabase(combinedDate),
        }).then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success && response.data.result.isDuplicate) {
            this.duplicatePerformanceDialog = true;

            var duplicatePerformanceOrganisation =
              response.data.result.performance.producerName == ''
                ? response.data.result.performance.agencyName
                : response.data.result.performance.producerName;
            var duplicateMessage = `Let op! Op ${functions.getDateTimeView(
              response.data.result.performance.startDate
            )} in ${response.data.result.performance.theaterName} ${response.data.result.performance.areaName} staat ${
              response.data.result.performance.number
            } van ${duplicatePerformanceOrganisation} met de status ${functions.changeState(
              response.data.result.performance.state
            )} al geboekt. Weet je zeker dat deze boeking correct is?`;

            this.duplicatePerformanceDialogMessage = duplicateMessage;
          } else if (response.data.success && !response.data.result.isDuplicate) {
            this.submit();
          } else {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          }
        });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.addProductionPerformanceForm;

        // combine startDate and startDateTime
        let combinedDate =
          this.addProductionPerformanceForm.startDate + ' ' + this.addProductionPerformanceForm.startDateTime;

        ApiService.post('/Performances/Add', {
          productionNumber: this.productionNumber,
          theaterId: form.theaterId,
          areaId: form.areaId,
          characteristicIds: form.characteristicIds,
          startDate: functions.getDateTimeDatabase(combinedDate),
          type: form.type,
          festival: form.festival,
          private: form.private,
          state: form.state,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
  watch: {
    addProductionPerformanceForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
