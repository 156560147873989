<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="flex align-center pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <!-- Help -->
            <help
              v-if="steps[0].active"
              bg-color="#fff"
              help-id="1085"
            ></help>
            <help
              v-else-if="steps[1].active"
              bg-color="#fff"
              help-id="1086"
            ></help>
            <help
              v-else-if="steps[2].active"
              bg-color="#fff"
              help-id="1087"
            ></help>
            <help
              v-else-if="steps[3].active && form.type == 'Huur'"
              bg-color="#fff"
              help-id="1088"
            ></help>
            <help
              v-else-if="steps[3].active && form.type == 'Partage'"
              bg-color="#fff"
              help-id="1089"
            ></help>
            <help
              v-else-if="steps[3].active && form.type == 'Staffel'"
              bg-color="#fff"
              help-id="1090"
            ></help>
            <help
              v-else-if="steps[3].active && form.type == 'Suppletie'"
              bg-color="#fff"
              help-id="1091"
            ></help>
            <help
              v-else-if="steps[3].active && form.type == 'Uitkoop'"
              bg-color="#fff"
              help-id="1092"
            ></help>
            <help
              v-else-if="steps[4].active"
              bg-color="#fff"
              help-id="1093"
            ></help>
            <help
              v-else-if="steps[5].active"
              bg-color="#fff"
              help-id="1094"
            ></help>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <!-- Step 1 Type -->
            <step-1-type
              v-if="steps[0].active"
              :form="form"
              :steps="steps"
              :step="0"
              @form="form = $event"
              @steps="steps = $event"
            ></step-1-type>

            <!-- Step 2 Who -->
            <step-2-who
              v-if="steps[1].active"
              :form="form"
              ref="validateForm"
              @form="form = $event"
              @valid="steps[1].valid = $event"
            ></step-2-who>

            <!-- Step 3 Performances -->
            <step-3-performances
              v-else-if="steps[2].active"
              :form="form"
              ref="validateForm"
              @form="form = $event"
              @valid="steps[2].valid = $event"
            ></step-3-performances>

            <!-- Step 4 Agreement type -->
            <!-- Rent -->
            <step-4-agreement-rent
              v-else-if="steps[3].active && form.type == 'Huur'"
              :form="form"
              ref="validateForm"
              @form="form = $event"
              @valid="steps[3].valid = $event"
            ></step-4-agreement-rent>

            <!-- Partage -->
            <step-4-agreement-partage
              v-else-if="steps[3].active && form.type == 'Partage'"
              :form="form"
              ref="validateForm"
              @form="form = $event"
              @valid="steps[3].valid = $event"
            ></step-4-agreement-partage>

            <!-- Tranche -->
            <step-4-agreement-tranche
              v-else-if="steps[3].active && form.type == 'Staffel'"
              :form="form"
              ref="validateForm"
              @form="form = $event"
              @valid="steps[3].valid = $event"
            ></step-4-agreement-tranche>

            <!-- Suppletion -->
            <step-4-agreement-suppletion
              v-else-if="steps[3].active && form.type == 'Suppletie'"
              :form="form"
              ref="validateForm"
              @form="form = $event"
              @valid="steps[3].valid = $event"
            ></step-4-agreement-suppletion>

            <!-- Buyout -->
            <step-4-agreement-buyout
              v-else-if="steps[3].active && form.type == 'Uitkoop'"
              :form="form"
              ref="validateForm"
              @form="form = $event"
              @valid="steps[3].valid = $event"
            ></step-4-agreement-buyout>

            <!-- Step 5 Agreement -->
            <step-5-agreement
              v-else-if="steps[4].active"
              :form="form"
              ref="validateForm"
              @form="form = $event"
              @valid="steps[4].valid = $event"
            ></step-5-agreement>

            <!-- Step 6 Contract Details -->
            <step-6-contract-details
              v-else-if="steps[5].active"
              :contract="form"
            ></step-6-contract-details>

            <v-alert
              v-if="formError != ''"
              text
              color="error"
              >{{ formError }}</v-alert
            >

            <!-- Buttons -->
            <!-- Back -->
            <v-btn
              v-if="!steps[5].active && !steps[0].active && !steps[1].active"
              rounded
              large
              outlined
              elevation="0"
              @click="back"
              :loading="formLoading"
              color="primary"
              class="text-lowercase mb-2 mr-2 align-self-start"
              ><v-icon
                small
                left
                >mdi-arrow-left</v-icon
              >
              Ga een stap terug</v-btn
            >

            <!-- Next -->
            <v-btn
              v-if="steps[4].active"
              rounded
              large
              elevation="0"
              @click="next"
              :loading="formLoading"
              color="secondary"
              class="text-lowercase mb-2 mr-2 align-self-start"
              >Bekijk afspraak
              <v-icon
                small
                right
                >mdi-arrow-right</v-icon
              ></v-btn
            >
            <v-btn
              v-else-if="!steps[4].done && form.type != ''"
              rounded
              large
              elevation="0"
              @click="next"
              :loading="formLoading"
              color="secondary"
              class="text-lowercase mb-2 mr-2 align-self-start"
              >Ga verder
              <v-icon
                small
                right
                >mdi-arrow-right</v-icon
              ></v-btn
            >

            <!-- Save -->
            <v-btn
              v-if="steps[5].active"
              rounded
              large
              elevation="0"
              @click="submitProposed"
              :loading="formLoading"
              color="secondary"
              class="text-lowercase mb-2 mr-2 align-self-start"
              >Afspraak voorstellen
              <v-icon
                small
                right
                >mdi-arrow-right</v-icon
              ></v-btn
            >

            <!-- Edit -->
            <v-btn
              v-if="steps[5].active"
              rounded
              large
              outlined
              elevation="0"
              @click="edit"
              :loading="formLoading"
              color="primary"
              class="text-lowercase mb-2 mr-2 align-self-start"
              >Afspraak bewerken
              <v-icon
                small
                right
                >mdi-file-edit-outline</v-icon
              ></v-btn
            >

            <!-- Concept save -->
            <v-btn
              v-if="steps[2].done"
              rounded
              large
              outlined
              elevation="0"
              @click="submitConcept"
              :loading="formLoading"
              color="primary"
              class="text-lowercase mb-2 mr-2 align-self-start"
              >Opslaan als concept
              <v-icon
                small
                right
                >mdi-content-save</v-icon
              ></v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      color="success"
      >financiële afspraak aangemaakt</v-snackbar
    >
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../../components/Help.vue';
import router from '../../router/router';
import functions from '../../services/functions.service';

import Step1Type from './components/formSteps/Step1-Type';
import Step2Who from './components/formSteps/Step2-Who';
import Step3Performances from './components/formSteps/Step3-Performances';

import Step4AgreementRent from './components/formSteps/Step4-Agreement-Rent';
import Step4AgreementPartage from './components/formSteps/Step4-Agreement-Partage';
import Step4AgreementTranche from './components/formSteps/Step4-Agreement-Tranche';
import Step4AgreementSuppletion from './components/formSteps/Step4-Agreement-Suppletion';
import Step4AgreementBuyout from './components/formSteps/Step4-Agreement-Buyout';

import Step5Agreement from './components/formSteps/Step5-Agreement';

import Step6ContractDetails from './components/formSteps/Step6-ContractDetails';

export default {
  name: 'ContractsAdd',
  components: {
    Help,
    Step1Type,
    Step2Who,
    Step3Performances,

    Step4AgreementRent,
    Step4AgreementPartage,
    Step4AgreementTranche,
    Step4AgreementSuppletion,
    Step4AgreementBuyout,

    Step5Agreement,

    Step6ContractDetails,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'financiële afspraken',
          disabled: false,
          href: '/financiele-afspraken',
          bold: false,
        },
        {
          text: 'toevoegen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      steps: [
        {
          name: 'Step1 - Type',
          active: true,
          done: false,
          valid: false,
        },
        {
          name: 'Step2 - Who',
          active: false,
          done: false,
          valid: false,
        },
        {
          name: 'Step3 - Performances',
          active: false,
          done: false,
          valid: false,
        },
        {
          name: 'Step4 - Agreement per type',
          active: false,
          done: false,
          valid: false,
        },
        {
          name: 'Step5 - Agreement',
          active: false,
          done: false,
          valid: false,
        },
        {
          name: 'Step6 - Agreement details',
          active: false,
          done: false,
          valid: false,
        },
      ],
      concept: null,
      snackbar: false,
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      form: {
        id: null,
        type: '',
        state: '',
        code: '',
        theater: {
          id: null,
          name: '',
        },
        producer: {
          id: null,
          name: '',
        },
        theaterContact: {
          id: null,
          fullName: '',
          email: '',
        },
        providerContact: {
          id: null,
          fullName: '',
          email: '',
        },
        production: {
          number: null,
          title: '',
        },
        performances: [],
        ticketPrice1: '0,00',
        ticketPrice2: '0,00',
        ticketPrice3: '0,00',
        ticketPrice4: '0,00',
        ticketPrice5: '0,00',
        ticketPrice6: '0,00',
        ticketPrice7: '0,00',
        ticketPrice8: '0,00',
        ticketPrice9: '0,00',
        ticketPrice10: '0,00',
        consumptionSurcharge: null,
        serviceSurcharge: null,
        wardrobeSurcharge: null,
        theaterTechnicians: null,
        producerTechnicians: null,
        remark: '',
        acceptTerms: true,
        agencyAsProvider: false,

        // specifieke routes
        theaterFee: null,
        rent: '0,00',
        otherCosts: '0,00',
        copyrightPercentage: '0',
        calculateCopyrightPercentage: true,
        providerGuarantee: '0,00',
        consumerGuarantee: '0,00',
        partageProviderPercentage: '0',

        partageProviderAmount1: '0,00',
        partageProviderPercentage1: '0',
        partageProviderAmount2: '0,00',
        partageProviderPercentage2: '0',
        partageProviderAmount3: '0,00',
        partageProviderPercentage3: '0',
        partageProviderAmount4: '0,00',
        partageProviderPercentage4: '0',
        partageProviderPercentageRest: '0',
        combinedBudget: '0,00',
        amount: '0,00',
        maximumAmount: '0,00',
        buyoutAmount: '0,00',
        guaranteePerPerformance: false,
      },
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('ContractsAdd');
    this.checkRights();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    back() {
      var activeStep = this.getActiveStepId();

      if (activeStep == 0) {
        router.back();
      } else {
        this.steps[activeStep].active = false;
        this.steps[activeStep].done = false;
        this.steps[activeStep - 1].done = false;
        this.steps[activeStep - 1].active = true;
      }
    },
    next() {
      var activeStep = this.getActiveStepId();

      var activeForm = this.$refs.validateForm;
      activeForm.validateForm();

      if (this.steps[activeStep].valid) {
        this.steps[activeStep].active = false;
        this.steps[activeStep].done = true;
        this.steps[activeStep + 1].active = true;
      }
    },
    getActiveStepId() {
      var id = 0;
      for (var i = 0; i < this.steps.length; i++) {
        if (this.steps[i].active) {
          id = i;
        }
      }
      return id;
    },
    edit() {
      for (var i = 1; i < this.steps.length; i++) {
        this.steps[i].active = false;
        this.steps[i].done = false;
      }
      this.steps[1].active = true;
    },
    submit() {
      this.formError = '';
      this.formLoading = true;

      const form = this.form;

      const performances = [];
      for (var i = 0; i < form.performances.length; i++) {
        performances.push(form.performances[i].id);
      }
      var state;
      if (this.concept) {
        state = 'concept';
      } else {
        state = 'proposed';
      }

      const postForm = {
        code: form.code,
        state: state,
        remark: form.remark,
        acceptTerms: form.acceptTerms,
        consumptionSurcharge: functions.getEuroCurrencyDatabase(form.consumptionSurcharge),
        serviceSurcharge: functions.getEuroCurrencyDatabase(form.serviceSurcharge),
        wardrobeSurcharge: functions.getEuroCurrencyDatabase(form.wardrobeSurcharge),
        theaterTechnicians: form.theaterTechnicians,
        producerTechnicians: form.producerTechnicians,
        ticketPrice1: functions.getEuroCurrencyDatabase(form.ticketPrice1),
        ticketPrice2: functions.getEuroCurrencyDatabase(form.ticketPrice2),
        ticketPrice3: functions.getEuroCurrencyDatabase(form.ticketPrice3),
        ticketPrice4: functions.getEuroCurrencyDatabase(form.ticketPrice4),
        ticketPrice5: functions.getEuroCurrencyDatabase(form.ticketPrice5),
        ticketPrice6: functions.getEuroCurrencyDatabase(form.ticketPrice6),
        ticketPrice7: functions.getEuroCurrencyDatabase(form.ticketPrice7),
        ticketPrice8: functions.getEuroCurrencyDatabase(form.ticketPrice8),
        ticketPrice9: functions.getEuroCurrencyDatabase(form.ticketPrice9),
        ticketPrice10: functions.getEuroCurrencyDatabase(form.ticketPrice10),
        performanceIds: performances,
        theaterContactId: form.theaterContact.id,
        theaterContactEmail: form.theaterContact.email,
        providerContactId: form.providerContact.id,
        providerContactEmail: form.providerContact.email,
        agencyAsProvider: form.agencyAsProvider,
        theaterFee: functions.getEuroCurrencyDatabase(form.theaterFee)
      };

      var apiLinkPost = '';

      if (form.type == 'Huur') {
        apiLinkPost = '/RentContracts/Add';

        postForm.rent = functions.getEuroCurrencyDatabase(form.rent);
        postForm.otherCosts = functions.getEuroCurrencyDatabase(form.otherCosts);
      } else if (form.type == 'Partage') {
        apiLinkPost = '/PartageContracts/Add';

        postForm.copyrightPercentage = functions.getDecimalDatabase(form.copyrightPercentage);
        postForm.calculateCopyrightPercentage = form.calculateCopyrightPercentage;
        postForm.providerGuarantee = functions.getEuroCurrencyDatabase(form.providerGuarantee);
        postForm.consumerGuarantee = functions.getEuroCurrencyDatabase(form.consumerGuarantee);
        postForm.partageProviderPercentage = functions.getDecimalDatabase(form.partageProviderPercentage);
        postForm.guaranteePerPerformance = form.guaranteePerPerformance;
      } else if (form.type == 'Staffel') {
        apiLinkPost = '/TrancheContracts/Add';

        postForm.copyrightPercentage = functions.getDecimalDatabase(form.copyrightPercentage);
        postForm.calculateCopyrightPercentage = form.calculateCopyrightPercentage;
        postForm.providerGuarantee = functions.getEuroCurrencyDatabase(form.providerGuarantee);
        postForm.consumerGuarantee = functions.getEuroCurrencyDatabase(form.consumerGuarantee);

        postForm.partageProviderAmount1 = functions.getEuroCurrencyDatabase(form.partageProviderAmount1);
        postForm.partageProviderPercentage1 = functions.getDecimalDatabase(form.partageProviderPercentage1);
        postForm.partageProviderAmount2 = functions.getEuroCurrencyDatabase(form.partageProviderAmount2);
        postForm.partageProviderPercentage2 = functions.getDecimalDatabase(form.partageProviderPercentage2);
        postForm.partageProviderAmount3 = functions.getEuroCurrencyDatabase(form.partageProviderAmount3);
        postForm.partageProviderPercentage3 = functions.getDecimalDatabase(form.partageProviderPercentage3);
        postForm.partageProviderAmount4 = functions.getEuroCurrencyDatabase(form.partageProviderAmount4);
        postForm.partageProviderPercentage4 = functions.getDecimalDatabase(form.partageProviderPercentage4);
        postForm.partageProviderPercentageRest = functions.getDecimalDatabase(form.partageProviderPercentageRest);
        postForm.combinedBudget = functions.getEuroCurrencyDatabase(form.combinedBudget);
      } else if (form.type == 'Suppletie') {
        apiLinkPost = '/SuppletionContracts/Add';

        postForm.copyrightPercentage = functions.getDecimalDatabase(form.copyrightPercentage);
        postForm.calculateCopyrightPercentage = form.calculateCopyrightPercentage;
        postForm.partageProviderPercentage = functions.getDecimalDatabase(form.partageProviderPercentage);
        postForm.amount = functions.getEuroCurrencyDatabase(form.amount);
        postForm.maximumAmount = functions.getEuroCurrencyDatabase(form.maximumAmount);
        postForm.guaranteePerPerformance = form.guaranteePerPerformance;
      } else if (form.type == 'Uitkoop') {
        apiLinkPost = '/BuyoutContracts/Add';

        postForm.copyrightPercentage = functions.getDecimalDatabase(form.copyrightPercentage);
        postForm.buyoutAmount = functions.getEuroCurrencyDatabase(form.buyoutAmount);
      }

      ApiService.post(apiLinkPost, postForm)
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          if (response.data.success) {
            this.isEditing = false;
            this.snackbar = true;
            setTimeout(function () {
              router.push({ name: 'Contracts' });
            }, 1000);
          } else {
            this.formLoading = false;
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
          }
        })
        // eslint-disable-next-line
        .catch(() => {
          this.formLoading = false;
          this.formError =
            'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
        });
    },
    submitProposed() {
      this.concept = false;
      this.submit();
    },
    submitConcept() {
      this.concept = true;
      this.submit();
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style scoped></style>
