<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="flex align-center pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="24"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- Form -->
              <v-form
                @submit.prevent
                v-model="formValid"
                class="d-flex flex-column col col-6"
                ref="form"
              >
                <h1 class="text-primary mb-5">{{ this.agency.name }}</h1>
                <h4 class="text-primary mb-2 font-weight-regular">gebruiker toevoegen</h4>

                <v-card
                  class="pa-6 mb-10"
                  flat
                >
                  <v-row class="mt-0">
                    <!-- Add user -->
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-autocomplete
                        label="gebruiker"
                        v-model="addUserLinkForm.userId"
                        :items="userOptions"
                        :rules="formValidationRules.required"
                        required
                        variant="outlined"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Userrol -->
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-autocomplete
                        label="gebruikersrol"
                        v-model="addUserLinkForm.roleId"
                        :items="userRoleOptions"
                        :rules="formValidationRules.required"
                        required
                        variant="outlined"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>

                <v-alert
                  v-if="formError != ''"
                  text
                  color="error"
                  >{{ formError }}</v-alert
                >

                <v-btn
                  rounded
                  large
                  elevation="0"
                  @click="submit"
                  :loading="formLoading"
                  color="secondary"
                  class="text-lowercase mb-5 align-self-start"
                  >Opslaan
                  <v-icon
                    small
                    right
                    >mdi-content-save</v-icon
                  ></v-btn
                >
              </v-form>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import Help from '../../../components/Help.vue';

export default {
  name: 'AdminTheatersLinkAdd',
  props: ['agencyId'],
  components: {
    Help,
  },
  data() {
    return {
      agency: {
        name: '',
      },
      isEditing: false,
      formValid: false,
      formError: '',
      formLoading: false,
      addUserLinkForm: {
        roleId: '',
        userId: '',
      },
      userOptions: [],
      userRoleOptions: [],
      formValidationRules: {},
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'impresariaten',
          disabled: false,
          href: '/admin/impresariaten',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruiker toevoegen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.formValidationRules = functions.getFormValidationRules();
    this.getAgency();
    this.getAgencyRoles();
    this.getUsers();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    getAgency: function () {
      ApiService.get('/Agencies/Get', {
        agencyId: this.agencyId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const agency = response.data.result;
            this.breadcrumbs[2].text = agency.name;
            this.breadcrumbs[2].href = '/admin/impresariaten/' + this.agencyId + '/';
            this.breadcrumbs[3].href = '/admin/impresariaten/' + this.agencyId + '/link/add';
            this.agency.name = agency.name;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getAgencyRoles: function () {
      ApiService.get('/UserRoles/GetAll', {
        organisationTypeId: 1,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          response.data.result.forEach((element) => {
            this.userRoleOptions.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getUsers: function () {
      ApiService.get('/Users/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          response.data.result.forEach((element) => {
            this.userOptions.push({ value: element.id, title: element.fullName + ' (' + element.email + ')' });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    submit() {
      this.formError = '';
      this.formLoading = true;
      if (this.formValid) {
        const form = this.addUserLinkForm;

        ApiService.post('/UserRoles/Add', {
          organisationTypeId: 1,
          organisationId: parseInt(this.agencyId),
          roleId: form.roleId,
          userId: form.userId,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {},
  watch: {
    addUserLinkForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
