<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="1"></breadcrumbs-with-help>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableDuplicatePerformancesHeaders"
          :items="tableDuplicatePerformancesFiltered"
          :mobile-breakpoint="0"
          :loading="tableDuplicatePerformancesLoading"
          :page="tableDuplicatePerformancesPaging.page"
          :items-per-page="tableDuplicatePerformancesPaging.itemsPerPage"
          @update:current-items="tableDuplicatePerformancesPaging.pageCount = Math.ceil(tableDuplicatePerformancesFiltered.length / tableDuplicatePerformancesPaging.itemsPerPage)"
          @update:items-per-page="tableDuplicatePerformancesPaging.pageCount = Math.ceil(tableDuplicatePerformancesFiltered.length / tableDuplicatePerformancesPaging.itemsPerPage)"
          class="table-action table-expand mt-2"
          hide-default-footer
          fixed-header
          single-expand
          show-expand
          item-key="startDate"
          :expanded="clearExpand"
          :height="tableDuplicatePerformancesFiltered.length > 10 ? '60vh' : ''"
        >
          <template v-slot:[`item.startDate`]="{ item }">
            <span>{{ item.startDate_formatted }}</span>
          </template>

          <template v-slot:expanded-row="{ columns, item }">
            <td
              :colspan="columns.length"
              class="pa-0"
            >
              <v-data-table
                :headers="tableProductionsDetailsHeaders"
                :items="item.performances"
                :mobile-breakpoint="0"
                hide-default-footer
                :items-per-page="-1"
                class="table-action"
              >
                <template v-slot:[`item.type`]="{ item }">
                  <span v-if="item.type == 'normal'">normaal</span>
                  <span v-if="item.type == 'tryout'">tryout</span>
                  <span v-if="item.type == 'premiere'">premiere</span>
                  <span v-if="item.type == 'montage'">montage</span>
                  <span v-if="item.type == 'derniere'">derniere</span>
                </template>

                <template v-slot:[`item.state`]="{ item }">
                  <span v-if="item.state == 'agreed'">geaccepteerd</span>
                  <span v-if="item.state == 'option'">optie</span>
                  <span v-if="item.state == 'success'">succesoptie</span>
                  <span v-if="item.state == 'cancellation'">annulering</span>
                  <span v-if="item.state == 'interest'">interesse</span>
                </template>

                <template v-slot:[`item.startDate`]="{ item }">
                  <span>{{ item.startDate_formatted }}</span>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    rounded="circle" 
                    elevation="0" 
                    size="small" 
                    icon="mdi-check"
                    color="warning"
                    class="ml-1"
                    @click="(deleteItem = item), (deleteItemDialog = true);"
                  >
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>

        <!-- Table settings -->
        <table-settings
          :items="tableDuplicatePerformancesFiltered"
          :paging="tableDuplicatePerformancesPaging"
          :export-columns="downloadExportColumns"
          :show-excel-button="true"
        ></table-settings>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Voorstelling met ID:{{ this.deleteItem.id }} fiatteren?</v-card-title>
        <v-card-text
          >Weet je zeker dat je deze voorstelling met ID{{ this.deleteItem.id }} en PKI-nummer
          {{ this.deleteItem.pkiNumber }} wilt fiatteren? Deze is daarna niet meer terug te vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(deleteItemDialog = false), (deleteItem = '');"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="approvePerformance()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
      elevation="0"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import ApiService from '@/services/api.service';
import { TokenService } from '@/services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '../../../components/TableSettings.vue';
import functions from '@/services/functions.service';

export default {
  name: 'MonitorDuplicatePerformances',
  components: {
    BreadcrumbsWithHelp,
    TableSettings
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'monitor',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'dubbele voorstellingen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      duplicatePerformances: [],
      types: [],
      tableDuplicatePerformancesFiltered: [],
      clearExpand: [],
      tableDuplicatePerformancesLoading: true,
      tableDuplicatePerformancesHeaders: [
        { title: 'datum', key: 'startDate' },
        { title: 'theater', key: 'theaterName' },
        { title: 'zaal', key: 'areaName' },
        { title: '', key: 'data-table-expand', align: 'end' },
      ],
      tableDuplicatePerformancesPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableDuplicatePerformancesFilterDialog: false,
      tableProductionsDetailsHeaders: [
        { title: 'productie', key: 'productionTitle' },
        { title: 'productie nummer', key: 'productionNumber' },
        { title: 'id', key: 'id' },
        { title: 'nummer', key: 'number' },
        { title: 'type', key: 'type' },
        { title: 'status', key: 'state' },
        { title: 'startdatum', key: 'startDate' },
        { title: 'impresariaat', key: 'agencyName' },
        { title: 'producent', key: 'producerName' },
        {
          title: '',
          key: 'action',
          sortable: false,
          align: 'end',
        },
      ],
      downloadExportColumns: [
        { label: 'productie', field: 'productionTitle' },
        { label: 'productie nummer', field: 'productionNumber' },
        { label: 'theater', field: 'theaterName' },
      ],
      deleteItemDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
    };
  },
  mounted() {
    this.getDuplicatePerformances();
  },
  methods: {
    getDateTimeView: function (date) {
      return functions.getDateTimeView(date);
    },
    getDuplicatePerformances: function () {
      this.duplicatePerformances = [];
      this.tableDuplicatePerformancesFiltered = [];
      this.clearExpand = [];
      this.tableDuplicatePerformancesLoading = true;

      ApiService.get('/Performances/GetDuplicates')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.duplicatePerformances = response.data.result;

          this.duplicatePerformances.forEach((performance, i) => {
            performance.key = i;
            performance.startDate_formatted = functions.getDateTimeView(performance.startDate);

            performance.performances.forEach((subPerformance) => {
              subPerformance.startDate_formatted = functions.getDateTimeView(subPerformance.startDate);
            });
          });

          this.tableDuplicatePerformancesFiltered = this.duplicatePerformances;
          this.tableDuplicatePerformancesLoading = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    approvePerformance: function () {
      ApiService.put('/Performances/UpdateChecked', {
        performanceId: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableDuplicatePerformancesLoading = true;

            this.getDuplicatePerformances();

            this.snackbarText = this.deleteItem.id + ' is verwijderd';
            this.deleteItemDialog = false;
            this.deleteItem = '';
            this.snackbarColor = 'success';
            this.snackbar = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
};
</script>