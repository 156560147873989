<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1149"
            ></help>
          </v-col>

          <v-col class="py-0 d-flex align-center justify-end"></v-col>
        </v-row>

        <v-row
          class="pb-5"
          no-gutters
          v-if="!this.tablePublicMonitorOverlapAnalysisLoading"
        >
          <top-info-cards :production="this.selectedProduction"></top-info-cards>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tablePublicMonitorOverlapAnalysisHeaders"
          :items="tablePublicMonitorOverlapAnalysisFiltered"
          :mobile-breakpoint="0"
          :loading="tablePublicMonitorOverlapAnalysisLoading"
          :page.sync="tablePublicMonitorOverlapAnalysisPage"
          :items-per-page="tablePublicMonitorItemsPerPage"
          hide-default-footer
          @update:current-items="tablePublicMonitorOverlapAnalysisPageCount = Math.ceil(tablePublicMonitorOverlapAnalysisFiltered.length / tablePublicMonitorItemsPerPage)"
          @update:items-per-page="tablePublicMonitorOverlapAnalysisPageCount = Math.ceil(tablePublicMonitorOverlapAnalysisFiltered.length / tablePublicMonitorItemsPerPage)"
          class="table-action"
          fixed-header
          :height="tablePublicMonitorOverlapAnalysisFiltered.length > 10 ? '55vh' : ''"
        >
          <template v-slot:[`item.overlap.rate`]="{ item }">
            <td class="tooltip-container">
              <span class="tooltip"
                ><table>
                  <tbody>
                    <tr>
                      <th scope="row">overlap</th>
                      <td>{{ item.overlap.count }}</td>
                    </tr>
                    <tr>
                      <th scope="row">overlap percentueel</th>
                      <td>{{ `${item.overlap.rate}%` }}</td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <v-progress-linear
                class="custom_progress"
                bg-color="#e1e7f2"
                height="20"
                width="200"
                rounded="true"
                :model-value="item.overlap.rate"
                color="#0abfb8"
              >
              </v-progress-linear>
            </td>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              v-if="item.number != null && item.number != 'no_export'"
              fab
              x-small
              depressed
              color="info"
              class="ml-1"
              :to="{
                name: 'SalesMonitorProductionsDetail',
                params: { productionNumber: item.number },
              }"
            >
              <v-icon color="primary">mdi-link-variant</v-icon>
            </v-btn>

            <v-btn
              v-if="!item.hasTooFewPurchases"
              fab
              x-small
              depressed
              color="warning"
              class="ml-1"
              :to="{
                name: 'PublicMonitorProductionsDetail',
                params: { productionNumber: item.id },
              }"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>

        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption text-primary ma-0 pa-0">
              totaal:
              {{ this.tablePublicMonitorOverlapAnalysisFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tablePublicMonitorOverlapAnalysisFiltered.length > 25"
              @click="tablePublicMonitorItemsPerPage = 25"
              :color="tablePublicMonitorItemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tablePublicMonitorOverlapAnalysisFiltered.length > 25"
              @click="tablePublicMonitorItemsPerPage = 50"
              :color="tablePublicMonitorItemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tablePublicMonitorOverlapAnalysisFiltered.length > 50"
              @click="tablePublicMonitorItemsPerPage = 100"
              :color="tablePublicMonitorItemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tablePublicMonitorOverlapAnalysisPageCount > 1"
              v-model="tablePublicMonitorOverlapAnalysisPage"
              :length="tablePublicMonitorOverlapAnalysisPageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>

          <!-- Table download -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="tablePublicMonitorOverlapAnalysisFiltered"
                :columns="downloadExportColumns"
                :file-name="'dip-overlap-analyse-export'"
                :sheet-name="'publieksmonitor overlapanalyse'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../../components/Help.vue';
import TopInfoCards from './components/OverlapAnalysis/TopInfoCards.vue';

export default {
  name: 'PublicMonitorProductions',
  props: ['productionId'],
  components: {
    Help,
    TopInfoCards,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'publieksmonitor',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/publieksmonitor/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'overlapanalyse',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      productions: [],
      selectedProduction: {
        id: 0,
        title: '',
        genre: '',
        overlapCount: 0,
      },
      tablePublicMonitorOverlapAnalysisFiltered: [],
      tablePublicMonitorOverlapAnalysisLoading: true,
      tablePublicMonitorOverlapAnalysisHeaders: [
        { title: 'naam', key: 'title' },
        { title: 'genre', key: 'genre' },
        {
          title: 'aantal overlap',
          key: 'overlap.rate',
          sortable: false,
          width: '125px',
        },
        { title: 'overlap (aantal klanten)', key: 'overlap.count' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tablePublicMonitorFilterActive: false,
      tablePublicMonitorFilter: {
        productionName: '',
        genre: '',
      },
      tablePublicMonitorOverlapAnalysisPage: 1,
      tablePublicMonitorOverlapAnalysisPageCount: 0,
      tablePublicMonitorItemsPerPage: 25,
      tablePublicMonitorFilterDialog: false,
      downloadExportColumns: [
        { label: 'naam', field: 'title' },
        { label: 'genre', field: 'genre' },
        { label: 'aantal overlap', field: 'overlap.rate' },
        { label: 'overlap', field: 'overlap.count' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('PublicMonitor');
    this.getProductionOverlap();
  },
  methods: {
    getProductionOverlap: function () {
      //const vm = this; const vmLegacy = { $set: (o, k, v) => o[k] = v };
      this.productions = [];
      this.tablePublicMonitorOverlapAnalysisFiltered = [];
      this.tablePublicMonitorOverlapAnalysisLoading = true;

      ApiService.get('PublicMonitor/GetProductionOverlap', {
        Id: this.productionId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.productions = response.data.result;

          this.selectedProduction.id = this.productions.selected.id;
          this.selectedProduction.title = this.productions.selected.title;
          this.selectedProduction.genre = this.productions.selected.genre;
          this.selectedProduction.overlapCount = this.productions.selected.overlap.count;

          this.breadcrumbs[2].text = `${this.productions.selected.title} (${this.productionId})`;
          this.breadcrumbs[2].href = `/publieksmonitor/producties/${this.productionId}`;

          this.tablePublicMonitorOverlapAnalysisFiltered = this.productions.productions;
          this.tablePublicMonitorOverlapAnalysisLoading = false;
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
  },
};
</script>