<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="4"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- User details -->

              <v-col
                cols="12"
                md="6"
                sm="10"
              >
                <h1 class="text-primary mb-5">{{ this.userInfo.firstName }} {{ this.userInfo.lastName }}</h1>
                <h4 class="text-primary mb-2 font-weight-regular">gegevens gebruiker</h4>

                <v-card
                  class="pa-6 mb-1"
                  flat
                >
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <p class="text-primary mb-0">
                        <small>naam:</small> <br />
                        <strong>{{ userInfo.firstName }} {{ userInfo.lastName }}</strong>
                      </p>
                    </v-col>
                  </v-row>

                  <hr class="accent-hr my-2" />
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <p class="text-primary mb-0">
                        <small>e-mailadres:</small> <br />
                        <strong>{{ userInfo.email }}</strong>
                      </p>
                    </v-col>
                  </v-row>
                  <hr class="accent-hr my-2" />
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <p class="text-primary mb-0">
                        <small>admin:</small> <br />
                        <strong><span v-if="userInfo.isSuperUser">Ja</span><span v-else>Nee</span></strong>
                      </p>
                    </v-col>
                  </v-row>
                </v-card>

                <div>
                  <p class="grey--text text--lighten-1 mt-0">
                    <small
                      ><i>{{ this.userInfo.lastEditedBy }}</i></small
                    >
                  </p>

                  <!-- Bewerken -->
                  <v-btn
                    rounded
                    large
                    elevation="0"
                    outlined
                    color="primary"
                    class="text-lowercase mb-5 mr-5 align-self-start"
                    :to="{ name: 'AdminUsersEdit' }"
                    >gegevens Bewerken
                    <v-icon
                      small
                      right
                      >mdi-square-edit-outline</v-icon
                    ></v-btn
                  >

                  <!-- Verwijderen -->
                  <v-btn
                    v-if="user.userId != this.userId"
                    rounded
                    large
                    elevation="0"
                    @click="deleteItemDialog = true"
                    color="error"
                    class="text-lowercase mb-5 align-self-start"
                  >
                    verwijderen
                    <v-icon
                      small
                      right
                      >mdi-delete</v-icon
                    ></v-btn
                  >
                </div>
              </v-col>

              <!-- Organizational details overview -->

              <v-col
                cols="12"
                md="6"
                sm="10"
              >
                <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                  <h4 class="text-primary font-weight-regular">organisatiegegevens</h4>
                  <v-btn
                    rounded
                    small
                    elevation="0"
                    color="primary"
                    class="text-lowercase"
                    :to="{ name: 'AdminUsersLinkAdd' }"
                    >rechten toekennen</v-btn
                  >
                </v-row>

                <!-- Table -->
                <v-data-table
                  :headers="tableUsersOrganisationsHeaders"
                  :items="tableUsersOrganisationsFiltered"
                  :mobile-breakpoint="0"
                  :loading="tableUsersOrganisationsLoading"
                  :items-per-page="-1"
                  hide-default-footer
                  class="table-action"
                >
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      fab
                      x-small
                      depressed
                      color="info"
                      class="ml-1"
                      :to="{
                        name: 'AdminUsersLinkEdit',
                        params: {
                          linkId: item.linkId,
                          userId: userId,
                          organisationTypeId: item.organisationTypeId,
                        },
                      }"
                    >
                      <v-icon color="primary">mdi-square-edit-outline</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      x-small
                      depressed
                      color="error"
                      class="ml-1"
                      @click="(deleteItemLink = item), (deleteItemLinkDialog = true);"
                    >
                      <v-icon color="#fff">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>

              <!-- Token Details overview -->

              <v-col
                cols="12"
                md="6"
                sm="10"
              >
                <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                  <h4 class="text-primary font-weight-regular">tokens</h4>
                  <v-btn
                    rounded
                    small
                    elevation="0"
                    color="primary"
                    class="text-lowercase"
                    :loading="tableUsersTokensLoading"
                    @click="addClientCredentials()"
                    >token toekennen</v-btn
                  >
                </v-row>

                <!-- Table -->
                <v-data-table
                  :headers="tableUsersTokensHeaders"
                  :items="tableUsersTokensFiltered"
                  :mobile-breakpoint="0"
                  :loading="tableUsersTokensLoading"
                  :items-per-page="-1"
                  hide-default-footer
                  class="table-action"
                >
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      fab
                      x-small
                      depressed
                      color="error"
                      class="ml-1"
                      @click="(deleteItemToken = item), (deleteItemTokenDialog = true);"
                    >
                      <v-icon color="#fff">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>

            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog user starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline"
          >{{ this.userInfo.firstName }} {{ this.userInfo.lastName }} verwijderen?</v-card-title
        >
        <v-card-text
          >Weet je zeker dat je {{ this.userInfo.firstName }} {{ this.userInfo.lastName }} wil verwijderen? Het is
          daarna niet meer terug te vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemUser()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog link starts here -->
    <v-dialog
      v-model="deleteItemLinkDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Gebruikersrol verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je deze gebruikersrol wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemLinkDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemLinkUser()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog link starts here -->
    <v-dialog
      v-model="deleteItemTokenDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Token verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je dit token wil verwijderen? Het is daarna niet meer terug te vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemTokenDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemTokenUser()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';

import Help from '../../../components/Help.vue';

export default {
  name: 'AdminUsersDetail',
  props: ['userId'],
  components: {
    Help,
  },
  data() {
    return {
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        isSuperUser: false,
        lastEditedBy: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'gebruikers',
          disabled: false,
          href: '/admin/gebruikers',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      deleteItemDialog: false,
      deleteItemLinkDialog: false,
      deleteItemTokenDialog: false,
      deleteItemLink: '',
      deleteItemToken: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      tableUsersOrganisationsFiltered: [],
      tableUsersOrganisationsLoading: true,
      tableUsersOrganisationsHeaders: [
        { title: 'organisatie', key: 'organisationName' },
        { title: 'rol', key: 'roleName' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      tableUsersTokensFiltered: [],
      tableUsersTokensLoading: true,
      tableUsersTokensHeaders: [
        { title: 'ClientId', key: 'clientId' },
        { title: 'Secret', key: 'clientSecret' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
    };
  },
  mounted() {
    this.getUser();
    this.getUserOrganisations();
    this.getUserTokens();
  },
  methods: {
    getUser: function () {
      ApiService.get('/Users/Get', {
        userId: this.userId,
      })
        .then((response) => {
          if (response.data.success) {
            const userInfo = response.data.result;

            this.userInfo.firstName = userInfo.firstName != null ? userInfo.firstName : '';
            this.userInfo.lastName = userInfo.lastName != null ? userInfo.lastName : '';
            this.userInfo.email = userInfo.email;
            this.userInfo.isSuperUser = userInfo.isSuperUser;
            this.userInfo.lastEditedBy = userInfo.lastEditedBy;

            this.breadcrumbs[2].text = this.userInfo.firstName + ' ' + this.userInfo.lastName;
            this.breadcrumbs[2].href = '/admin/gebruikers/' + userInfo.id;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getUserOrganisations: function () {
      ApiService.get('/Users/GetOrganisationRoles', {
        userId: this.userId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableUsersOrganisationsFiltered = response.data.result;
            this.tableUsersOrganisationsLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getUserTokens: function () {
      //const vm = this; const vmLegacy = { $set: (o, k, v) => o[k] = v };

      ApiService.get('/Users/GetClientCredentials', {
        userId: this.userId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableUsersTokensFiltered = response.data.result;
            this.tableUsersTokensLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    addClientCredentials: function () {
      this.tableUsersTokensLoading = true;
      ApiService.post('/Users/AddClientCredentials', {
        userId: this.userId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableUsersTokensLoading = false;
            this.getUserTokens();
          } else {
            this.tableUsersTokensLoading = false;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.tableUsersTokensLoading = false;
        });
    },
    deleteItemUser: function () {
      ApiService.delete('/Users/Delete', {
        id: this.userId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.userInfo.firstName + ' is verwijderd';
            this.deleteItemDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/admin/gebruikers' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    deleteItemLinkUser: function () {
      ApiService.delete('/UserRoles/Delete', {
        linkId: this.deleteItemLink.linkId,
        organisationTypeId: this.deleteItemLink.organisationTypeId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableUsersOrganisationsFiltered.map((item) => item.linkId).indexOf(this.deleteItemLink.linkId);
            this.tableUsersOrganisationsFiltered.splice(i, 1);

            this.snackbarText = 'Gebruikersrol is verwijderd';
            this.deleteItemLinkDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    deleteItemTokenUser: function () {
      ApiService.delete('/Users/DeleteClientCredentials', {
        tokenId: this.deleteItemToken.id,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableUsersTokensFiltered.map((item) => item.id).indexOf(this.deleteItemToken.id);
            this.tableUsersTokensFiltered.splice(i, 1);

            this.snackbarText = 'Token is verwijderd';
            this.deleteItemTokenDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
