<template>
  <v-menu
    :close-on-click="true"
    :close-on-content-click="false"
    v-model="datePickerDialog"
    max-width="290px"
    min-width="auto"
    nudge-top="-40"
  >
    <template v-slot:activator="{ props }">
      <v-text-field
        v-model="formattedDate"
        v-bind="props"
        :label="datePickerLabel ?? 'startdatum'"
        placeholder="DD-MM-JJJJ"
        @update:model-value="dateTyped"
        :rules="datePickerDateRequired ? formValidationRules.dateRequired : formValidationRules.date"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="datePickerStart"
      no-title
      hide-header
      first-day-of-week="1"
      @update:model-value="datePicked"
      color="success"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { ref, onMounted } from 'vue';
import functions from '../services/functions.service';

export default {
  props: {
    passedDate: {
      type: String,
      default: ''
    },
    rules: {
      type: [String, Array],
      default: 'date'
    },
    label: {
      type: String,
      default: 'startdatum'
    }
  },
  setup(props, { emit }) {
    const formattedDate = ref(props.passedDate || '');
    const datePickerDialog = ref(false);
    const datePickerLabel = ref(props.label);
    const datePickerDateRequired = ref(false);

    const datePickerStart = ref(
      props.passedDate
        ? normalizeDate(props.passedDate)
        : new Date().toISOString().substring(0, 10)
    );
    
    const formValidationRules = ref(functions.getFormValidationRules());

    function normalizeDate(dateString) {
      const date = new Date(dateString);
      date.setHours(0, 0, 0, 0);
      return date;
    }

    onMounted(() => {
      if (props.rules === 'date' || props.rules === undefined) {
        datePickerDateRequired.value = false;
      } else {
        datePickerDateRequired.value = true;
      }
    });

    const datePicked = (date) => {
      if (!date) return;

      formattedDate.value = formatDateForInput(date);
      datePickerDialog.value = false;
      emit('update:modelValue', formattedDate.value);
    };

    const formatDateForInput = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    const dateTyped = (date) => {
      if (!date || date.length !== 10) return;
      datePickerStart.value = normalizeDate(date);
      emit('update:modelValue', formattedDate.value);
    };

    return {
      formattedDate,
      datePickerDialog,
      datePickerLabel,
      datePickerDateRequired,
      datePickerStart,
      formValidationRules,
      datePicked,
      dateTyped
    };
  }
};
</script>

<style>
.v-date-picker-table--date td {
  padding: 5px 0;
}
</style>