<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          class="d-flex"
          justify="center"
          align="center"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="text-primary">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="text-primary"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="16"
            ></help>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-layout class="d-flex flex-column align-center justify-center">
              <!-- Producers details -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <h1 class="text-primary mb-5">{{ this.producer.name }}</h1>
                  <h4 class="text-primary mb-2 font-weight-regular">gegevens producent</h4>

                  <v-card
                    class="pa-6 mb-10"
                    flat
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>type:</small> <br />
                          <strong>{{ producer.type }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>branchevereniging:</small><br /><strong>{{ producer.branchAssociation }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>voorwaarden DIP geaccepteerd:</small> <br /><strong
                            ><span v-if="producer.agreedToTerms">Ja</span><span v-else>Nee</span></strong
                          >
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="accent-hr my-2" />
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>toon publieksmonitor:</small> <br /><strong
                            ><span v-if="producer.hasPublicMonitorAccess">Ja</span><span v-else>Nee</span></strong
                          >
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>

                  <h4 class="text-primary mb-2 font-weight-regular">adres</h4>
                  <v-card
                    class="pa-6 mb-1"
                    flat
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <p class="text-primary mb-0">
                          <small>straat en huisnummer:</small> <br />
                          <strong>{{ producer.street + ' ' + producer.number }}</strong>
                        </p>
                        <hr class="accent-hr my-2" />
                        <p class="text-primary mb-0">
                          <small>postcode:</small> <br />
                          <strong>{{ producer.zipCode }}</strong>
                        </p>
                        <hr class="accent-hr my-2" />
                        <p class="text-primary mb-0">
                          <small>plaats:</small> <br />
                          <strong>{{ producer.city }}</strong>
                        </p>
                        <hr class="accent-hr my-2" />
                        <p class="text-primary mb-0">
                          <small>provincie:</small> <br />
                          <strong>{{ producer.province }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>

                  <div>
                    <p class="grey--text text--lighten-1 mt-0">
                      <small
                        ><i>{{ this.producer.lastEditedBy }}</i></small
                      >
                    </p>

                    <!-- Bewerken -->
                    <v-btn
                      rounded
                      large
                      elevation="0"
                      outlined
                      color="primary"
                      class="text-lowercase mb-5 mr-5 align-self-start"
                      :to="{ name: 'AdminProducersEdit' }"
                      >Gegevens Bewerken
                      <v-icon
                        small
                        right
                        >mdi-square-edit-outline</v-icon
                      ></v-btn
                    >

                    <!-- Verwijderen -->
                    <v-btn
                      rounded
                      large
                      elevation="0"
                      @click="deleteItemDialog = true"
                      color="error"
                      class="text-lowercase mb-5 align-self-start"
                      >Verwijderen
                      <v-icon
                        small
                        right
                        >mdi-delete</v-icon
                      ></v-btn
                    >
                  </div>
                  <div>
                    <v-btn
                      rounded
                      large
                      elevation="0"
                      color="primary"
                      class="text-lowercase mb-5 mr-5 align-self-start"
                      :to="{ name: 'AdminProducersProductions' }"
                      >Producties</v-btn
                    >
                  </div>
                </v-col>
              </v-row>

              <!-- Active User overview -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                    <h4 class="text-primary font-weight-regular">gebruikers</h4>
                    <v-btn
                      rounded
                      small
                      elevation="0"
                      color="primary"
                      class="text-lowercase"
                      :to="{ name: 'AdminProducersLinkAdd' }"
                      >gebruiker toevoegen</v-btn
                    >
                  </v-row>

                  <!-- Table -->
                  <v-data-table
                    :headers="tableProducersUsersHeaders"
                    :items="tableProducersUsersFiltered"
                    :mobile-breakpoint="0"
                    :loading="tableProducersUsersLoading"
                    hide-default-footer
                    class="table-action"
                  >
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="info"
                        class="ml-1"
                        :to="{
                          name: 'AdminProducersLinkEdit',
                          params: { linkId: item.linkId, producerId: producerId },
                        }"
                      >
                        <v-icon color="primary">mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="error"
                        class="ml-1"
                        @click="(deleteItemLink = item), (deleteItemLinkDialog = true);"
                      >
                        <v-icon color="#fff">mdi-delete</v-icon>
                      </v-btn>
                    </template>


                  </v-data-table>
                </v-col>
              </v-row>

              <!-- Producer link overview -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                    <h4 class="text-primary font-weight-regular">gekoppelde impresariaten</h4>
                    <v-btn
                      rounded
                      small
                      elevation="0"
                      color="primary"
                      class="text-lowercase"
                      :to="{ name: 'AdminProducersAgencyLinkAdd' }"
                      >impresariaat toevoegen</v-btn
                    >
                  </v-row>

                  <!-- Table -->
                  <v-data-table
                    :headers="tableAgencyLinkHeaders"
                    :items="tableAgencyLinkFiltered"
                    :mobile-breakpoint="0"
                    :loading="tableAgencyLinkLoading"
                    :items-per-page="-1"
                    hide-default-footer
                    class="table-action"
                  >
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="error"
                        class="ml-1"
                        @click="(deleteAgencyLinkItem = item), (deleteAgencyLinkDialog = true);"
                      >
                        <v-icon color="#fff">mdi-delete</v-icon>
                      </v-btn>
                    </template>


                  </v-data-table>
                </v-col>
              </v-row>

              <!-- Producer subsidie overview -->
              <v-row class="d-flex flex-column align-center col col-12">
                <v-col
                  cols="12"
                  md="6"
                  sm="10"
                >
                  <v-row class="pa-0 ma-0 mb-2 d-flex flex-row align-center justify-space-between">
                    <h4 class="text-primary font-weight-regular">subsidies</h4>
                    <v-btn
                      rounded
                      small
                      elevation="0"
                      color="primary"
                      class="text-lowercase"
                      :to="{ name: 'AdminProducersSubventionAdd' }"
                      >subsidie toevoegen</v-btn
                    >
                  </v-row>

                  <!-- Table -->
                  <v-data-table
                    :headers="tableSubventionHeaders"
                    :items="tableSubventionFiltered"
                    :mobile-breakpoint="0"
                    :loading="tableSubventionLoading"
                    :items-per-page="-1"
                    hide-default-footer
                    class="table-action"
                  >
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="info"
                        class="ml-1"
                        :to="{
                          name: 'AdminProducersSubventionEdit',
                          params: { subsidieId: item.id, producerId: producerId },
                        }"
                      >
                        <v-icon color="primary">mdi-square-edit-outline</v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        x-small
                        depressed
                        color="error"
                        class="ml-1"
                        @click="(deleteSubventionItem = item), (deleteSubventionDialog = true);"
                      >
                        <v-icon color="#fff">mdi-delete</v-icon>
                      </v-btn>
                    </template>


                  </v-data-table>
                </v-col>
              </v-row>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Delete dialog starts here -->
    <v-dialog
      v-model="deleteItemDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">{{ this.producer.name }} verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je {{ this.producer.name }} wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemProducer()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog link starts here -->
    <v-dialog
      v-model="deleteItemLinkDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Gebruikersrol verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je deze gebruikersrol wil verwijderen? Het is daarna niet meer terug te
          vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItemLinkDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteItemLinkProducer()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog agency link starts here -->
    <v-dialog
      v-model="deleteAgencyLinkDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Koppeling impresariaat verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je deze koppeling wil verwijderen? Het is daarna niet meer terug te vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteAgencyLinkDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteAgencyLink()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog subsidei starts here -->
    <v-dialog
      v-model="deleteSubventionDialog"
      persistent
      width="500"
    >
      <v-card
        class="pa-6"
        flat
      >
        <v-card-title class="headline">Subsidie verwijderen?</v-card-title>
        <v-card-text
          >Weet je zeker dat je deze subsidie wil verwijderen? Het is daarna niet meer terug te vinden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteSubventionDialog = false"
            >Nee</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="deleteSubvention()"
            >Ja</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarColor"
    >
      {{ this.snackbarText }}
      <v-btn
        color="#fff"
        text
        @click="(snackbar = false), (snackbarText = '');"
        >Sluiten</v-btn
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';

import Help from '../../../components/Help.vue';
import functions from '../../../services/functions.service';

export default {
  name: 'AdminProducersDetail',
  props: ['producerId'],
  components: {
    Help,
  },
  data() {
    return {
      producer: {
        name: '',
        agreedToTerms: false,
        hasPublicMonitorAccess: false,
        street: '',
        number: '',
        zipCode: '',
        city: '',
        province: '',
        type: '',
        branchAssociation: '',
        lastEditedBy: '',
      },
      breadcrumbs: [
        {
          text: 'admin',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producenten',
          disabled: false,
          href: '/admin/producenten',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      deleteItemDialog: false,
      deleteItemLinkDialog: false,
      tableProducersUsersFiltered: [],
      tableProducersUsersLoading: true,
      tableProducersUsersHeaders: [
        { title: 'naam', key: 'userFullName' },
        { title: 'rol', key: 'roleName' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      deleteAgencyDialog: false,
      deleteAgencyLinkDialog: false,
      tableAgencyLinkFiltered: [],
      tableAgencyLinkLoading: true,
      tableAgencyLinkHeaders: [
        { title: 'naam', key: 'agencyName' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
      deleteSubventionDialog: false,
      tableSubventionFiltered: [],
      tableSubventionLoading: true,
      tableSubventionHeaders: [
        { title: 'subsidieregeling', key: 'type' },
        { title: 'organisatie', key: 'organisation' },
        { title: 'startdatum', key: 'startDate' },
        { title: 'einddatum', key: 'endDate' },
        { title: '', key: 'action', sortable: false, align: 'end' },
      ],
    };
  },
  mounted() {
    this.getProducer();
    this.getProducerUsers();
    this.getAgnecyLinks();
    this.getSubvention();
  },
  methods: {
    getProducer: function () {
      ApiService.get('/Producers/Get', {
        producerId: this.producerId,
      })
        .then((response) => {
          if (response.data.success) {
            const producer = response.data.result;
            this.breadcrumbs[2].text = producer.name;
            this.breadcrumbs[2].href = '/admin/producenten/' + producer.id;
            this.producer.name = producer.name;
            this.producer.agreedToTerms = producer.agreedToTerms;
            this.producer.hasPublicMonitorAccess = producer.hasPublicMonitorAccess;
            this.producer.street = producer.street;
            this.producer.number = producer.number;
            this.producer.zipCode = producer.zipCode;
            this.producer.city = producer.city;
            this.producer.province = producer.province.name;
            this.producer.type = producer.type.name;
            this.producer.branchAssociation = producer.branchAssociation != null ? producer.branchAssociation.name : '';
            this.producer.lastEditedBy = producer.lastEditedBy;
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getProducerUsers: function () {
      ApiService.get('/Producers/GetUsers', {
        producerId: this.producerId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableProducersUsersFiltered = response.data.result;
            this.tableProducersUsersLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getAgnecyLinks: function () {
      ApiService.get('/AgencyProducerLinks/GetPerProducer', {
        producerId: this.producerId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableAgencyLinkFiltered = response.data.result;
            this.tableAgencyLinkLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getSubvention: function () {
      ApiService.get('/Producers/GetSubventions', {
        producerId: this.producerId,
      })
        .then((response) => {
          if (response.data.success) {
            this.tableSubventionFiltered = response.data.result;
            for (var i = 0; i < this.tableSubventionFiltered.length; i++) {
              this.tableSubventionFiltered[i].type = functions.getSubventionTypeView(
                this.tableSubventionFiltered[i].type
              );
              this.tableSubventionFiltered[i].startDate = functions.getDateView(
                this.tableSubventionFiltered[i].startDate
              );
              this.tableSubventionFiltered[i].endDate = functions.getDateView(this.tableSubventionFiltered[i].endDate);
            }
            this.tableSubventionLoading = false;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    deleteItemProducer: function () {
      ApiService.delete('/Producers/Delete', {
        producerId: this.producerId,
      })
        .then((response) => {
          if (response.data.success) {
            this.snackbarText = this.producer.name + ' is verwijderd';
            this.deleteItemDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
            setTimeout(function () {
              router.push({ path: '/admin/producenten' });
            }, 1500);
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    deleteItemLinkProducer: function () {
      ApiService.delete('/UserRoles/Delete', {
        linkId: this.deleteItemLink.linkId,
        organisationTypeId: this.deleteItemLink.organisationTypeId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableProducersUsersFiltered.map((item) => item.linkId).indexOf(this.deleteItemLink.linkId);
            this.tableProducersUsersFiltered.splice(i, 1);

            this.snackbarText = 'Gebruiker is verwijderd';
            this.deleteItemLinkDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    deleteAgencyLink: function () {
      ApiService.delete('/AgencyProducerLinks/Delete', {
        linkId: this.deleteAgencyLinkItem.linkId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableAgencyLinkFiltered.map((item) => item.linkId).indexOf(this.deleteAgencyLinkItem.linkId);
            this.tableAgencyLinkFiltered.splice(i, 1);

            this.snackbarText = 'Koppeling impresariaat is verwijderd';
            this.deleteAgencyLinkDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    deleteSubvention: function () {
      ApiService.delete('/Producers/DeleteSubvention', {
        id: this.deleteSubventionItem.id,
        producerId: this.producerId,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.tableSubventionFiltered.map((item) => item.id).indexOf(this.deleteSubventionItem.id);
            this.tableSubventionFiltered.splice(i, 1);

            this.snackbarText = 'Subsidie is verwijderd';
            this.deleteSubventionDialog = false;
            this.snackbarColor = 'success';
            this.snackbar = true;
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {},
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
